import map from 'lodash/map';
import pickBy from 'lodash/pickBy';
import { useCallback, useReducer } from 'react';

import {
  ApplyFilterPayloadType,
  filtersReducer,
  RecordingsFiltersActions,
} from 'pages/recordings-list/components/recordings-list-page/filters-bar/hooks/useRecordingsFilters/reducer';
import { FiltersList } from 'shared/types';

const useRecordingsFilters = (filtersList: FiltersList) => {
  const initialFiltersState: { [key: string]: string[] } = {};
  map(filtersList, (filter, index) => {
    initialFiltersState[index] = Object.keys(pickBy(filter.options, (option) => option.isApplied));
  });

  const [filtersApplied, dispatch] = useReducer(filtersReducer, initialFiltersState);

  const applyFilters = useCallback((payload: ApplyFilterPayloadType) => {
    dispatch({
      type: RecordingsFiltersActions.APPLY_FILTER,
      payload,
    });
  }, []);

  const removeFilter = useCallback((payload: string) => {
    dispatch({
      type: RecordingsFiltersActions.REMOVE_FILTER,
      payload,
    });
  }, []);

  return {
    filtersApplied,
    applyFilters,
    removeFilter,
  };
};

export default useRecordingsFilters;
