import { TypeOf } from 'zod';

import { seasonCompetitionFormSchema } from 'pages/backoffice/pages/season-competitions/components/season-competition-form/form';
import { Competition } from 'pages/backoffice/types/competitions';

export enum SeasonCompetitionFormFieldsNames {
  id = 'id',
  year = 'year',
  crossesYear = 'crossesYear',
  competition = 'competition',
}

export interface SeasonCompetitionForm {
  [SeasonCompetitionFormFieldsNames.id]?: string;
  [SeasonCompetitionFormFieldsNames.year]: number;
  [SeasonCompetitionFormFieldsNames.crossesYear]: boolean;
  [SeasonCompetitionFormFieldsNames.competition]?: Competition;
}

export type SeasonFormSchema = TypeOf<typeof seasonCompetitionFormSchema>;
