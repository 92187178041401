import { z } from 'zod';

import { StageFormFieldsNames } from 'pages/backoffice/pages/stages/components/stage-form/types';
import { SeasonSchema } from 'pages/backoffice/types/seasons';
import { numberTextField } from 'shared/utils/zod/number-text-field';

export const stageFormSchema = z.object({
  [StageFormFieldsNames.id]: z.string().optional(),
  [StageFormFieldsNames.name]: z.string().trim().min(2),
  [StageFormFieldsNames.orderIndex]: numberTextField,
  [StageFormFieldsNames.season]: SeasonSchema.optional(),
  [StageFormFieldsNames.parentStageId]: z.string().optional(),
});
