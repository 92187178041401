import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { HTTPMethod } from 'api/types';
import { annotationApiUrls } from 'pages/backoffice/api/routes';
import { useAllowedBackofficeOrigin } from 'pages/backoffice/api/use-allowed-backoffice-origin';
import { metadataApiErrorParser, MetadataApiErrorResponse } from 'pages/backoffice/api/utils';

interface DeleteMetadataGameOptions {
  data: { gameId: string };
  onSuccess?: () => void;
}

export const useDeleteMetadataGamePlayers = () => {
  const { getIsAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();
  const mutationRequest = useMutationRequest<unknown, unknown, MetadataApiErrorResponse>({
    type: HTTPMethod.DELETE,
    errorMessage: (data) => metadataApiErrorParser(data, 'Delete game players error'),
  });

  const deleteMetadataGamePlayers = ({ data, onSuccess }: DeleteMetadataGameOptions) => {
    if (!getIsAllowedBackofficeOrigin()) return;

    mutationRequest.mutate(
      {
        url: `${annotationApiUrls.GAME_PLAYERS(data.gameId)}`,
      },
      {
        onSuccess,
      },
    );
  };

  return { ...mutationRequest, deleteMetadataGamePlayers };
};
