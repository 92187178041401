import { Paper, PaperProps } from '@mui/material';
import { boxShadows, Colors } from 'kognia-ui';

interface Props extends PaperProps {}

export const PlayerSearchPaper = ({ children, sx, ...props }: Props) => {
  return (
    <Paper
      sx={{
        mt: 0.5,
        pt: 1,
        pb: 1,
        border: `1px solid ${Colors.ghost}`,
        boxShadow: boxShadows[3],
        borderRadius: 1,
        ...sx,
      }}
      {...props}
    >
      {children}
    </Paper>
  );
};
