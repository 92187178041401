import { SvgIcon, type SvgIconProps } from 'shared/components/icons/svg-icon/SvgIcon';

const IconFolder = (props: Omit<SvgIconProps, 'children'>): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path d='M18.3175 6.96031h-.0002-6.7943l-1.4321-1.70262v-.00006c-.0532-.06316-.11953-.11394-.19438-.14879s-.15641-.05292-.23897-.05295h-.00007-5.28976-.00005c-.07435-.00001-.14798.01462-.21667.04307s-.13115.07016-.18374.12274-.09429.11502-.12274.18374-.04308.14232-.04307.21667v.00005V17.0629v.0002c.00058.4987.19895.9769.55158 1.3295s.83074.551 1.32944.5516h.00023 12.6346.0002c.4987-.0006.9768-.199 1.3295-.5516s.551-.8308.5515-1.3295v-.0002-8.22152-.00024c-.0005-.49868-.1989-.97676-.5516-1.32937s-.8307-.55092-1.3294-.55146zM4.93389 6.18833h4.45989l.64952.77198H4.93389v-.77198zM19.0661 17.0627c-.0002.1986-.0792.389-.2196.5294s-.3308.2194-.5294.2196H5.68291c-.19858-.0002-.38898-.0792-.5294-.2196s-.21938-.3307-.21962-.5293V8.09276H18.3171h.0002c.1985.00026.3888.07921.5291.21956s.2195.33074.2197.52931v8.22107z' />
    </SvgIcon>
  );
};

export default IconFolder;
