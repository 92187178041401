import { Box, Card, CardContent, IconButton, Tab } from '@mui/material';
import React, { useCallback, useState } from 'react';

import IconClose from 'shared/components/icons/icon-close';
import { Tabs } from 'shared/components/tabs';
import { TabPanel, TabsPanel } from 'shared/components/tabs/components/tab-panel';
import { VideoOverlaysTab } from 'shared/components/video-player/video-player-component/components/video-player-debug/video-player-debug-content/video-overlays-tab';
import { VideoPlayerTab } from 'shared/components/video-player/video-player-component/components/video-player-debug/video-player-debug-content/video-player-tab';
import styles from 'shared/components/video-player/video-player-component/components/video-player-debug/video-player-debug-content/VideoPlayerDebugContent.module.scss';

interface Props {
  onClose: () => void;
}

export const VideoPlayerDebugContent = ({ onClose }: Props) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleOnClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Card className={styles.container}>
      <CardContent className={styles.content}>
        <IconButton className={styles.close} size='small' onClick={handleOnClose}>
          <IconClose size='small' />
        </IconButton>
        <Box sx={{ padding: '16px' }}>
          <Tabs value={selectedTab} onChange={handleTabChange} variant={'fullWidth'}>
            <Tab label='Overlay frame info' />
            <Tab label='Video info' />
          </Tabs>
          <TabsPanel>
            <TabPanel value={selectedTab} index={1}>
              <VideoPlayerTab />
            </TabPanel>
            <TabPanel value={selectedTab} index={0}>
              <VideoOverlaysTab />
            </TabPanel>
          </TabsPanel>
        </Box>
      </CardContent>
    </Card>
  );
};
