import { SvgIcon, type SvgIconProps } from 'shared/components/icons/svg-icon/SvgIcon';

const IconLoading = (props: Omit<SvgIconProps, 'children'>): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path d='M12.312 18.202c.022.436.394.776.825.705a7 7 0 0 0 5.088-3.705 7 7 0 0 0-.332-6.981 7 7 0 0 0-6.206-3.215 7 7 0 0 0-5.49 3.078c-.244.362-.09.842.296 1.043h0c.387.202.859.047 1.115-.306a5.42 5.42 0 0 1 4.149-2.237 5.42 5.42 0 0 1 4.805 2.489 5.42 5.42 0 0 1 .257 5.406 5.42 5.42 0 0 1-3.764 2.837c-.428.085-.765.45-.744.886h0z' />
    </SvgIcon>
  );
};

export default IconLoading;
