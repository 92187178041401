import { useInfiniteQuery } from '@tanstack/react-query';
import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';
import { useCallback, useMemo, useState } from 'react';

import { queryClient } from 'api/config';
import { useBackendApi } from 'api/hooks/useBackendApi';
import { transformRecordingByName } from 'api/recording/transformers';
import { RecordingByNameAPIResponse } from 'api/recording/types';
import { recordingsByNameUrl } from 'api/routes';
import { HTTPMethod } from 'api/types';
import { RecordingByName } from 'shared/types/recording/types';

const transformRecordings = (data: {
  content: RecordingByNameAPIResponse[];
  page: { totalPages: number; number: number };
}) => {
  return {
    data: {
      recordings: data.content.map(transformRecordingByName),
      page: data.page,
    },
    nextCursor: data.page.totalPages > data.page.number ? (data.page.number + 1).toString() : undefined,
  };
};

interface useRecordingsByNameInterface {
  data: RecordingByName[];
  fetchNextPage: () => void;
  isPending: boolean;
  isSuccess: boolean;
  isFetching: boolean;
  isError: boolean;
  isEnabled: boolean;
  setName: (name: string) => void;
  setRecordingIds: (recordingIds: string[]) => void;
  setPageSize: (page: number) => void;
  setEnabled: (isEnabled: boolean) => void;
  removeQueries: () => void;
  enabled?: boolean;
}

interface Params {
  enabled?: boolean;
}

const PAGE_SIZE = 10;
const recordingsByNameQueryRef = ['recordings-by-name'];

const getQueryParamsString = (queryParams: { size: string; name: string; recordingIds: string[]; page: string }) => {
  const { recordingIds, ...rest } = queryParams;
  const params = { ...rest, recordingIds: recordingIds.join(',') };
  return new URLSearchParams({ ...omitBy(params, isEmpty) }).toString();
};

const getUrl = ({
  name,
  recordingIds,
  size,
  page,
}: {
  size: string;
  name: string;
  recordingIds: string[];
  page: number;
}): string => `${recordingsByNameUrl}?${getQueryParamsString({ name, recordingIds, size: size, page: `${page}` })}`;

export const useRecordingsByName = ({ enabled = true }: Params = {}): useRecordingsByNameInterface => {
  const [name, setName] = useState('');
  const [isQueryEnabled, setIsQueryEnabled] = useState(enabled);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [recordingsIds, setRecordingIds] = useState<string[]>([]);

  const removeQueries = useCallback(() => queryClient.removeQueries({ queryKey: recordingsByNameQueryRef }), []);

  const { data, isPending, isFetching, isSuccess, isError, fetchNextPage } = useInfiniteQuery({
    queryKey: [recordingsByNameQueryRef, name, pageSize],
    queryFn: ({ queryKey: [, nameParam, pageSizeParam], pageParam }) => {
      return useBackendApi(
        getUrl({
          name: nameParam.toString(),
          recordingIds: recordingsIds,
          size: pageSizeParam.toString(),
          page: pageParam,
        }),
        HTTPMethod.GET,
        transformRecordings,
      );
    },
    getNextPageParam: (lastPage: { nextCursor: number }) => {
      return lastPage.nextCursor;
    },
    enabled: isQueryEnabled,
    initialPageParam: 0,
  });

  const pages = useMemo(() => {
    return data?.pages?.length
      ? data.pages.reduce((acc: RecordingByName[], page: any) => {
          return acc.concat(page.data.recordings);
        }, [])
      : [];
  }, [data?.pages]);

  return {
    data: pages,
    fetchNextPage,
    isPending,
    isFetching,
    isSuccess,
    isError,
    isEnabled: isQueryEnabled,
    removeQueries,
    setName,
    setPageSize,
    setEnabled: setIsQueryEnabled,
    setRecordingIds,
  };
};
