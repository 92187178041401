import { Typography } from '@mui/material';
import { fontWeight } from 'kognia-ui';
import React, { useCallback, useMemo, useState } from 'react';

import { useDeleteSeason } from 'pages/backoffice/api/seasons/use-delete-season';
import { SeasonCompetitionFormFieldsNames } from 'pages/backoffice/pages/season-competitions/components/season-competition-form/types';
import { SeasonCompetitionStages } from 'pages/backoffice/pages/season-competitions/components/season-competition-item/season-competition-stages';
import { SeasonCompetitionsListItem } from 'pages/backoffice/pages/season-competitions/components/season-competition-item/styled';
import { SeasonCompetitionModal } from 'pages/backoffice/pages/season-competitions/components/season-competition-modal';
import { Season } from 'pages/backoffice/types/seasons';
import ConfirmDialog from 'shared/components/confirm-dialog';
import IconDelete from 'shared/components/icons/icon-delete';
import IconEdit from 'shared/components/icons/icon-edit';
import KebabMenu, { MenuListOption } from 'shared/components/kebab-menu';

interface Props {
  season: Season;
}

export const SeasonCompetitionItem = ({ season }: Props) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState(false);
  const { deleteSeason } = useDeleteSeason();

  const handleEditModalOpen = () => setIsEditModalOpen(true);
  const handleEditModalClose = () => setIsEditModalOpen(false);

  const handleDeleteSeason = useCallback(() => {
    deleteSeason({ id: season.id });
  }, [season.id, deleteSeason]);

  const options: MenuListOption[] = [
    {
      displayText: 'Edit',
      icon: <IconEdit size='small' color='secondary' />,
      onClick: handleEditModalOpen,
    },
    {
      displayText: 'Delete',
      icon: <IconDelete size='small' color='secondary' />,
      onClick: () => setIsConfirmDeleteDialogOpen(true),
    },
  ];

  const defaultValues = {
    [SeasonCompetitionFormFieldsNames.id]: season.id,
    [SeasonCompetitionFormFieldsNames.year]: season.year,
    [SeasonCompetitionFormFieldsNames.crossesYear]: season.crossesYear,
    [SeasonCompetitionFormFieldsNames.competition]: season.competition,
  };

  const seasonYearName = useMemo(() => {
    return season.crossesYear ? `${season.year}-${(season.year + 1).toString().slice(2)}` : season.year.toString();
  }, [season.year, season.crossesYear]);

  return (
    <>
      <SeasonCompetitionsListItem key={season.id}>
        <Typography fontWeight={fontWeight['500']}>{seasonYearName}</Typography>
        <Typography>{season?.competition?.name ?? ''}</Typography>
        <Typography>{season.stages ? <SeasonCompetitionStages stages={season.stages} /> : null}</Typography>
        <KebabMenu options={options} />
      </SeasonCompetitionsListItem>

      {isEditModalOpen ? (
        <SeasonCompetitionModal open={isEditModalOpen} onClose={handleEditModalClose} defaultValues={defaultValues} />
      ) : null}

      <ConfirmDialog
        type={'warning'}
        title={`You sure you want delete "${seasonYearName}" season?`}
        cancelLabel={'Cancel'}
        confirmLabel={'Delete'}
        onConfirm={handleDeleteSeason}
        isOpen={isConfirmDeleteDialogOpen}
        setIsOpen={setIsConfirmDeleteDialogOpen}
      />
    </>
  );
};
