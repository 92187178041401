import { SvgIcon, type SvgIconProps } from 'shared/components/icons/svg-icon/SvgIcon';

const IconTransition = (props: Omit<SvgIconProps, 'children'>): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path d='M17.834 8.462L14.5 12h2.5c0 2.928-2.242 5.307-5.001 5.307a4.67 4.67 0 0 1-2.334-.619L8.45 17.979c1.025.69 2.242 1.097 3.55 1.097 3.684 0 6.668-3.167 6.668-7.076h2.5l-3.334-3.538zM6.999 12c0-2.928 2.242-5.307 5.001-5.307a4.67 4.67 0 0 1 2.334.619l1.217-1.291c-1.025-.69-2.242-1.097-3.55-1.097-3.684 0-6.668 3.167-6.668 7.076h-2.5l3.334 3.538L9.5 12h-2.5z' />
    </SvgIcon>
  );
};

export default IconTransition;
