import { Box, Stack } from '@mui/material';
import { ReactNode, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { BackofficeSection } from 'pages/backoffice/components/backoffice-section-header';
import { GameFormNavigation } from 'pages/backoffice/pages/games/game-form/components/game-form-navigation';
import GameFormStepper from 'pages/backoffice/pages/games/game-form/components/game-form-stepper';
import { getCurrentStepFromPathName, useResetGameFormData } from 'pages/backoffice/pages/games/game-form/config';
import IconBall from 'shared/components/icons/icon-ball';

const getPageTitle = (id: string | undefined, step: number) => {
  if (step === 3) return 'GAME SUMMARY';

  return id ? 'EDIT GAME' : 'NEW GAME';
};

export const GameForm = ({ children }: { children: ReactNode }) => {
  const { id } = useParams();
  const currentStep = getCurrentStepFromPathName(location.pathname);

  const resetNewGameData = useResetGameFormData();

  useEffect(() => {
    if (!id) resetNewGameData();
  }, [id, resetNewGameData]);

  return (
    <Box sx={{ display: 'flex', height: '100vh', flexDirection: 'column', background: '#fff' }}>
      <Stack sx={{ flexGrow: 1, overflowY: 'scroll', padding: '0 32px 0px' }} gap={4}>
        <BackofficeSection icon={<IconBall size='small' />}>{getPageTitle(id, currentStep)}</BackofficeSection>
        <GameFormStepper step={currentStep} />
        {children}
      </Stack>
      <GameFormNavigation step={currentStep} />
    </Box>
  );
};
