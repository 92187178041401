import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { downloadEffectiveTimeVideosUrl } from 'api/routes';
import { HTTPMethod } from 'api/types';

export const useDownloadEffectiveTimeVideos = () => {
  const { t } = useTranslation();
  const { mutate } = useMutationRequest({
    successMessage: t('api:use-download-effective-time-videos.success'),
    errorMessage: t('api:use-download-effective-time-videos.error'),
    type: HTTPMethod.POST,
  });

  return useCallback(
    (tacticalAnalysisId: string) => {
      mutate({
        url: downloadEffectiveTimeVideosUrl(),
        data: { tacticalAnalysisId },
      });
    },
    [mutate],
  );
};
