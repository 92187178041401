import { Backdrop, IconButton, styled } from '@mui/material';
import MuiDialog, { DialogProps } from '@mui/material/Dialog';
import React from 'react';

import IconClose from 'shared/components/icons/icon-close';

interface DialogBackdropProps {
  disableBackground: boolean;
}

const DialogBackdrop = styled(Backdrop, {
  shouldForwardProp: (prop) => prop !== 'disableBackground',
})<DialogBackdropProps>(({ disableBackground }) => ({
  ...(disableBackground && {
    backgroundColor: 'transparent',
  }),
  pointerEvents: 'none',
}));

const DialogCloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
}));

interface Props extends DialogProps {
  disableBackground?: boolean;
  disableBackdropOnClose?: boolean;
}

export const Dialog = ({
  disableBackground = false,
  disableBackdropOnClose = false,
  onClose,
  children,
  ...rest
}: Props) => {
  return (
    <MuiDialog
      {...rest}
      onClose={disableBackdropOnClose ? undefined : onClose}
      components={{ Backdrop: DialogBackdrop }}
      componentsProps={{
        backdrop: () => ({ disableBackground }),
      }}
    >
      {children}

      {typeof onClose === 'function' ? (
        <DialogCloseButton onClick={(event) => onClose && onClose(event, 'backdropClick')}>
          <IconClose size='small' />
        </DialogCloseButton>
      ) : null}
    </MuiDialog>
  );
};
