import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useSaveUserPreset } from 'api/user-presets/use-save-user-preset';
import { MINIMUM_TIMELINE_TRACKS_HEIGHT } from 'pages/tactical-analysis/atoms';
import ResizeBar from 'pages/tactical-analysis/components/tactical-analysis/resize-bar';
import {
  TacticalAnalysisContainer,
  TacticalAnalysisTimelineContainer,
  TacticalAnalysisVideoContainer,
} from 'pages/tactical-analysis/components/tactical-analysis/styled';
import { TacticalAnalysisVideoPlayer } from 'pages/tactical-analysis/components/tactical-analysis/tactical-analysis-video-player';
import { Timeline } from 'pages/tactical-analysis/components/timeline';
import { useGenerateTimelineWidth } from 'pages/tactical-analysis/components/timeline/timeline-table/hooks/use-generate-timeline-width';
import { useSetTimelineHeight, useTimelineHeight } from 'pages/tactical-analysis/hooks/use-timeline-height';
import { useDuration } from 'shared/components/video-player';
import { TimelineHeightPreset } from 'shared/constants/user-presets/userPresetsClasses';
import { useBranding } from 'shared/hooks/use-branding/useBranding';
import { Episode, Match } from 'shared/types';
import { GroupedTags } from 'shared/types/tagging-events/types';

const MINIMUM_VIDEO_PLAYER_HEIGHT = 150;

export interface Props {
  episodes: Episode[];
  recordingId: string;
  match: Match;
  groupedTags: GroupedTags;
}

export const TacticalAnalysis = ({ episodes, match, groupedTags, recordingId }: Props) => {
  const { t } = useTranslation();
  const duration = useDuration();
  useGenerateTimelineWidth(recordingId);
  const { savePreset } = useSaveUserPreset();
  const containerRef = useRef<HTMLDivElement>(null);
  const trackContainerRef = useRef<HTMLDivElement>(null);
  const timelineHeight = useTimelineHeight();
  const setTimelineHeight = useSetTimelineHeight();
  const branding = useBranding();

  const handleSaveHeight = useCallback(
    (height: number) => {
      if (timelineHeight === height) {
        return;
      }
      const heightPreset = new TimelineHeightPreset(height);
      setTimelineHeight(height);
      savePreset({ data: heightPreset });
    },
    [savePreset, setTimelineHeight, timelineHeight],
  );

  useEffect(() => {
    document.title = t('common:metas.title.tactical-analysis', { clientDisplayName: branding.displayName });
  }, [t, branding.displayName]);

  return (
    <TacticalAnalysisContainer>
      <TacticalAnalysisVideoContainer ref={containerRef}>
        <TacticalAnalysisVideoPlayer recordingId={recordingId} />
      </TacticalAnalysisVideoContainer>
      <TacticalAnalysisTimelineContainer height={timelineHeight} ref={trackContainerRef}>
        <ResizeBar
          direction={'vertical'}
          mainPanelElement={trackContainerRef}
          mainPanelMinSize={MINIMUM_TIMELINE_TRACKS_HEIGHT}
          onChange={handleSaveHeight}
          secondaryPanelElement={containerRef}
          secondaryPanelMinSize={MINIMUM_VIDEO_PLAYER_HEIGHT}
        />
        {duration !== 0 ? (
          <Timeline episodes={episodes} match={match} recordingId={recordingId} tags={groupedTags} />
        ) : null}
      </TacticalAnalysisTimelineContainer>
    </TacticalAnalysisContainer>
  );
};
