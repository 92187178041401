import { Button, MenuItem, TextField } from '@mui/material';
import { Colors, fontSizes } from 'kognia-ui';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCreatePlaylist } from 'api/playlist/useCreatePlaylist';
import { invalidatePlaylistsQuery } from 'api/playlist/useFetchPlaylists';
import IconAddFolder from 'shared/components/icons/icon-add-folder';
import styles from 'shared/components/select-playlist-dialog/menu-item-new-playlist/MenuItemNewPlaylist.module.scss';
import { NotificationType, useNotifications } from 'shared/hooks/notifications';

const MenuItemNewPlaylist = () => {
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const triggerNotification = useNotifications();
  const { createPlaylist } = useCreatePlaylist();

  const handleSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();
      if (!inputValue.trim()) {
        return triggerNotification({
          type: NotificationType.ERROR,
          message: t('playlists:forms.add-a-name-for-the-playlist'),
        });
      }

      createPlaylist({ data: { name: inputValue as string }, onSuccess: invalidatePlaylistsQuery });
      setInputValue('');
      setShowForm(false);
    },
    [createPlaylist, inputValue, t, triggerNotification],
  );

  const handleInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  }, []);

  return (
    <>
      {!showForm && (
        <MenuItem
          sx={({ spacing }) => ({
            padding: spacing(1, 3),
            // TODO use from theme
            borderTop: `1px solid ${Colors.ghost}`,
            fontSize: fontSizes.default,
            lineHeight: 1.77,

            '& svg': {
              marginRight: spacing(1),
            },
          })}
          onClick={(event) => {
            event.stopPropagation();
            setShowForm(true);
          }}
        >
          <IconAddFolder size='small' /> {t('playlists:forms.new-playlist')}
        </MenuItem>
      )}

      {showForm && (
        <div onClick={(event) => event.stopPropagation()} className={styles.formContainer}>
          <form onSubmit={handleSubmit}>
            <div className={styles.iconGroup}>
              <IconAddFolder size='small' />{' '}
              <TextField
                className={styles.newPlaylistInput}
                autoFocus
                value={inputValue}
                onChange={handleInputChange}
                size='small'
                variant='outlined'
              />
            </div>
            <div className={styles.actions}>
              <Button disabled={!inputValue} type='submit' color='secondary'>
                {t('playlists:forms.create-playlist')}
              </Button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default MenuItemNewPlaylist;
