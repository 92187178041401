import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from 'pages/recordings-list/components/vod-recording-card/recording-states/RecordingStates.module.scss';
import IconCamera from 'shared/components/icons/icon-camera';
import Spinner from 'shared/components/spinner';

interface Props {
  active: boolean;
  isProcessingVideo: boolean;
}

const StateCamera = ({ active, isProcessingVideo }: Props) => {
  const { t } = useTranslation();
  return (
    <span className={classNames(styles.stateBlock, active && !isProcessingVideo ? styles.active : styles.inactive)}>
      {isProcessingVideo ? (
        <Spinner color='tertiary' size='small' />
      ) : (
        <IconCamera size='small' color={active ? 'tertiary' : 'disabled'} />
      )}
      <div>{t('recording:states.video')}</div>
    </span>
  );
};

export default StateCamera;
