import { Checkbox, Stack, Typography } from '@mui/material';
import { Colors, fontSizes } from 'kognia-ui';

import { useIsBulkModeActive } from 'entities/playlist/hooks/useIsBulkModeActive';
import { ContentBottomRow } from 'entities/playlist/ui/ContentBottomRow';
import { ContentTopRow } from 'entities/playlist/ui/ContentTopRow';
import { CounterBadge } from 'entities/playlist/ui/CounterBadge';
import { ItemContentWrapper } from 'entities/playlist/ui/ItemContentWrapper';
import { PlayButton } from 'features/playlist/playlist-item-content/ui/PlayButton';
import { PlaylistItemProgressBar } from 'features/playlist/playlist-item-content/ui/PlaylistItemProgressBar';
import IconPlay from 'shared/components/icons/icon-play';
import IconTime from 'shared/components/icons/icon-time';
import KebabMenu, { MenuListOption } from 'shared/components/kebab-menu';
import { useIsCurrentPlaylistItem } from 'shared/components/video-player';
import { PlaylistItemType } from 'shared/components/video-player/types';
import { secondsAsDuration } from 'shared/utils/seconds-as-duration';

type Props = {
  showProgressBar: boolean;
  isEditingMode: boolean;
  isChecked: boolean;
  getMenuOptions: (hasOverlays: boolean) => MenuListOption[];
  playlistItem: PlaylistItemType;
  videoIndex: number;
  onClick: (value: boolean) => void;
  playlistId: string;
  kebabRef: React.MutableRefObject<HTMLDivElement | null>;
};

export const PlaylistItemContent = ({
  playlistId,
  onClick,
  showProgressBar,
  isEditingMode,
  getMenuOptions,
  playlistItem,
  videoIndex,
  isChecked,
  kebabRef,
}: Props) => {
  const enabledBulkMode = useIsBulkModeActive(playlistId);
  const isCurrentPlaylistItem = useIsCurrentPlaylistItem(playlistItem.id);

  return (
    <ItemContentWrapper>
      <PlaylistItemProgressBar isVisible={showProgressBar} isEditing={Boolean(isEditingMode)} />
      <Stack
        sx={{
          height: '100%',
          justifyContent: 'flex-start',
          position: 'relative',
          maxWidth: 'calc(100% - 24px)',
        }}
      >
        <ContentTopRow>
          {enabledBulkMode ? <Checkbox sx={{ padding: '0 !important' }} checked={isChecked} /> : null}
          {isCurrentPlaylistItem && !enabledBulkMode ? (
            <PlayButton playlistItem={playlistItem} />
          ) : enabledBulkMode ? null : (
            <IconPlay onClick={() => onClick(true)} isButton size={'small'} color={'primary'} />
          )}
          <CounterBadge data-element-name='counter'>{(videoIndex + 1).toString().padStart(3, '0')}</CounterBadge>
          <Typography
            sx={{ color: isCurrentPlaylistItem ? 'common.white' : 'secondary.main' }}
            data-element-name='duration'
            fontSize={fontSizes.xxSmall}
            display='flex'
            alignItems='center'
          >
            <IconTime size='small' sx={{ color: isCurrentPlaylistItem ? 'common.white' : 'secondary.main' }} />
            {secondsAsDuration(playlistItem.duration, false)}
          </Typography>
        </ContentTopRow>
        <ContentBottomRow>
          <Typography
            color={isCurrentPlaylistItem ? Colors.white : Colors.night}
            fontSize={fontSizes.xxSmall}
            whiteSpace='nowrap'
            textOverflow='ellipsis'
            overflow='hidden'
            data-element-name='name-text'
          >
            {playlistItem.name}
          </Typography>
          <Typography
            color={isCurrentPlaylistItem ? Colors.white : Colors.storm}
            fontSize={fontSizes.xxSmall}
            whiteSpace='nowrap'
            textOverflow='ellipsis'
            overflow='hidden'
            data-element-name='name-text'
          >
            {`${playlistItem.recordingName} (${playlistItem.recordingMatchday})`}
          </Typography>
        </ContentBottomRow>
      </Stack>
      {!enabledBulkMode ? (
        <Stack>
          <KebabMenu
            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
            ref={kebabRef}
            options={getMenuOptions(playlistItem.hasHomographies)}
            disableButtonPadding
          />
        </Stack>
      ) : null}
    </ItemContentWrapper>
  );
};
