import { Stack, Typography } from '@mui/material';
import { Colors, fontWeight } from 'kognia-ui';
import React, { useCallback, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { routes } from 'kognia/router/routes';
import { useDeleteMetadataGame } from 'pages/backoffice/api/games/use-delete-metadata-game';
import { AddRecordingIdDialog } from 'pages/backoffice/pages/games/components/metadata-game-item/add-recording-id-dialog';
import { GamesListItem } from 'pages/backoffice/pages/games/components/metadata-game-item/styled';
import { MetadataGame } from 'pages/backoffice/types/games';
import ConfirmDialog from 'shared/components/confirm-dialog';
import IconDelete from 'shared/components/icons/icon-delete';
import IconEdit from 'shared/components/icons/icon-edit';
import { IconEye } from 'shared/components/icons/icon-eye';
import KebabMenu, { MenuListOption } from 'shared/components/kebab-menu';
import { TeamLogo } from 'shared/components/team-logo';
import { useDates } from 'shared/hooks/use-dates';

interface Props {
  game: MetadataGame;
}

export const GameItem = ({ game }: Props) => {
  const { dateToString } = useDates();
  const navigate = useNavigate();
  const [isRecordingDialogOpen, setIsRecordingDialogOpen] = useState(false);
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState(false);
  const { deleteMetadataGame } = useDeleteMetadataGame();

  const handleDeleteGame = useCallback(() => {
    deleteMetadataGame({ id: game.id });
  }, [game.id, deleteMetadataGame]);

  const options: MenuListOption[] = [
    {
      displayText: 'View game details',
      icon: <IconEye size='small' color='secondary' />,
      onClick: () => navigate(generatePath(routes.BACKOFFICE_GAME_SUMMARY, { id: game.id })),
    },
    {
      displayText: 'Edit game',
      icon: <IconEdit size='small' color='secondary' />,
      onClick: () => navigate(generatePath(routes.BACKOFFICE_EDIT_GAME_FORM, { id: game.id })),
    },
    {
      displayText: 'Add Recording ID',
      icon: <IconEdit size='small' color='secondary' />,
      onClick: () => setIsRecordingDialogOpen(true),
    },
    {
      displayText: 'Delete',
      icon: <IconDelete size='small' color='secondary' />,
      onClick: () => setIsConfirmDeleteDialogOpen(true),
      isHidden: game.recordings.length > 0,
    },
  ];

  const hasRecordings = game.recordings.length > 0;

  return (
    <>
      <GamesListItem key={game.id} sx={{ background: hasRecordings ? 'transparent' : Colors.athens }}>
        <Stack gap={2}>
          <Stack direction={'row'} gap={1} alignItems={'center'}>
            <TeamLogo src={game.homeTeam.logoUrl} name={game.homeTeam.name} />
            <Typography fontWeight={fontWeight['500']}>{game.homeTeamScore}</Typography>
            <Typography fontWeight={fontWeight['500']}>-</Typography>
            <Typography fontWeight={fontWeight['500']}>{game.awayTeamScore}</Typography>
            <TeamLogo src={game.awayTeam.logoUrl} name={game.awayTeam.name} />
          </Stack>
          {!hasRecordings ? (
            <Stack alignItems={'center'} direction={'row'} gap={1} sx={{ color: Colors.storm }}>
              No recording id
            </Stack>
          ) : null}
        </Stack>
        <Stack>
          <Typography fontWeight={fontWeight['500']} textTransform={'uppercase'}>
            {game.homeTeam.abbreviation} - {game.awayTeam.abbreviation}
          </Typography>
          <Typography color={Colors.storm}>{game.season?.competition?.name}</Typography>
        </Stack>
        <Stack>
          <Typography fontWeight={fontWeight['500']} textTransform={'uppercase'}>
            {game.date ? dateToString(game.date) : '-'}
          </Typography>
          <Typography color={Colors.storm}>{game.stage?.name}</Typography>
        </Stack>
        <KebabMenu options={options} />
      </GamesListItem>
      {isRecordingDialogOpen ? (
        <AddRecordingIdDialog isOpen={isRecordingDialogOpen} setIsOpen={setIsRecordingDialogOpen} gameId={game.id} />
      ) : null}
      <ConfirmDialog
        type={'warning'}
        title={`You sure you want delete "${game.name}" game?`}
        cancelLabel={'Cancel'}
        confirmLabel={'Delete'}
        onConfirm={handleDeleteGame}
        isOpen={isConfirmDeleteDialogOpen}
        setIsOpen={setIsConfirmDeleteDialogOpen}
      />
    </>
  );
};
