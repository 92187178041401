import { Navigate, Outlet } from 'react-router-dom';

import { FEATURE_FLAG } from 'api/user/use-fetch-feature-flags';
import { sentryCreateBrowserRouter } from 'kognia/error-tracking/init';
import { routes } from 'kognia/router/routes';
import AccountPage from 'pages/account/AccountPageContainer';
import { backofficeRoutes } from 'pages/backoffice/BackofficeContainer';
import { DashboardPreviewPage } from 'pages/dashboard-preview/DashboardPreview.page';
import DownloadManagerPage from 'pages/download-manager/DownloadManager.page';
import { ExplorePage } from 'pages/explore/Explore.page';
import HomeContainer from 'pages/home/HomeContainer.page';
import LogoutPage from 'pages/logout/Logout.page';
import { PlayerProfilePage } from 'pages/player-profile/PlayerProfileDashboard.page';
import { PlayerProfileDashboardPage } from 'pages/player-profile-dashboard/PlayerProfileDashboard.page';
import { PlaylistDetailContainer } from 'pages/playlist-detail/PlaylistDetailContainer.page';
import { PlaylistsContainer } from 'pages/playlists/PlaylistsContainer';
import { RecordingPlaylistsContainer } from 'pages/recording-playlists/RecordingPlaylistsContainer';
import RecordingsListPage from 'pages/recordings-list/RecordingsListPageContainer';
import { TacticalAnalysisContainer } from 'pages/tactical-analysis/TacticalAnalysisContainer';
import { RecordingAlignment } from 'pages/tagging-tool-align-recording';
import { AlignmentSourceListContainer } from 'pages/tagging-tool-aling-sources/AlignmentSourceListContainer';
import { KeypadEditPage } from 'pages/tagging-tool-keypad-edit';
import { KeypadListPage } from 'pages/tagging-tool-keypad-list';
import { TaggingToolRecordingList } from 'pages/tagging-tool-recording-list';
import { TaggingToolTagContainer } from 'pages/tagging-tool-tag-recording/TaggingToolTagContainer';
import { AccessProtected } from 'shared/components/access-protected/AccessProtected';
import { ErrorPage } from 'shared/components/error-page';
import { ProtectedRoute } from 'shared/components/protected-route';
import { SidebarLayout } from 'shared/components/sidebar-layout';

export const appRouter = sentryCreateBrowserRouter([
  {
    path: routes.HOME_PAGE,
    element: (
      <ProtectedRoute>
        <SidebarLayout>
          <Outlet />
        </SidebarLayout>
      </ProtectedRoute>
    ),
    errorElement: (
      <ProtectedRoute>
        <ErrorPage />
      </ProtectedRoute>
    ),
    children: [
      {
        index: true,
        element: <HomeContainer />,
      },
      {
        path: routes.RECORDING_LIST,
        element: <RecordingsListPage />,
      },
      {
        path: routes.PLAYLISTS,
        element: <PlaylistsContainer />,
      },
      {
        path: routes.RECORDING_PLAYLISTS,
        element: <RecordingPlaylistsContainer />,
      },
      {
        path: routes.RECORDING_PLAYLIST_DETAIL,
        element: <PlaylistDetailContainer />,
      },
      {
        path: routes.PERFORMANCE_REPORT_TIMELINE,
        element: <TacticalAnalysisContainer />,
      },
      {
        path: routes.DOWNLOAD_MANAGER,
        element: <DownloadManagerPage />,
      },
      {
        path: routes.ACCOUNT,
        element: <AccountPage />,
      },
      {
        path: routes.TAGGING_TOOL,
        element: <TaggingToolRecordingList />,
      },
      {
        path: routes.TAGGING_TOOL_ALIGN_SOURCES,
        element: <AlignmentSourceListContainer />,
      },
      {
        path: routes.TAGGING_TOOL_ALIGN_RECORDING_WITH_SOURCE,
        element: <RecordingAlignment />,
      },
      {
        path: routes.TAGGING_TOOL_ALIGN_RECORDING,
        element: <RecordingAlignment />,
      },
      {
        path: routes.TAGGING_TOOL_TAG,
        element: <TaggingToolTagContainer />,
      },
      {
        path: routes.TAGGING_TOOL_RECORDING,
        element: <TaggingToolTagContainer />,
      },
      {
        path: routes.KEYPADS,
        element: <KeypadListPage />,
      },
      {
        path: routes.KEYPADS_EDIT,
        element: <KeypadEditPage />,
      },
      {
        path: routes.EXPLORE,
        element: (
          <AccessProtected featureFlag={FEATURE_FLAG.APP_EXPLORE}>
            <ExplorePage />
          </AccessProtected>
        ),
      },
      {
        path: routes.PLAYER_PROFILE_DASHBOARD,
        element: (
          <AccessProtected featureFlag={FEATURE_FLAG.APP_DASHBOARDS}>
            <PlayerProfileDashboardPage />
          </AccessProtected>
        ),
      },
      {
        path: routes.PLAYER_PROFILE,
        element: (
          <AccessProtected featureFlag={FEATURE_FLAG.APP_EXPLORE}>
            <PlayerProfilePage />
          </AccessProtected>
        ),
      },
      {
        path: routes.DASHBOARD_PREVIEW,
        element: (
          <AccessProtected featureFlag={FEATURE_FLAG.APP_DASHBOARD_PREVIEW}>
            <DashboardPreviewPage />
          </AccessProtected>
        ),
      },
      ...backofficeRoutes,
    ],
  },
  {
    path: routes.LOGOUT,
    element: <LogoutPage />,
  },
  {
    path: '*',
    element: <Navigate to={routes.HOME_PAGE} replace />,
  },
]);
