type PlayerAttributesMapping = {
  [key: number]: string;
};

export const PlayerPositions: PlayerAttributesMapping = {
  0: 'unknown',
  1: 'goalkeeper',
  2: 'wingBack',
  3: 'fullBack',
  4: 'centralDefender',
  5: 'defensiveMidfielder',
  6: 'attackingMidfielder',
  7: 'centralMidfielder',
  8: 'winger',
  9: 'striker',
  10: 'secondStriker',
};

export const PlayerSides: PlayerAttributesMapping = {
  0: 'unknown',
  1: 'left',
  2: 'right',
  3: 'center',
  4: 'leftCenter',
  5: 'centerRight',
  6: 'leftCenterRight',
  7: 'leftRight',
};

export const getPlayerPositionFromValue = (positionValue: number): string => {
  return PlayerPositions[positionValue] ?? PlayerPositions[0];
};
