import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { PlaylistDownloadRequestsParams } from 'api/playlist/useDownloadPlaylist/types/playlistDownloadRequestsParams';
import { PlaylistDownloadType } from 'api/playlist/useDownloadPlaylist/types/playlistDownloadType';
import {
  playlistDownloadRequestsPlaylistIdUrl,
  playlistDownloadRequestsPlaylistItemsUrl,
  playlistDownloadRequestsUrl,
} from 'api/routes';
import { FundamentalsSelection, Playlist } from 'shared/types/playlist/types';

interface DownloadPlaylistItemData {
  recordingId: string;
  name: string;
  startTime: number;
  endTime: number;
  fundamentalsSelected: FundamentalsSelection;
}

interface DownloadPlaylistData {
  name: string;
  items?: DownloadPlaylistItemData[];
}

interface PlaylistDownloadRequests {
  data: Playlist;
  params: PlaylistDownloadRequestsParams;
}

interface PlaylistDownloadRequestsPlaylistId {
  playlistId: string;
  params: PlaylistDownloadRequestsParams;
}

interface PlaylistDownloadRequestsPlaylistItems {
  playlistItemsIds: string[];
  playlistId: string;
  params: PlaylistDownloadRequestsParams;
}

const mapPlaylistToDownloadData = (playlist: Playlist): DownloadPlaylistData => {
  return {
    name: playlist.name,
    items: playlist.playlistItems.map((item) => ({
      recordingId: item.recordingId,
      name: item.name ?? '',
      startTime: item.startTime,
      endTime: item.endTime,
      fundamentalsSelected: item.fundamentalsSelected,
    })),
  };
};

export const useDownloadPlaylist = () => {
  const { t } = useTranslation();
  const { mutate } = useMutationRequest({
    successMessage: t('api:use-download-playlist.success'),
    errorMessage: t('api:use-download-playlist.error'),
  });

  const downloadTemporaryPlaylist = useCallback(
    (payload: PlaylistDownloadRequests) => {
      mutate({
        url: playlistDownloadRequestsUrl({ ...payload.params }),
        data: mapPlaylistToDownloadData(payload.data),
      });
    },
    [mutate],
  );

  const downloadPlaylistItems = useCallback(
    (payload: PlaylistDownloadRequestsPlaylistItems) => {
      mutate({
        url: playlistDownloadRequestsPlaylistItemsUrl({ playlistId: payload.playlistId, ...payload.params }),
        data: payload.playlistItemsIds,
      });
    },
    [mutate],
  );

  const downloadPlaylist = useCallback(
    (payload: PlaylistDownloadRequestsPlaylistId) => {
      mutate({
        url: playlistDownloadRequestsPlaylistIdUrl({ playlistId: payload.playlistId, ...payload.params }),
      });
    },
    [mutate],
  );

  return useCallback(
    ({
      params,
      data,
      type,
    }: {
      params: PlaylistDownloadRequestsParams;
      data: Playlist;
      type: PlaylistDownloadType;
    }) => {
      if (type === 'temporaryPlaylist') {
        downloadTemporaryPlaylist({
          data,
          params,
        });
      }

      if (type === 'playlistItems') {
        downloadPlaylistItems({
          playlistItemsIds: data.playlistItems.map((item) => item.id),
          playlistId: data.id,
          params,
        });
      }

      if (type === 'playlist') {
        downloadPlaylist({
          playlistId: data.id,
          params,
        });
      }
    },
    [downloadPlaylist, downloadPlaylistItems, downloadTemporaryPlaylist],
  );
};
