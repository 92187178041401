import { useQueryClient, UseQueryResult } from '@tanstack/react-query';

import { useFetchRequest } from 'api/hooks/useFetchRequest';
import { transformMetadataGame } from 'pages/backoffice/api/games/transformers';
import { annotationApiUrls } from 'pages/backoffice/api/routes';
import { MetadataGame } from 'pages/backoffice/types/games';

type Params = {
  gameId?: string;
  onSuccess?: (data: MetadataGame) => void;
};

export const getMetadataGameQueryKey = (gameId?: string) => ['metadata-game', gameId ?? ''];

export const useMetadataGame = ({ gameId }: Params): UseQueryResult<MetadataGame> => {
  return useFetchRequest<MetadataGame>({
    queryRef: getMetadataGameQueryKey(gameId),
    url: `${annotationApiUrls.GAMES}/${gameId}`,
    transformer: transformMetadataGame,
    options: {
      enabled: gameId !== undefined,
    },
  });
};

export const useInvalidateMetadataGame = () => {
  const queryClient = useQueryClient();

  return (gameId: string) => queryClient.invalidateQueries({ queryKey: getMetadataGameQueryKey(gameId) });
};
