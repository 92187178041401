import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback, useRef } from 'react';
import { useForm } from 'react-hook-form';

import { useCreateCompetition } from 'pages/backoffice/api/competitions/use-create-competition';
import { useUpdateCompetition } from 'pages/backoffice/api/competitions/use-update-competition';
import { CompetitionsForm } from 'pages/backoffice/pages/competitions/components/competition-form';
import { competitionFormSchema } from 'pages/backoffice/pages/competitions/components/competition-form/form';
import {
  CompetitionForm,
  CompetitionFormSchema,
} from 'pages/backoffice/pages/competitions/components/competition-form/types';
import { DialogNew } from 'shared/components/dialog-new';
import { IconUser } from 'shared/components/icons/icon-user';

interface Props {
  open: boolean;
  onClose: () => void;
  defaultValues?: CompetitionForm;
}

const competitionsFormId = 'competitions-form';

export const CompetitionModal = ({ open, onClose, defaultValues }: Props) => {
  const formRef = useRef<HTMLFormElement>(null);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<CompetitionFormSchema>({
    resolver: zodResolver(competitionFormSchema),
    defaultValues,
  });

  const { create } = useCreateCompetition();
  const { update } = useUpdateCompetition();

  const handleCreate = useCallback(
    (data: CompetitionForm) => {
      create({
        data,
        onSuccess: () => {
          onClose();
          reset();
        },
      });
    },
    [create, onClose, reset],
  );

  const handleUpdate = useCallback(
    (data: CompetitionForm) => {
      if (!data?.id) return;
      const { id, ...rest } = data;
      update({
        data: rest,
        id,
        onSuccess: onClose,
      });
    },
    [onClose, update],
  );

  const handleCreateOrUpdate = useCallback(
    (data: CompetitionForm) => {
      if (defaultValues) {
        handleUpdate(data);
      } else {
        handleCreate(data);
      }
    },
    [defaultValues, handleCreate, handleUpdate],
  );

  return (
    <DialogNew
      maxWidth='sm'
      fullWidth
      title={defaultValues ? 'Edit competition' : 'New competition'}
      icon={<IconUser size='small' />}
      onCancel={onClose}
      onClose={onClose}
      buttonSubmitText={defaultValues ? 'Save' : 'Create'}
      open={open}
      buttonFormId={competitionsFormId}
    >
      <form id={competitionsFormId} ref={formRef} onSubmit={handleSubmit(handleCreateOrUpdate)}>
        <CompetitionsForm<CompetitionFormSchema> errors={errors} register={register} />
      </form>
    </DialogNew>
  );
};
