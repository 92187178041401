import { Box, styled } from '@mui/material';
import React from 'react';

import IconChevronDown from 'shared/components/icons/icon-chevron-down';

interface Props {
  isOpen: boolean;
  color?: 'text.primary';
}

interface ChevronProps {
  isOpen: boolean;
}

const ChevronContainer = styled(Box, { shouldForwardProp: (prop) => prop !== 'isOpen' })<ChevronProps>(
  ({ theme, isOpen }) => ({
    borderRadius: theme.spacing(0.25),
    [theme.breakpoints.down('xs')]: {
      ['& svg']: {
        marginLeft: theme.spacing(0.5),
      },
    },
    [theme.breakpoints.down('xl')]: {
      marginLeft: theme.spacing(),
    },
    ['& svg']: {
      transition: theme.transitions.create(['transform'], {
        duration: theme.transitions.duration.short,
        easing: theme.transitions.easing.easeOut,
      }),
      transform: isOpen ? 'rotate(-180deg)' : 'rotate(0)',
    },
  }),
);

export const Chevron = ({ isOpen, color }: Props) => {
  return (
    <ChevronContainer isOpen={isOpen}>
      <IconChevronDown sx={{ color: color ? color : 'secondary.light' }} size='small' />
    </ChevronContainer>
  );
};
