import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { fontSizes } from 'kognia-ui';
import { ChangeEvent, useState } from 'react';

import { useTeams } from 'pages/backoffice/api/teams/use-teams';
import { BackofficeContentContainer } from 'pages/backoffice/components/backoffice-content-container';
import { CreateTeamModal } from 'pages/backoffice/components/create-team-modal';
import { TeamsList } from 'pages/backoffice/pages/teams/components/teams-list';
import { TeamsListSkeleton } from 'pages/backoffice/pages/teams/components/teams-list-skeleton';
import { TeamListHeaderGrid } from 'pages/backoffice/pages/teams/styled';
import IconPlus from 'shared/components/icons/icon-plus';
import { ListHeader } from 'shared/components/list/list-header';
import NotFound from 'shared/components/not-found';
import Pagination from 'shared/components/pagination';
import { useDebounce } from 'shared/hooks/use-debounce/useDebounce';

export const Teams = () => {
  const [isCreateTeamModalOpen, setIsCreateTeamModalOpen] = useState(false);
  const { items, page, fetchNextPage, isPending, isFetching, setFilters } = useTeams();

  const handleCreateTeamModalOpen = () => setIsCreateTeamModalOpen(true);
  const handleCreateTeamModalClose = () => setIsCreateTeamModalOpen(false);

  const handleSearchChange = useDebounce((event: ChangeEvent<HTMLInputElement>) => {
    setFilters({ name: event.target.value });
  });

  return (
    <BackofficeContentContainer>
      <Stack direction='row' alignItems='center' justifyContent='space-between' marginBottom={2}>
        <Typography variant='h4'>Teams</Typography>
        <Button
          variant='contained'
          size='large'
          startIcon={<IconPlus size='small' sx={{ color: 'common.white' }} />}
          onClick={handleCreateTeamModalOpen}
        >
          New team
        </Button>
      </Stack>

      <Stack marginBottom={2} justifyContent='flex-end' direction='row'>
        <TextField size='small' placeholder='Search teams' onChange={handleSearchChange} />
      </Stack>

      <ListHeader>
        <TeamListHeaderGrid>
          <Typography fontWeight={'500'} fontSize={fontSizes.default}>
            Name
          </Typography>
          <Typography fontWeight={'500'} fontSize={fontSizes.default}>
            Abbreviation
          </Typography>
          <Typography fontWeight={'500'} fontSize={fontSizes.default}>
            Country
          </Typography>
          <div style={{ width: '32px' }}>&nbsp;</div>
        </TeamListHeaderGrid>
      </ListHeader>
      {!isPending && !isFetching && items.length === 0 ? (
        <Box marginTop={4}>
          <NotFound header={'No results'} />
        </Box>
      ) : null}
      {isPending ? <TeamsListSkeleton /> : <TeamsList teams={items} />}
      {page ? (
        <Pagination
          total={page.totalElements}
          displayed={items.length}
          onShowMore={fetchNextPage}
          loading={isPending || isFetching}
          getStatsText={(displayed, total) => `${displayed} of ${total} teams`}
        />
      ) : null}
      {isCreateTeamModalOpen ? (
        <CreateTeamModal open={isCreateTeamModalOpen} onClose={handleCreateTeamModalClose} />
      ) : null}
    </BackofficeContentContainer>
  );
};
