import { SvgIcon, type SvgIconProps } from 'shared/components/icons/svg-icon/SvgIcon';

// TODO unused
export const IconSave = (props: Omit<SvgIconProps, 'children'>): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path d='M19 8.053v9.781a1.12 1.12 0 0 1-.35.817 1.12 1.12 0 0 1-.817.35H6.167a1.12 1.12 0 0 1-.817-.35c-.233-.233-.35-.506-.35-.817V6.167c0-.311.117-.583.35-.817S5.856 5 6.167 5h9.781L19 8.053zm-1.167.525l-2.411-2.411H6.167v11.667h11.667V8.578zM12 16.569c.557 0 1.034-.198 1.429-.593s.593-.872.593-1.429-.198-1.034-.593-1.429-.872-.593-1.429-.593-1.034.198-1.429.593-.593.872-.593 1.429.198 1.034.593 1.429.872.593 1.429.593zM7.197 9.978h6.961V7.197H7.197v2.781zm-1.031-1.4v9.256V6.167v2.411z' />
    </SvgIcon>
  );
};
