import { Button, Tooltip } from '@mui/material';
import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { IconDownload } from 'shared/components/icons/icon-download';
import { usePlayerCurrentSource } from 'shared/components/video-player/index';

interface Props {
  onDownloadSource?: () => void;
}
const DownloadButton = ({ onDownloadSource }: Props) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();
  const videoSource = usePlayerCurrentSource();

  if (!videoSource?.srcDownload) return null;

  const handleDownloadCurrentVideo = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      buttonRef?.current?.blur();
      if (!videoSource?.srcDownload || !videoSource?.srcDownload?.length) return null;

      return window.open(videoSource.srcDownload);
    },
    [buttonRef, videoSource],
  );

  return (
    <Tooltip enterDelay={2000} title={`${t('video-player:controls.download-video')}`}>
      <Button ref={buttonRef} onClick={onDownloadSource ? onDownloadSource : handleDownloadCurrentVideo} size={'small'}>
        <IconDownload sx={{ color: 'common.white' }} />
      </Button>
    </Tooltip>
  );
};

export default DownloadButton;
