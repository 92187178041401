import { useLocation, useParams } from 'react-router-dom';

import { useMetadataGame } from 'pages/backoffice/api/games/use-metadata-game';
import { GameInfoForm } from 'pages/backoffice/pages/games/game-form/components/game-info-form';
import { getCurrentStepFromPathName, useCheckValidateStep } from 'pages/backoffice/pages/games/game-form/config';

export const GameFormInfo = () => {
  const location = useLocation();
  const { id } = useParams();
  const { data: game, isLoading } = useMetadataGame({ gameId: id });
  const step = getCurrentStepFromPathName(location.pathname);

  useCheckValidateStep(step);

  return <>{isLoading && id ? null : <GameInfoForm game={game} step={step} />}</>;
};
