import { zodResolver } from '@hookform/resolvers/zod';
import { AccordionActions, AccordionDetails } from '@mui/material';
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { useDeleteEpisodeOverlays } from 'pages/backoffice/api/operations/use-delete-episode-overlays';
import { AnnotationFormActions } from 'pages/backoffice/pages/annotation/components/annotation-form-actions';
import { deleteEpisodeOverlaysFormSchema } from 'pages/backoffice/pages/annotation/components/delete-episode-overlays/form';
import {
  DeleteEpisodeOverlaysForm,
  DeleteEpisodeOverlaysFormSchema,
} from 'pages/backoffice/pages/annotation/components/delete-episode-overlays/types';
import { EpisodeIndexField } from 'pages/backoffice/pages/annotation/components/form-fields/episode-index-field';
import { RecordingIdField } from 'pages/backoffice/pages/annotation/components/form-fields/recording-id-field';
import { DialogNew } from 'shared/components/dialog-new';
import IconDelete from 'shared/components/icons/icon-delete';

export const DeleteEpisodeOverlays = () => {
  const [confirmationDialogOpen, setConfirmationDialogOpen] = React.useState(false);
  const {
    register,
    formState: { errors },
    reset,
    getValues,
    handleSubmit: handleFormSubmit,
  } = useForm<DeleteEpisodeOverlaysFormSchema>({
    resolver: zodResolver(deleteEpisodeOverlaysFormSchema),
  });

  const values: DeleteEpisodeOverlaysForm = getValues();

  const handleConfirmDialogOpen = useCallback(() => setConfirmationDialogOpen(true), []);
  const handleConfirmDialogClose = useCallback(() => setConfirmationDialogOpen(false), []);

  const { deleteEpisodeOverlays } = useDeleteEpisodeOverlays();

  const handleSubmit = useCallback(() => {
    handleConfirmDialogOpen();
  }, [handleConfirmDialogOpen]);

  const handleConfirmSubmit = useCallback(() => {
    deleteEpisodeOverlays({ data: values });
    handleConfirmDialogClose();
  }, [deleteEpisodeOverlays, handleConfirmDialogClose, values]);

  return (
    <form onSubmit={handleFormSubmit(handleSubmit)}>
      <AccordionDetails>
        <RecordingIdField errors={errors} register={register} />
        <EpisodeIndexField errors={errors} register={register} />
      </AccordionDetails>
      <AccordionActions>
        <AnnotationFormActions reset={() => reset()} />
      </AccordionActions>
      <DialogNew
        title='Confirmation'
        icon={<IconDelete />}
        onCancel={handleConfirmDialogClose}
        onClose={handleConfirmDialogClose}
        onSubmit={handleConfirmSubmit}
        buttonSubmitText='Delete'
        open={confirmationDialogOpen}
        error
      >
        This will delete episode <strong>{values.episodeIx}</strong> for recording <strong>{values.recordingId}</strong>
        . Continue?
      </DialogNew>
    </form>
  );
};
