import ReactDOM from 'react-dom/client';

import 'focus-visible';

import { initErrorTracker } from 'kognia/error-tracking/init';
import { initMetricsCollector } from 'kognia/metrics/init';
import { APP_COLLECT_METRICS } from 'kognia/metrics/utils';

import App from './App';

initErrorTracker();
APP_COLLECT_METRICS && initMetricsCollector();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(<App />);
