import { Paper, Stack, Typography } from '@mui/material';
import { fontSizes } from 'kognia-ui';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { usePlayerWithDefaults } from 'api/player/usePlayerWithDefaults';
import { getPlayerPositionFromValue } from 'entities/dashboard/types/dashboard.types';
import { getPlayerValueFromFixtures } from 'features/dashboard/player-profile-header/utils/getPlayerValueFromFixtures';
import IconBall from 'shared/components/icons/icon-ball';
import { PlayerPhoto } from 'shared/components/player-photo/PlayerPhoto';
import { TeamLogo } from 'shared/components/team-logo';
import { invariant } from 'shared/utils/invariant';

const HEADER_MAX_WIDTH = '500px';
const HEADER_MIN_WIDTH = '316px';

export const PlayerProfileHeaderFeature = memo(() => {
  const { playerId } = useParams();
  invariant(playerId);
  const { data: player } = usePlayerWithDefaults(playerId);
  const { t } = useTranslation('player');
  const playerPosition = getPlayerValueFromFixtures(player, 'position');
  const playerJerseyNumber = getPlayerValueFromFixtures(player, 'jerseyNumber');

  return (
    <Stack direction={'row'}>
      <Paper elevation={0} sx={{ padding: 3, maxWidth: HEADER_MAX_WIDTH, minWidth: HEADER_MIN_WIDTH }}>
        <Stack direction={'row'} gap={2} alignItems={'center'}>
          <PlayerPhoto withBorder size={'xlarge'} src={player.photoUrl} />
          <Stack gap={0.5}>
            <Typography
              fontSize={fontSizes.medium}
              fontWeight={(theme) => theme.typography.fontWeightBold}
              paddingLeft={(theme) => theme.spacing(0.5)}
            >
              {Boolean(playerJerseyNumber) && `${playerJerseyNumber}. `}
              {player.name}
            </Typography>
            {player.playerDefaults?.team ? (
              <Stack direction={'row'} gap={1} alignItems={'center'}>
                <TeamLogo src={player.playerDefaults.team.logoUrl} /> {player.playerDefaults.team.name}
              </Stack>
            ) : null}

            {Boolean(playerPosition) && (
              <Stack direction={'row'} gap={1} alignItems={'center'}>
                <IconBall size={'small'} />
                <Typography fontSize={fontSizes.default}>
                  {t(`position.${getPlayerPositionFromValue(playerPosition)}`)}
                </Typography>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Paper>
    </Stack>
  );
});

PlayerProfileHeaderFeature.displayName = 'PlayerProfileHeaderFeature';
