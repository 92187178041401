import { Box, IconButton, Typography } from '@mui/material';
import { fontSizes } from 'kognia-ui';
import { useTranslation } from 'react-i18next';

import { PlaylistItemsList } from 'pages/tactical-analysis/components/tactical-analysis/add-to-playlist-menu/current-playlist-menu/styled';
import IconDelete from 'shared/components/icons/icon-delete';
import { Playlist } from 'shared/types';

interface Props {
  selectedPlaylist: Playlist;
  onDeletePlaylistItem: (playlistItemId: string) => void;
}

export const CurrentPlaylistMenu = ({ selectedPlaylist, onDeletePlaylistItem }: Props) => {
  const { t } = useTranslation();

  const playlistItems = selectedPlaylist.playlistItems;

  return (
    <Box sx={{ flex: '1 1 50%' }}>
      <Box display='flex' alignItems='center' bgcolor='secondary.main'>
        <Typography
          py={0.5}
          px={1}
          color='common.white'
          fontSize={fontSizes.small}
          fontWeight='fontWeightMedium'
          overflow='hidden'
          whiteSpace='nowrap'
        >
          {`${t('timeline:current-playlist-menu.title')} (${playlistItems.length})`}
        </Typography>
      </Box>
      <PlaylistItemsList>
        {playlistItems.length > 0 ? (
          <>
            {playlistItems.map((item) => (
              <Box
                display='flex'
                alignItems='center'
                justifyContent='space-between'
                key={`current-playlist-${selectedPlaylist.id}-${item.id}`}
              >
                <Typography fontSize={fontSizes.small} fontWeight='fontWeightRegular'>
                  {item.name}
                </Typography>
                <IconButton sx={{ padding: 0 }} onClick={() => onDeletePlaylistItem(item.id)}>
                  <IconDelete
                    size='small'
                    color='secondary'
                    sx={{
                      '&:hover': {
                        color: 'common.white',
                      },
                    }}
                  />
                </IconButton>
              </Box>
            ))}
          </>
        ) : (
          <Typography fontSize={fontSizes.xxSmall} fontWeight='fontWeightMedium'>
            {t('timeline:current-playlist-menu.no-clips')}
          </Typography>
        )}
      </PlaylistItemsList>
    </Box>
  );
};
