import { TacticId } from '@kognia/tactical-analysis-data';
import React, { useCallback, useMemo } from 'react';

import { createSelectFundamentalsPayload, useBatchUpdatePlaylistItems } from 'api/playlist/useBatchUpdatePlaylistItems';
import { useMapVideos } from 'entities/playlist/hooks/use-map-videos/useMapVideos';
import { useIsBulkModeActive } from 'entities/playlist/hooks/useIsBulkModeActive';
import { useActivePlaylistItemIds } from 'features/playlist/playlist-overlay-selector-panel/hooks/useActivePlaylistItemIds';
import { useSelectedItemsOverlayTactics } from 'features/playlist/playlist-overlay-selector-panel/hooks/useSelectedItemsOverlayTactics';
import { OverlaySelectorPanel } from 'shared/components/overlay-selector-panel';
import {
  useCurrentPlaylistItem,
  usePlaylistItems,
  useVideoPlayerActions,
  useVideoPlayerId,
} from 'shared/components/video-player/hooks';
import { useOverlaysFrameInfo } from 'shared/components/video-player/hooks/use-overlays-controller';
import { areAllOverlayTacticsSelected } from 'shared/components/video-player/util';
import { Playlist, TacticIdOrAll } from 'shared/types/playlist/types';

type Props = {
  playlistId: string;
  readOnly?: boolean;
};

export const PlaylistOverlaysSelectorPanelFeature = ({ playlistId, readOnly = false }: Props) => {
  const isBulkModeActive = useIsBulkModeActive(playlistId);
  const videoPlayerId = useVideoPlayerId();
  const playlistItems = usePlaylistItems();
  const playlistItem = useCurrentPlaylistItem();
  const actions = useVideoPlayerActions();
  const mapVideos = useMapVideos();
  const playlistItemIds = useActivePlaylistItemIds(playlistId);
  const { data, isPending } = useSelectedItemsOverlayTactics(playlistId);
  const frameInfo = useOverlaysFrameInfo();

  const handleReplacePlaylistItem = useCallback(
    (playlist: Playlist) => {
      actions.updatePlaylistItems(mapVideos(playlist));
    },
    [mapVideos, actions],
  );

  const { updateBatchPlaylistItems } = useBatchUpdatePlaylistItems(playlistId);

  const handleToggleTactic = useCallback(
    (tactics: TacticId[]) => {
      const selectedTactics = (tactics.length === data?.tactics.length ? ['all'] : tactics) as TacticIdOrAll[];
      updateBatchPlaylistItems(
        createSelectFundamentalsPayload(playlistItemIds.ids, selectedTactics),
        handleReplacePlaylistItem,
      );
    },
    [handleReplacePlaylistItem, updateBatchPlaylistItems, playlistItemIds, data],
  );

  const handleToggleTacticReadOnly = useCallback(
    (tactics: TacticId[]) => {
      const selectedTactics = (tactics.length === data?.tactics.length ? ['all'] : tactics) as TacticIdOrAll[];

      actions.updatePlaylistItem({
        ...playlistItem,
        fundamentalsSelected: { ...playlistItem.fundamentalsSelected, fundamentalsSelected: selectedTactics },
      });
    },
    [actions, data, playlistItem],
  );

  const onIsOverlayPanelOpenChange = useCallback(
    (open: boolean) => {
      actions.changeAutoplayNextPlaylistItem(!open);
    },
    [actions],
  );

  const selectedTactics = useMemo(() => {
    const activePlaylistItems = playlistItems.filter((playlistItem) => playlistItemIds.ids.includes(playlistItem.id));

    return activePlaylistItems
      .map((item) =>
        areAllOverlayTacticsSelected(item)
          ? data?.playlistItemsTactics[item.id] ?? []
          : (item.fundamentalsSelected.fundamentalsSelected as TacticId[]),
      )
      .flat();
  }, [data, playlistItemIds, playlistItems]);

  return (
    <OverlaySelectorPanel
      id={videoPlayerId}
      frameTactics={isBulkModeActive ? [] : frameInfo.frameTactics}
      onIsOverlayPanelOpenChange={onIsOverlayPanelOpenChange}
      availableTactics={data?.tactics || []}
      isLoading={isPending && !data}
      initialSelectedTactics={selectedTactics}
      onTacticsChange={readOnly ? handleToggleTacticReadOnly : handleToggleTactic}
    />
  );
};
