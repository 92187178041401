import styles from 'pages/playlists/components/playlist-duration/PlaylistsDuration.module.scss';
import IconTime from 'shared/components/icons/icon-time';
import { secondsAsDuration } from 'shared/utils/seconds-as-duration';

interface Props {
  duration: number;
}

const PlayListDuration = ({ duration }: Props) => {
  return (
    <div className={styles.duration}>
      <IconTime /> {secondsAsDuration(duration, false)}
    </div>
  );
};

export default PlayListDuration;
