import { Typography } from '@mui/material';
import { fontSizes } from 'kognia-ui';

import { PlayerPhotoPreviewContainer } from 'pages/backoffice/pages/players/components/player-photo-preview/styled';
import { PlayerPhoto } from 'shared/components/player-photo/PlayerPhoto';

interface Props {
  photoUrl?: string | null;
}

export const PlayerPhotoPreview = ({ photoUrl }: Props) => {
  return (
    <PlayerPhotoPreviewContainer>
      <PlayerPhoto size={'large'} src={photoUrl} />
      <Typography fontSize={fontSizes.small} marginTop={1}>
        Image preview
      </Typography>
    </PlayerPhotoPreviewContainer>
  );
};
