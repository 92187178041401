import { InfinityQueryDataResult } from 'api/hooks/useInfinityQuery/types';
import { SeasonApi } from 'pages/backoffice/api/seasons/types';
import { SeasonsApiResponse } from 'pages/backoffice/api/seasons/use-seasons/types';
import { transformStageSummaries } from 'pages/backoffice/api/stages/use-stages/transformer';
import { Season } from 'pages/backoffice/types/seasons';
import { chooseNextCursorValue } from 'shared/utils/choose-next-cursor-value';

export const transformSeason = (item: SeasonApi): Season => ({
  id: item.uuid,
  year: item.year,
  crossesYear: item.crosses_year,
  competition: {
    id: item.competition.uuid,
    name: item.competition.name,
    country: item.competition.country,
  },
  stages: transformStageSummaries(item.stages),
});

export const transformSeasons = (response: SeasonsApiResponse): InfinityQueryDataResult<Season> => {
  return {
    data: {
      items: response.data.map(transformSeason),
      page: response.page,
    },
    nextCursor: chooseNextCursorValue(response.page.totalPages, response.page.number),
  };
};
