import { Typography } from '@mui/material';
import { Colors, fontSizes, fontWeight } from 'kognia-ui';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  KeypadDefaultBadge,
  KeypadIconContainer,
  KeypadListItemContainer,
  KeypadMenuWrapper,
  KeypadNameContainer,
} from 'pages/tagging-tool-keypad-list/components/keypad-list/components/keypad-list-item/index.styled';
import IconCopy from 'shared/components/icons/icon-copy';
import IconDelete from 'shared/components/icons/icon-delete';
import IconEdit from 'shared/components/icons/icon-edit';
import IconStar from 'shared/components/icons/icon-star';
import IconTag from 'shared/components/icons/icon-tag';
import KebabMenu, { MenuListOption } from 'shared/components/kebab-menu';
import Spinner from 'shared/components/spinner';
import { FetchKeypadsItem } from 'tagging-tool/service/keypad';
import { fetchUpdateKeypad } from 'tagging-tool/service/keypad.service';

export type KeypadListItemRowProps = {
  data: FetchKeypadsItem;
  onEdit: (data: FetchKeypadsItem) => void;
  onChangeName: (data: FetchKeypadsItem) => void;
  onDuplicate: (data: FetchKeypadsItem) => void;
  onSetDefault: (data: FetchKeypadsItem) => void;
  onRemove: (data: FetchKeypadsItem) => void;
};

export const KeypadListItem = ({
  data,
  onEdit,
  onChangeName,
  onDuplicate,
  onSetDefault,
  onRemove,
}: KeypadListItemRowProps) => {
  const kebabRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();
  const [isDefaultLoading, setIsDefaultLoading] = useState<boolean>(false);

  const handleEdit = useCallback(() => onEdit(data), [data, onEdit]);
  const handleChangeName = useCallback(() => onChangeName(data), [data, onChangeName]);
  const handleDuplicate = useCallback(() => onDuplicate(data), [data, onDuplicate]);
  const handleRemove = useCallback(() => onRemove(data), [data, onRemove]);
  const handleSetDefault = useCallback(() => {
    const keypad: FetchKeypadsItem = { ...data, isDefault: true };
    setIsDefaultLoading(true);

    fetchUpdateKeypad(keypad).then((res) => {
      setIsDefaultLoading(false);

      if (!res.error) {
        onSetDefault(keypad);
      }
    });
  }, [data, onSetDefault]);

  const menuOptions: MenuListOption[] = useMemo(
    () => [
      {
        displayText: t('tagging-tool:keypad.menu-change-name'),
        icon: <IconEdit size='small' color='secondary' />,
        onClick: handleChangeName,
      },

      {
        displayText: t('tagging-tool:keypad.menu-duplicate'),
        icon: <IconCopy size='small' color='secondary' />,
        onClick: handleDuplicate,
      },
      {
        isHidden: data.isDefault,
        displayText: (
          <>
            {t('tagging-tool:keypad.menu-set-default')}
            {isDefaultLoading && <Spinner />}
          </>
        ),
        icon: <IconStar size='small' color='secondary' />,
        onClick: handleSetDefault,
      },
      {
        displayText: t('tagging-tool:keypad.menu-remove'),
        icon: <IconDelete size='small' color='secondary' />,
        onClick: handleRemove,
        isHidden: data.isDefault,
      },
    ],
    [handleChangeName, handleDuplicate, handleRemove, handleSetDefault, isDefaultLoading, data.isDefault, t],
  );

  return (
    <KeypadListItemContainer onClick={handleEdit}>
      <KeypadNameContainer>
        <Typography fontWeight={fontWeight['500']} fontSize={fontSizes.default}>
          {data.name}
        </Typography>
        {data.isDefault && <KeypadDefaultBadge>{t('tagging-tool:keypad.default-keypad')}</KeypadDefaultBadge>}
      </KeypadNameContainer>
      <KeypadIconContainer>
        <IconTag size='small' color='secondary' />
        <Typography fontSize={fontSizes.small} color={Colors.storm} fontWeight={fontWeight['500']}>
          {t('tagging-tool:keypad.button-counter', { count: data.nTags })}
        </Typography>
      </KeypadIconContainer>
      <KeypadMenuWrapper>
        <KebabMenu ref={kebabRef} options={menuOptions} id={data.id} />
      </KeypadMenuWrapper>
    </KeypadListItemContainer>
  );
};
