import { ProcessGamePreset } from 'pages/backoffice/entities/process-game/types/processGame.types';
import {
  ProcessGameEventsProvider,
  ProcessGameFieldsNames,
  ProcessGamePipelineType,
  ProcessGameTrackingProvider,
} from 'pages/backoffice/widgets/process-game/ProcessGameForm/types/processGameForm.types';
import { SportlogiqTrackingType } from 'pages/backoffice/widgets/process-game/ProcessGameForm/types/processGameFormSubforms.types';

const processGameDefaultValues = {
  [ProcessGameFieldsNames.bucket]: 'kognia-client-uploads',
  [ProcessGameFieldsNames.runProcessing]: true,
  [ProcessGameFieldsNames.restartProcessing]: false,
};

export const PRESETS_FORMS_DEFAULTS = {
  [ProcessGamePreset.ADVANCED]: processGameDefaultValues,
  [ProcessGamePreset.PERFORMANCE]: {
    ...processGameDefaultValues,
    [ProcessGameFieldsNames.showOverlays]: true,
    [ProcessGameFieldsNames.autoRunTacticalAnalysis]: false,
    [ProcessGameFieldsNames.sportlogiqTrackingType]: SportlogiqTrackingType.RAW_GAME_TRACKING_DATA,
  },
  [ProcessGamePreset.SCOUTING]: {
    ...processGameDefaultValues,
    [ProcessGameFieldsNames.pipelineType]: ProcessGamePipelineType.THIRD_PARTY_TACTICAL_TRACKING,
    [ProcessGameFieldsNames.trackingProvider]: ProcessGameTrackingProvider.SPORTLOGIQ,
    [ProcessGameFieldsNames.eventsProvider]: ProcessGameEventsProvider.OPTA,
    [ProcessGameFieldsNames.showOverlays]: false,
    [ProcessGameFieldsNames.autoRunTacticalAnalysis]: true,
    [ProcessGameFieldsNames.sportlogiqTrackingType]: SportlogiqTrackingType.GAME_TRACKING_DATA,
  },
};
