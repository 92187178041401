import { Stack, Typography } from '@mui/material';

import { KogniaUser } from 'api/backoffice/user/use-kognia-users/types';
import { BulkButton } from 'shared/components/bulk-button/BulkButton';
import { CheckboxWithCustomColor } from 'shared/components/CheckboxWithCustomColor/CheckboxWithCustomColor';
import IconDelete from 'shared/components/icons/icon-delete';
import { IconUser } from 'shared/components/icons/icon-user';
import { ItemsListBulk } from 'shared/components/items-list-bulk/ItemsListBulk';

interface Props {
  users: KogniaUser[];
  checkedUsers: string[];
  setCheckedUsers: React.Dispatch<React.SetStateAction<string[]>>;
  openDeleteModal: () => void;
  openAddUsersToEnvironmentsModal: () => void;
  openRemoveUsersFromEnvironmentsModal: () => void;
}

export const UsersBulkBar = ({
  users,
  checkedUsers,
  setCheckedUsers,
  openDeleteModal,
  openAddUsersToEnvironmentsModal,
  openRemoveUsersFromEnvironmentsModal,
}: Props) => {
  const isAllUsersChecked = users.length === checkedUsers.length;
  const isIndeterminate = !isAllUsersChecked && Boolean(checkedUsers.length);

  const handleToggleCheckedUsers = () => {
    if (isIndeterminate || !checkedUsers.length) {
      setCheckedUsers(users.map((user) => user.id));
    } else {
      setCheckedUsers([]);
    }
  };

  return (
    <ItemsListBulk sx={(theme) => ({ position: 'static', padding: theme.spacing(1, 2) })}>
      <Stack direction='row' alignItems='center'>
        <CheckboxWithCustomColor
          customColor='white'
          checked={isAllUsersChecked}
          onChange={handleToggleCheckedUsers}
          indeterminate={isIndeterminate}
        />
        <Typography variant='body2'>{`All Users (${checkedUsers.length}/${users.length})`}</Typography>
      </Stack>

      <Stack direction='row' alignItems='center' gap={1}>
        <BulkButton
          onClick={openAddUsersToEnvironmentsModal}
          IconComponent={IconUser}
          label={'Add to Environments'}
          disabled={!checkedUsers.length}
        />

        <BulkButton
          onClick={openRemoveUsersFromEnvironmentsModal}
          IconComponent={IconUser}
          label={'Remove from Environments'}
          disabled={!checkedUsers.length}
        />

        <BulkButton
          onClick={openDeleteModal}
          IconComponent={IconDelete}
          label={'Delete'}
          disabled={!checkedUsers.length}
        />
      </Stack>
    </ItemsListBulk>
  );
};
