import { useEffect } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { routes } from 'kognia/router/routes';
import { useMetadataGame } from 'pages/backoffice/api/games/use-metadata-game';
import { useSetGameData } from 'pages/backoffice/pages/games/game-form/config';
import { MatchTeamTypes } from 'shared/types/teams/types';
import { invariant } from 'shared/utils/invariant';

export const EditGame = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  invariant(id);

  const {
    data: game,
    isLoading,
    isSuccess,
  } = useMetadataGame({
    gameId: id,
  });

  const setHomeTeamMetaData = useSetGameData();

  useEffect(() => {
    if (game && isSuccess) {
      const homeTeam = {
        team: game.homeTeam,
        players: game.players.filter((p) => p.teamId === game.homeTeam.id),
      };
      const awayTeam = {
        team: game.awayTeam,
        players: game.players.filter((p) => p.teamId === game.awayTeam.id),
      };

      const gameInfo = {
        name: game.name,
        date: game.date,
        scoreHomeTeam: game.homeTeamScore,
        scoreOpponentTeam: game.awayTeamScore,
        duration: game.duration,
        season: game.season,
        venue: game.venue,
        stageId: game.stage.id,
        pitchLength: game.pitchLength,
        pitchWidth: game.pitchWidth,
        homeCoach: game.homeCoach,
        awayCoach: game.awayCoach,
      };

      setHomeTeamMetaData({
        [MatchTeamTypes.HOME]: homeTeam,
        [MatchTeamTypes.OPPONENT]: awayTeam,
        gameInfo,
      });

      navigate(generatePath(routes.BACKOFFICE_GAME_FORM_STEP_1, { id: game.id }), { replace: true });
    }
  }, [isSuccess, game, isLoading, setHomeTeamMetaData, navigate]);

  if (isSuccess && !game) return <div>Game not found</div>;
  return null;
};
