import { ListItem } from '@mui/material';
import isEqual from 'lodash/isEqual';
import React, { useMemo } from 'react';

import IconDone from 'shared/components/icons/icon-done';
import { PlayingMode } from 'shared/components/video-player/types';
import {
  VideoSourceList,
  VideoSourceListDivider,
  VideoSourceListItemButton,
  VideoSourceListItemIcon,
  VideoSourceListItemText,
  VideSourceListItemContent,
} from 'shared/components/video-player/video-player-bar/video-source-select-list/index.styled';

export enum VideoSourceSelectListItemTypes {
  ListItemMode = 'ListItemMode',
  ListItemOption = 'ListItemOption',
  Separator = 'Separator',
}

interface ListItemBase {
  id: string;
  label: string;
  onClick: () => void;
}

export interface VideoSourceSelectListItemView extends ListItemBase {
  type: VideoSourceSelectListItemTypes.ListItemMode;
  playingMode: PlayingMode;
  onRemove?: (id: string) => void;
  onEdit?: (event: React.MouseEvent, playingMode: PlayingMode) => void;
}

export interface VideoSourceSelectListItemOption extends ListItemBase {
  type: VideoSourceSelectListItemTypes.ListItemOption;
  icon: React.ReactNode;
}

export interface VideoSourceSelectSeparator {
  type: VideoSourceSelectListItemTypes.Separator;
}

export type VideoSourceSelectListItem =
  | VideoSourceSelectListItemView
  | VideoSourceSelectSeparator
  | VideoSourceSelectListItemOption;

interface Props {
  options: VideoSourceSelectListItem[];
  selected: VideoSourceSelectListItemView;
  onClose: () => void;
}

export const VideoSourceSelectList = ({ options, selected, onClose }: Props) => {
  const optionsList = useMemo(
    () =>
      options.map((listItem: VideoSourceSelectListItem, index) => {
        if (listItem.type === VideoSourceSelectListItemTypes.Separator) {
          return <VideoSourceListDivider key='separator' />;
        }

        const hasPrefixIcon = 'icon' in listItem;

        const handleOnClick = (event: React.MouseEvent) => {
          event.stopPropagation();
          listItem.onClick();
          onClose();
        };

        const isChecked = isEqual(selected, listItem);
        const isInset = hasPrefixIcon ? false : !isChecked;

        return (
          <ListItem key={`${listItem.label}_${index}`} disablePadding>
            <VideoSourceListItemButton onClick={handleOnClick}>
              <VideSourceListItemContent>
                {hasPrefixIcon ? <VideoSourceListItemIcon>{listItem.icon}</VideoSourceListItemIcon> : null}
                {isChecked ? (
                  <VideoSourceListItemIcon>
                    <IconDone size='small' sx={{ color: 'common.white' }} />
                  </VideoSourceListItemIcon>
                ) : null}
                <VideoSourceListItemText disableTypography inset={isInset} isChecked={isChecked}>
                  {listItem.label}
                </VideoSourceListItemText>
              </VideSourceListItemContent>
            </VideoSourceListItemButton>
          </ListItem>
        );
      }),
    [onClose, options, selected],
  );

  return <VideoSourceList>{optionsList}</VideoSourceList>;
};
