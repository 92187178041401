import { SvgIcon, type SvgIconProps } from 'shared/components/icons/svg-icon/SvgIcon';

export const IconDoubleArrowRight = (props: Omit<SvgIconProps, 'children'>): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule='evenodd'
        d='M11.036 18.464c-.257-.257-.257-.673 0-.929L16.57 12l-5.535-5.535c-.257-.257-.257-.673 0-.929s.673-.257.929 0l5.999 5.999c.257.257.257.673 0 .929l-5.999 5.999c-.257.257-.673.257-.929 0zm-5 0c-.257-.257-.257-.673 0-.929L11.57 12 6.036 6.465c-.257-.257-.257-.673 0-.929s.673-.257.929 0l5.999 5.999c.123.123.193.29.193.465s-.069.341-.193.465l-5.999 5.999c-.257.257-.673.257-.929 0z'
      />
    </SvgIcon>
  );
};
