import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { Colors, fontSizes } from 'kognia-ui';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CounterBadge } from 'entities/playlist/ui/CounterBadge';
import styles from 'features/playlist/user-playlist-items-list/ui/user-playlist-item/ui/rename-dialog/RenameDialog.module.scss';
import IconEdit from 'shared/components/icons/icon-edit';
import IconPlay from 'shared/components/icons/icon-play';
import Input from 'shared/components/input';
import { PlaylistItemType } from 'shared/components/video-player/types';
import { secondsAsDuration } from 'shared/utils/seconds-as-duration';

interface Props {
  videoIndex: number;
  isOpen: boolean;
  playlistItem: PlaylistItemType;
  setIsOpen: (isOpen: boolean) => void;
  onClose: () => void;
  updatePlaylistItem: (playlistItemId: string, data: { [key: string]: any }) => void;
}

const RenameDialog = ({ isOpen = false, onClose, setIsOpen, playlistItem, updatePlaylistItem, videoIndex }: Props) => {
  const { t } = useTranslation();
  const [inputName, setInputName] = useState(playlistItem.name);

  useEffect(() => {
    setInputName(playlistItem.name);
  }, [playlistItem.name]);

  const handleNameChange = (event: React.FormEvent<HTMLInputElement>) => {
    setInputName(event.currentTarget.value);
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (inputName === '') {
      return false;
    }
    updatePlaylistItem(playlistItem.id, { name: inputName });
    onClose();
    setIsOpen(false);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setInputName(playlistItem.name);
    setIsOpen(false);
    onClose();
  };

  return (
    <Dialog
      keepMounted={false}
      className={styles.dialog}
      open={isOpen}
      onClose={handleClose}
      onClick={(event) => event.stopPropagation()}
      aria-labelledby='confirm-dialog-title'
      aria-describedby='confirm-dialog-description'
    >
      <div className={styles.dialog}>
        <form onSubmit={handleSubmit}>
          <DialogTitle
            display='flex'
            alignItems='center'
            flexDirection='column'
            // TODO use from theme
            fontSize={fontSizes.small}
            lineHeight={1.67}
            fontWeight='fontWeightMedium'
            textTransform='uppercase'
          >
            <Box
              display='flex'
              flexDirection='row'
              alignItems='center'
              justifyContent='center'
              width='48px'
              height='48px'
              mb={3}
              borderRadius='50%'
              // TODO use from theme
              bgcolor={Colors.background}
            >
              <IconEdit size='small' />
            </Box>
            {t('playlist-detail:rename-dialog.title')}
          </DialogTitle>
          <DialogContent
            sx={({ spacing, palette }) => ({
              alignItems: 'center',
              borderRadius: '4px',
              border: `1px solid ${palette.primary.main}`,
              display: 'flex',
              gap: spacing(0.5),
              marginBottom: spacing(2),
              padding: `${spacing(0.5, 1, 0.5)} !important`,
            })}
          >
            <CounterBadge
              data-element-name='counter'
              sx={({ palette }) => ({ backgroundColor: palette.primary.main, color: palette.common.white })}
            >
              {(videoIndex + 1).toString().padStart(3, '0')}
            </CounterBadge>
            <IconPlay size='small' color='primary' />
            <Input
              autoFocus
              autoSelect
              id={`edit-playlist-item-name-${playlistItem.id}`}
              type={'text'}
              value={inputName}
              onChange={handleNameChange}
            />
            <Typography component='span' fontSize={fontSizes.small} lineHeight={1} marginLeft={1} marginRight={0.5}>
              {secondsAsDuration(playlistItem.duration, false)}
            </Typography>
          </DialogContent>
          <DialogActions className={styles.actions}>
            <Button onClick={handleClose} variant='outlined' color='secondary'>
              {t('common:actions.cancel')}
            </Button>
            <Button variant='contained' type='submit'>
              {t('common:actions.save')}
            </Button>
          </DialogActions>
        </form>
      </div>
    </Dialog>
  );
};

export default RenameDialog;
