import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from 'pages/recordings-list/components/vod-recording-card/recording-states/RecordingStates.module.scss';
import IconTag from 'shared/components/icons/icon-tag';

interface Props {
  active: boolean;
}

const StateTags = ({ active }: Props) => {
  const { t } = useTranslation();
  return (
    <span className={classNames(styles.stateBlock, active ? styles.active : styles.inactive)}>
      <IconTag size='small' color={active ? 'primary' : 'disabled'} />
      <div>{t('recording:states.tags')}</div>
    </span>
  );
};

export default StateTags;
