import classNames from 'classnames';
import React from 'react';

import { SvgIconProps } from 'shared/components/icons/svg-icon/SvgIcon';
import styles from 'shared/components/vertical-icon-button/VerticalIconButton.module.scss';

interface VerticalIconButtonProps {
  children: React.ReactChildren | string;
  Icon: React.JSXElementConstructor<React.PropsWithChildren<SvgIconProps>>;
  onClick: () => void;
  className?: string;
}

export const VerticalIconButton = ({
  children,
  Icon,
  onClick,
  className = '',
}: React.PropsWithChildren<VerticalIconButtonProps>) => {
  return (
    <button onClick={onClick} className={classNames(styles.root, className)}>
      <span>
        <Icon size='small' />
      </span>
      <span className={styles.text}>{children}</span>
    </button>
  );
};
