import { SvgIcon, type SvgIconProps } from 'shared/components/icons/svg-icon/SvgIcon';

const IconReplay = (props: Omit<SvgIconProps, 'children'>): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path d='M12 6.49998V2.83331L7.41666 7.41665 12 12V8.33331c3.0342 0 5.5 2.46579 5.5 5.49999s-2.4658 5.5-5.5 5.5-5.50001-2.4658-5.50001-5.5H4.66666c0 4.0517 3.28166 7.3333 7.33334 7.3333s7.3333-3.2816 7.3333-7.3333S16.0517 6.49998 12 6.49998z' />
    </SvgIcon>
  );
};
export default IconReplay;
