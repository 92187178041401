import React from 'react';

import IconBackward5 from 'shared/components/icons/icon-backward-5';
import { IconDownload } from 'shared/components/icons/icon-download';
import IconForward5 from 'shared/components/icons/icon-forward-5';
import IconFullScreen from 'shared/components/icons/icon-full-screen';
import IconPlay from 'shared/components/icons/icon-play';
import IconPlaySpeed from 'shared/components/icons/icon-play-speed';
import ButtonControls from 'shared/components/video-player/video-player-component/components/button-controls';
import ButtonControlsLeft from 'shared/components/video-player/video-player-component/components/button-controls-left';
import ButtonControlsRight from 'shared/components/video-player/video-player-component/components/button-controls-right';
import { PlayCircle } from 'shared/components/video-player/video-player-component/components/player-skeleton/play-circle';
import styles from 'shared/components/video-player/video-player-component/components/player-skeleton/PlayerSkeleton.module.scss';
import VideoControls from 'shared/components/video-player/video-player-component/components/video-controls';

export const PlayerSkeleton = () => {
  return (
    <div className={styles.container}>
      <div className={styles.playCircleContainer}>
        <PlayCircle />
      </div>

      <VideoControls disabled>
        <ButtonControls>
          <ButtonControlsLeft>
            <IconBackward5 color='disabled' />
            <IconPlay color='disabled' />
            <IconForward5 color='disabled' />
          </ButtonControlsLeft>

          <div className={styles.progressBar}>
            <div className={styles.progressBarInner}></div>
          </div>

          <ButtonControlsRight>
            <IconPlaySpeed color='disabled' />
            <IconDownload color='disabled' />
            <IconFullScreen color='disabled' />
          </ButtonControlsRight>
        </ButtonControls>
      </VideoControls>
    </div>
  );
};
