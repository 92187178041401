import { Avatar, MenuItem, Stack, Typography } from '@mui/material';
import { forwardRef, MouseEvent, Ref, useCallback } from 'react';

interface Props {
  photoUrl: string | null | undefined;
  label: string;
  onClick: () => void;
  selected?: boolean;
}

export const PlayerSearchOption = forwardRef(
  ({ onClick, photoUrl, label, selected }: Props, ref?: Ref<HTMLLIElement> | undefined) => {
    const handleClick = useCallback(
      (event: MouseEvent) => {
        event.stopPropagation();
        onClick();
      },
      [onClick],
    );
    return (
      <MenuItem ref={ref} disableRipple onClick={handleClick} selected={selected}>
        <Stack direction='row' alignItems='center' gap={1}>
          <Avatar src={photoUrl ?? undefined} alt='' sx={{ width: 24, height: 24 }} />
          <Typography variant='body2' sx={{ wordBreak: 'break-word' }}>
            {label}
          </Typography>
        </Stack>
      </MenuItem>
    );
  },
);

PlayerSearchOption.displayName = 'PlayerSearchOption';
