import { styled, Tooltip } from '@mui/material';
import React from 'react';

import IconShield from 'shared/components/icons/icon-shield';
import styles from 'shared/components/team-logo/TeamLogo.module.scss';

type Props = {
  src: string | null | undefined;
  name?: string;
};

const LogoImg = styled('img')(({ theme }) => ({
  height: theme.spacing(3),
}));

const LogoContainer = styled('div')(({ theme }) => ({
  height: theme.spacing(3),
  width: theme.spacing(3),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const NoLogoImg = styled(IconShield)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

export const TeamLogo = ({ src, name }: Props) => {
  return (
    <Tooltip title={name ?? ''}>
      <LogoContainer>
        {src ? <LogoImg alt={name} src={src} /> : <NoLogoImg size='small' color='secondary' className={styles.icon} />}
      </LogoContainer>
    </Tooltip>
  );
};
