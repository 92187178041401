import { useSuspenseQuery, UseSuspenseQueryResult } from '@tanstack/react-query';

import { useBackendApi, UseBackendApiParams } from 'api/hooks/useBackendApi';
import { HTTPMethod, UseApiFetchOptions } from 'api/types';

interface UseApiFetchInterface<T> {
  queryRef: string[];
  transformer: (response: any) => T;
  url: string;
  options?: UseApiFetchOptions;
  data?: UseBackendApiParams;
  method?: HTTPMethod.GET | HTTPMethod.POST;
}

export const useFetchSuspenseRequest = <T>({
  queryRef,
  url,
  options,
  transformer,
  data,
  method = HTTPMethod.GET,
}: UseApiFetchInterface<T>): UseSuspenseQueryResult<T> => {
  return useSuspenseQuery<T>({
    queryKey: queryRef,
    queryFn: () => useBackendApi(url, method, transformer, data),
    ...options,
  });
};
