import { PushEventOptions } from '@grafana/faro-core/dist/types/api/events/types';
import { EventAttributes, faro } from '@grafana/faro-web-sdk';
import { useCallback } from 'react';

import { APP_COLLECT_METRICS } from 'kognia/metrics/utils';
import { MetricsNames } from 'shared/types/metrics';

export const useMetrics = () => {
  const pushEvent = useCallback(
    <K extends EventAttributes>(
      name: MetricsNames,
      attributes?: K,
      domain?: string,
      options?: PushEventOptions,
    ): ReturnType<typeof faro.api.pushEvent> => {
      if (!APP_COLLECT_METRICS) return;

      return faro.api.pushEvent(name, attributes, domain, options);
    },
    [],
  );

  return {
    pushEvent,
  };
};
