import { Box, Fade, Popper, Stack, styled } from '@mui/material';
import { css } from '@mui/system';
import { Colors, fontSizes } from 'kognia-ui';
import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import {
  clipAtomFamily,
  useClipsIdsSelection,
} from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/atoms';
import { Clip } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/types/clip';
import { SelectableClip } from 'pages/tactical-analysis/components/timeline/timeline-table/components/clip';
import { CSS_CLIP_ZOOM_WIDTH } from 'pages/tactical-analysis/components/timeline/timeline-table/components/timeline-css-variables';
import { useRowTeam } from 'pages/tactical-analysis/components/timeline/timeline-table/hooks/use-row-team';
import { useTacticalAnalysisMode } from 'pages/tactical-analysis/hooks/use-tactical-analysis-mode';
import { TacticActionType } from 'pages/tactical-analysis/types';

const ParentSelectableClipCounter = styled(Box, {
  shouldForwardProp: (propName) => propName !== 'isDisabled',
})<{ isDisabled: boolean }>(
  ({ theme, isDisabled }) => css`
    position: absolute;
    bottom: 0;
    right: 0;
    padding: ${theme.spacing(0.25)};
    height: 100%;
    border-radius: ${theme.spacing(0, 0.25, 0.25, 0)};
    z-index: 1;
    background: ${Colors.navy};
    color: ${Colors.white};
    pointer-events: none;
    line-height: 1;
    font-size: ${fontSizes.xxxSmall};
    display: flex;
    align-items: center;
    justify-content: center;

    ${isDisabled && `opacity: 0.2`}
  `,
);

const ParentSelectableClipContainer = styled(Box)(
  ({ theme }) => css`
    position: relative;
    height: 100%;
    z-index: 0;
    container-type: inline-size;

    @container (max-width: 20px) {
      & .parent-clip-counter {
        top: 0;
        width: 100%;
        height: 4px;
        border-radius: ${theme.spacing(0.25, 0.25, 0, 0)};

        span {
          display: none;
        }
      }
    }
  `,
);

enum SelectionState {
  All = 'all',
  Some = 'some',
  None = 'none',
}

interface ParentClipProps {
  recordingId: string;
  clip: Clip;
  clips: Clip[];
  parentClip: Clip;
}

export const ParentClip = ({ recordingId, clip, clips, parentClip }: ParentClipProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLDivElement>(null);
  const [hasScroll, setHasScroll] = useState(false);
  const selectedClipsIds = useClipsIdsSelection();
  const clipWithSelection = useRecoilValue(clipAtomFamily(clip.id));
  const tacticalAnalysisMode = useTacticalAnalysisMode(recordingId);
  const open = Boolean(anchorEl);
  const childClipsCount = clips.length;
  const selectedChildClips = selectedClipsIds.filter((id) => clips.some((clip) => clip.id === id));
  const team = useRowTeam(recordingId, clip.teamId);

  const selectionState: SelectionState =
    selectedChildClips.length === childClipsCount
      ? SelectionState.All
      : selectedChildClips.length > 0
        ? SelectionState.Some
        : SelectionState.None;

  const isSelected = selectionState === SelectionState.All || selectionState === SelectionState.Some;
  const borderStyle =
    selectionState === SelectionState.All ? 'solid' : selectionState !== SelectionState.Some ? undefined : 'dotted';

  const handleMouseEnter = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      if (!event.currentTarget) return;
      if (tacticalAnalysisMode === 'selection' && !clipWithSelection?.isSelectedForPlaying) return;
      setAnchorEl(event.currentTarget);
    },
    [clipWithSelection?.isSelectedForPlaying, tacticalAnalysisMode],
  );

  useEffect(() => {
    if (!anchorEl) return;
    const modalContent = anchorEl.querySelector(`[data-clip-id="modal-content-${clip.id}"]`);
    if (modalContent) {
      setHasScroll(modalContent.scrollHeight > modalContent.clientHeight);
    }
  }, [anchorEl, clip.id]);

  const handleMouseLeave = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      const relatedTarget = event.relatedTarget as HTMLDivElement;
      if (tacticalAnalysisMode === 'selection' && !clipWithSelection?.isSelectedForPlaying) return;
      if (relatedTarget && relatedTarget?.parentElement?.dataset.elementType === 'clip-actions-menu') return;
      return setAnchorEl(null);
    },
    [clipWithSelection?.isSelectedForPlaying, tacticalAnalysisMode],
  );

  const isHome = team === 'home';
  const isOpponent = team === 'opponent';
  const shouldReverseHomeTeam =
    isOpponent && (clip.type === 'tactic' || clip.type === 'parent-clip') && clip.action === TacticActionType.DEFENSIVE;
  const shouldReverseOpponentTeam =
    isHome && (clip.type === 'tactic' || clip.type === 'parent-clip') && clip.action === TacticActionType.DEFENSIVE;

  const clipWidth = `calc(var(${CSS_CLIP_ZOOM_WIDTH}) * ${clip.endTime - clip.startTime})`;
  const clipLeft = `calc(var(${CSS_CLIP_ZOOM_WIDTH}) * ${clip.startTime - parentClip.startTime})`;
  const isHomeTeam = shouldReverseHomeTeam || (isHome && !shouldReverseOpponentTeam);
  const isOpponentTeam = shouldReverseOpponentTeam || (isOpponent && !shouldReverseHomeTeam);

  return (
    <Box
      sx={{ height: '100%', position: 'absolute', width: clipWidth, left: clipLeft, top: 0 }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      data-clip-team={isHomeTeam ? 'home' : isOpponentTeam ? 'opponent' : 'none'}
    >
      <ParentSelectableClipContainer>
        {childClipsCount > 1 ? (
          <ParentSelectableClipCounter
            className={'parent-clip-counter'}
            isDisabled={tacticalAnalysisMode === 'selection' && !clipWithSelection?.isSelectedForPlaying}
          >
            <span>{childClipsCount}</span>
          </ParentSelectableClipCounter>
        ) : null}
        <SelectableClip
          recordingId={recordingId}
          clip={clip}
          parentClip={clip}
          key={clip.id}
          isSelected={isSelected}
          borderStyle={borderStyle}
        />
      </ParentSelectableClipContainer>

      <Popper
        keepMounted
        disablePortal
        id={clip.id}
        open={open}
        anchorEl={anchorEl}
        sx={{ zIndex: 1 }}
        transition
        placement={'bottom-start'}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} appear={true} timeout={200}>
            <Stack
              data-clip-id={`modal-content-${clip.id}`}
              spacing={0.25}
              sx={{
                zIndex: 100,
                position: 'relative',
                py: 0.25,
                pr: 1,
                backgroundColor: Colors.background,
                width: `calc(var(${CSS_CLIP_ZOOM_WIDTH}) * ${clip.endTime - clip.startTime} + ${
                  hasScroll ? '10px' : '0px'
                })`,
                height: '100%',
                maxHeight: '145px',
                overflowY: 'auto',
                overflowX: 'hidden',
                display: 'block',
              }}
              boxShadow={5}
              borderRadius={0.5}
            >
              {clips.map((item) => (
                <Box key={item.id} sx={{ height: 20, position: 'relative' }}>
                  <SelectableClip recordingId={recordingId} clip={item} parentClip={clip} key={item.id} />
                </Box>
              ))}
            </Stack>
          </Fade>
        )}
      </Popper>
    </Box>
  );
};
