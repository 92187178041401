import { SvgIcon, type SvgIconProps } from 'shared/components/icons/svg-icon/SvgIcon';

const IconChevronDown = (props: Omit<SvgIconProps, 'children'>): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path d='M7.41 8.295l4.59 4.58 4.59-4.58L18 9.705l-6 6-6-6 1.41-1.41z' />
    </SvgIcon>
  );
};

export default IconChevronDown;
