import { Button, Stack, styled } from '@mui/material';
import { Colors } from 'kognia-ui';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { routes } from 'kognia/router/routes';
import { FORM_IDS, NEW_GAME_FORM_STEPS, NEW_GAMES_STEPS } from 'pages/backoffice/pages/games/game-form/config';

type Props = {
  step: number;
};

const BackButton = styled(Button)({
  background: Colors.white,
});

export const GameFormNavigation = ({ step }: Props) => {
  const { id } = useParams();

  const navigate = useNavigate();
  const handleSubmitCurrentForm = () => {
    const form = document.getElementById(FORM_IDS[step]) as HTMLFormElement;
    form?.requestSubmit();
  };

  const handleBackButton = () => {
    if (step > 0) navigate(generatePath(NEW_GAME_FORM_STEPS[step - 1], { id: id ?? null }));
  };

  const finishAction = id ? 'Save' : 'Create';

  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      justifyContent={'center'}
      gap={2}
      sx={{
        padding: 2,
        height: '80px',
        background: Colors.athens,
        bottom: 0,
        left: 0,
        maxWidth: '100%',
      }}
    >
      {step === 3 ? (
        <>
          <Button
            size={'large'}
            variant='contained'
            color='info'
            onClick={() => navigate(generatePath(routes.BACKOFFICE_GAME_FORM_STEP_1, { id: id ?? null }))}
            sx={{ padding: 2, width: '200px' }}
          >
            Edit
          </Button>
          <Button
            size={'large'}
            variant='contained'
            color='info'
            onClick={() => navigate(routes.BACKOFFICE_GAMES)}
            sx={{ padding: 2, width: '200px' }}
          >
            Go to games list
          </Button>
        </>
      ) : (
        <>
          {step !== 0 ? (
            <BackButton
              size={'large'}
              variant='outlined'
              color='secondary'
              disabled={step === 0}
              onClick={handleBackButton}
              sx={{ padding: 2, width: '200px' }}
            >
              Back
            </BackButton>
          ) : null}

          <Button
            onClick={handleSubmitCurrentForm}
            type={'submit'}
            size={'large'}
            variant={'contained'}
            color={'primary'}
            sx={{ padding: 2, width: '200px' }}
          >
            {step === Object.keys(NEW_GAMES_STEPS).length - 1 ? finishAction : 'Next'}
          </Button>
        </>
      )}
    </Stack>
  );
};
