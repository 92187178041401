import { Box, Stack } from '@mui/material';
import { Suspense, useMemo, useRef } from 'react';

import { useEmbeddedDashboard } from 'features/dashboard/embedded-dashboard/hooks/useEmbeddedDashboard';
import { ErrorContainerFeature } from 'features/error-container/ErrorContainer.feature';
import Spinner from 'shared/components/spinner';

type Props = {
  dashboardId: string;
  playerId?: string;
  competitionId?: string;
  seasonYear?: number;
};

export const EmbeddedDashboardFeature = ({ dashboardId, playerId, competitionId, seasonYear }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const dashboardUrlParams = useMemo(
    () => ({
      player_uuid: playerId,
      competition_uuid: competitionId,
      season_year: seasonYear,
    }),
    [competitionId, playerId, seasonYear],
  );

  const { isError, isLoading } = useEmbeddedDashboard({
    dashboardId,
    containerRef,
    dashboardUrlParams,
  });

  return (
    <Stack
      sx={{
        height: '100%',
      }}
    >
      {isError && (
        <Suspense>
          <Box padding={(theme) => theme.spacing(4)} sx={{ height: '100%' }}>
            <ErrorContainerFeature />
          </Box>
        </Suspense>
      )}
      {isLoading && (
        <Stack
          alignContent={'center'}
          justifyContent={'center'}
          height={'100%'}
          padding={(theme) => theme.spacing(3, 0, 0, 3)}
        >
          <Spinner isFullPage size={'large'} />
        </Stack>
      )}

      <Box
        ref={containerRef}
        sx={{
          flex: 1,
          overflow: 'hidden',
          visibility: !isError && !isLoading ? 'visible' : 'hidden',
          iframe: { border: 0, width: '100%', height: '100%' },
        }}
      />
    </Stack>
  );
};
