import { Typography } from '@mui/material';
import { fontWeight } from 'kognia-ui';
import React, { useCallback, useState } from 'react';

import { useDeleteCoach } from 'pages/backoffice/api/coaches/use-delete-coach';
import { CoachListItem } from 'pages/backoffice/pages/coaches/components/coach-item/styled';
import { CoachModal } from 'pages/backoffice/pages/coaches/components/coach-modal';
import { CoachPhoto } from 'pages/backoffice/pages/coaches/components/coach-photo';
import { CoachPhotoSkeleton } from 'pages/backoffice/pages/coaches/components/coach-photo-skeleton';
import { Coach } from 'pages/backoffice/types/coaches';
import ConfirmDialog from 'shared/components/confirm-dialog';
import IconDelete from 'shared/components/icons/icon-delete';
import IconEdit from 'shared/components/icons/icon-edit';
import KebabMenu, { MenuListOption } from 'shared/components/kebab-menu';

interface Props {
  coach: Coach;
}

export const CoachItem = ({ coach }: Props) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState(false);
  const { deleteCoach } = useDeleteCoach();
  const handleEditModalOpen = () => setIsEditModalOpen(true);
  const handleEditModalClose = () => setIsEditModalOpen(false);

  const handleDeleteCoach = useCallback(() => {
    deleteCoach({
      coachId: coach.id,
    });
  }, [coach.id, deleteCoach]);

  const options: MenuListOption[] = [
    {
      displayText: 'Edit',
      icon: <IconEdit size='small' color='secondary' />,
      onClick: handleEditModalOpen,
    },
    {
      displayText: 'Delete',
      icon: <IconDelete size='small' color='secondary' />,
      onClick: () => setIsConfirmDeleteDialogOpen(true),
    },
  ];

  return (
    <>
      <CoachListItem key={coach.id}>
        {coach.photoUrl ? <CoachPhoto src={coach.photoUrl} /> : <CoachPhotoSkeleton small />}
        <Typography fontWeight={fontWeight['500']}>{coach.name}</Typography>
        <KebabMenu options={options} />
      </CoachListItem>
      {isEditModalOpen ? <CoachModal open={isEditModalOpen} onClose={handleEditModalClose} coach={coach} /> : null}

      <ConfirmDialog
        type={'warning'}
        title={`You sure you want delete "${coach.name}" coach?`}
        cancelLabel={'Cancel'}
        confirmLabel={'Delete'}
        onConfirm={handleDeleteCoach}
        isOpen={isConfirmDeleteDialogOpen}
        setIsOpen={setIsConfirmDeleteDialogOpen}
      />
    </>
  );
};
