import { Button, Tooltip } from '@mui/material';
import classNames from 'classnames';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import IconForward5 from 'shared/components/icons/icon-forward-5';
import { useVideoPlayerIsInStandBy } from 'shared/components/video-player/hooks';
import { useVideoPlayerActions, useVideoPlayerState } from 'shared/components/video-player/index';
import { PLAYER_STATES } from 'shared/components/video-player/state/states';
import styles from 'shared/components/video-player/video-player-component/components/Controls.module.scss';

const Forward5Button = () => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();
  const { state, readyState } = useVideoPlayerState();
  const isInStandBy = useVideoPlayerIsInStandBy();
  const actions = useVideoPlayerActions();

  const isDisabled = !readyState || state === PLAYER_STATES.ENDED || isInStandBy;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    buttonRef?.current?.blur();
    actions.skipForward5s();
  };

  return (
    <Tooltip enterDelay={2000} title={`${t('video-player:controls.skip-forward')}`}>
      <span>
        <Button
          ref={buttonRef}
          className={classNames({ [styles.disabled]: isDisabled })}
          disabled={isDisabled}
          size={'small'}
          onClick={handleClick}
        >
          <IconForward5 sx={{ color: 'common.white' }} />
        </Button>
      </span>
    </Tooltip>
  );
};

export default Forward5Button;
