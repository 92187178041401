import { Typography } from '@mui/material';
import { fontSizes, fontWeight } from 'kognia-ui';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TaggingEvent } from 'api/tagging-tool/types';
import {
  ImportUploaderContainer,
  ImportUploaderContent,
  ImportUploaderLabel,
} from 'pages/tagging-tool-tag-recording/components/import-uploader/index.styled';
import { TagEventClipActions } from 'pages/tagging-tool-tag-recording/components/tag-event-clip-actions';
import IconExport from 'shared/components/icons/icon-export';
import { fetchUploadImportedTaggingEvents } from 'tagging-tool/service/taggingEvent.service';

export type ImportUploaderProps = {
  recordingId: string;
  onCancel: () => void;
  onImport: (events: TaggingEvent[]) => void;
};

export const ImportUploader = (props: ImportUploaderProps) => {
  const { t } = useTranslation();
  const { recordingId } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [fileList, setFileList] = useState<FileList>();

  // handlers

  const handleChange = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
    if (ev.target.files === null) {
      return;
    }

    setFileList(ev.target.files);
  }, []);

  const handleConfirm = useCallback(() => {
    if (!fileList) {
      return;
    }

    setLoading(true);

    const data = new FormData();
    data.append('file', fileList[0]);

    fetchUploadImportedTaggingEvents({ recordingId, data }).then((res) => {
      setLoading(false);
      setFileList(undefined);

      if (res.error) {
        return;
      }

      props.onImport(res.data);
    });
  }, [fileList, props, recordingId]);

  const handleCancel = useCallback(() => {
    if (fileList !== undefined) {
      setFileList(undefined);
    } else {
      props.onCancel();
    }
  }, [fileList, props]);

  return (
    <ImportUploaderContainer>
      {fileList === undefined && (
        <ImportUploaderContent>
          <ImportUploaderLabel>
            <IconExport size='small' color='secondary' />
            <Typography fontSize={fontSizes.small} fontWeight={fontWeight['500']} textTransform='uppercase'>
              {t('tagging-tool:tagging-recording.select-XML-file')}
            </Typography>
            <input type='file' style={{ display: 'none' }} onChange={handleChange} accept='text/xml' />
          </ImportUploaderLabel>
        </ImportUploaderContent>
      )}
      {fileList !== undefined && <Typography fontSize={fontSizes.default}>{fileList[0].name}</Typography>}
      <TagEventClipActions
        loading={loading}
        disabled={!Boolean(fileList)}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
    </ImportUploaderContainer>
  );
};
