import { Container, Paper, Stack, Typography } from '@mui/material';
import { useState } from 'react';

import { BackofficeContentContainer } from 'pages/backoffice/components/backoffice-content-container';
import { ProcessGamePreset } from 'pages/backoffice/entities/process-game/types/processGame.types';
import { PresetSelector } from 'pages/backoffice/features/process-game/PresetSelector';
import { PRESETS_FORMS_DEFAULTS } from 'pages/backoffice/widgets/process-game/ProcessGameForm/config/ProcessGameForm.config';
import { ProcessGameFormWidget } from 'pages/backoffice/widgets/process-game/ProcessGameForm/ProcessGameForm.widget';

export const ProcessGamePage = () => {
  const [selectedPreset, setSelectedPreset] = useState<ProcessGamePreset | undefined>();
  const [renderCounter, setRenderCounter] = useState(0);

  const handlePresetChange = (preset: ProcessGamePreset) => {
    setSelectedPreset(preset);
    setRenderCounter((prevCounter) => prevCounter + 1);
  };

  return (
    <BackofficeContentContainer>
      <Stack gap={2}>
        <Typography variant='h4'>Process game</Typography>

        <Container maxWidth='md'>
          <Paper sx={{ padding: 4 }}>
            <Stack gap={2}>
              <PresetSelector onChange={handlePresetChange} selectedPreset={selectedPreset} />
              {selectedPreset && (
                <ProcessGameFormWidget
                  key={`${selectedPreset}-${renderCounter}`}
                  selectedPreset={selectedPreset}
                  defaultValues={PRESETS_FORMS_DEFAULTS[selectedPreset]}
                />
              )}
            </Stack>
          </Paper>
        </Container>
      </Stack>
    </BackofficeContentContainer>
  );
};
