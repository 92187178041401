import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { fontSizes } from 'kognia-ui';
import { ChangeEvent, useCallback, useState } from 'react';

import { useSeasons } from 'pages/backoffice/api/seasons/use-seasons';
import { BackofficeContentContainer } from 'pages/backoffice/components/backoffice-content-container';
import { ListSkeleton } from 'pages/backoffice/components/list-skeleton';
import { SeasonCompetitionModal } from 'pages/backoffice/pages/season-competitions/components/season-competition-modal';
import { SeasonCompetitionsList } from 'pages/backoffice/pages/season-competitions/components/season-competitions-list';
import { SeasonCompetitionsListHeaderGrid } from 'pages/backoffice/pages/season-competitions/styled';
import IconPlus from 'shared/components/icons/icon-plus';
import { ListHeader } from 'shared/components/list/list-header';
import NotFound from 'shared/components/not-found';
import Pagination from 'shared/components/pagination';
import { useDebounce } from 'shared/hooks/use-debounce/useDebounce';

export const SeasonCompetitions = () => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const { items, page, fetchNextPage, isFetching, setFilters } = useSeasons();

  const handleCreateModalOpen = useCallback(() => setIsCreateModalOpen(true), []);
  const handleCreateModalClose = useCallback(() => setIsCreateModalOpen(false), []);

  const handleSearchChange = useDebounce((event: ChangeEvent<HTMLInputElement>) => {
    setFilters({ name: event.target.value });
  });

  return (
    <BackofficeContentContainer>
      <Stack direction='row' alignItems='center' justifyContent='space-between' marginBottom={2}>
        <Typography variant='h4'>Season competitions</Typography>
        <Button
          variant='contained'
          size='large'
          startIcon={<IconPlus size='small' sx={{ color: 'common.white' }} />}
          onClick={handleCreateModalOpen}
        >
          New season
        </Button>
      </Stack>

      <Stack marginBottom={2} justifyContent='flex-end' direction='row'>
        <TextField size='small' placeholder='Search seasons' onChange={handleSearchChange} />
      </Stack>

      <ListHeader>
        <SeasonCompetitionsListHeaderGrid>
          <Typography variant='body1' fontSize={fontSizes.default} fontWeight={'fontWeightMedium'}>
            Year
          </Typography>
          <Typography variant='body1' fontSize={fontSizes.default} fontWeight={'fontWeightMedium'}>
            Competition
          </Typography>
          <Typography variant='body1' fontSize={fontSizes.default} fontWeight={'fontWeightMedium'}>
            Stages
          </Typography>
          <div>&nbsp;</div>
        </SeasonCompetitionsListHeaderGrid>
      </ListHeader>
      {!isFetching && items.length === 0 ? (
        <Box marginTop={4}>
          <NotFound header={'No results'} />
        </Box>
      ) : null}
      {isFetching ? <ListSkeleton /> : <SeasonCompetitionsList seasons={items} />}
      {page ? (
        <Pagination
          total={page.totalElements}
          displayed={items.length}
          onShowMore={fetchNextPage}
          getStatsText={(displayed, total) => `${displayed} of ${total} seasons`}
        />
      ) : null}
      <SeasonCompetitionModal open={isCreateModalOpen} onClose={handleCreateModalClose} />
    </BackofficeContentContainer>
  );
};
