import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useRecordings } from 'api/recording/useFetchRecordings';
import { RecordingListPageSkeleton } from 'pages/recordings-list/components/recording-list-page-skeleton';
import { RecordingsListPage } from 'pages/recordings-list/components/recordings-list-page';
import { RecordingFilters } from 'pages/recordings-list/types';
import { useBranding } from 'shared/hooks/use-branding/useBranding';

export interface TabType {
  label: string;
  value: string[];
  type: RecordingFilters;
}

const COMPETITION_PRIORITY: Record<string, number> = {
  'LaLiga 2024-25': 0,
  'LaLiga 2023-24': 1,
  'UEFA Champions League 2023-24': 2,
  'UEFA Youth League 23-24': 3,
  'LaLiga 2022-23': 4,
  'LaLiga 2021-22': 5,
  'LaLiga 2 2022-23': 6,
  'LaLiga 2 2021-22': 7,
  'Serie A 2023-24': 8,
  'Allsvenskan 2023': 9,
  'Saudi Pro League 2023-24': 10,
  'Saudi Arabian Super Cup 2023-24': 11,
  'Poland Division 1 2024-25': 12,
  'Poland Division 1 2023-24': 13,
  'Liga MX Apertura 2024': 14,
  'Liga MX Apertura 2023': 15,
  'Liga MX': 16,
  'Division de Honor 2022-23': 17,
  'Division de Honor Juvenil': 18,
  'Primera RFEF Grupo 2': 19,
  'Primera RFEF Grupo 1 2023/24': 20,
  'Liga F 2023-24': 21,
  'Liga F': 22,
};

const sortTabsTypesByPriority = (a: TabType, b: TabType) => {
  const first = a.label in COMPETITION_PRIORITY ? COMPETITION_PRIORITY[a.label] : Number.MAX_SAFE_INTEGER;
  const second = b.label in COMPETITION_PRIORITY ? COMPETITION_PRIORITY[b.label] : Number.MAX_SAFE_INTEGER;

  let result = 0;
  if (first < second) result = -1;
  else if (first > second) result = 1;
  return result;
};

const RecordingsListPageContainer = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { filters, isSuccess, isPending } = useRecordings({ extraKey: 'only-filters' });
  const branding = useBranding();

  useEffect(() => {
    document.title = t('common:metas.title.recordings', { clientDisplayName: branding.displayName });
  }, [branding.displayName, t]);

  const competitionsTabs: TabType[] = useMemo(
    () =>
      (filters?.competition?.options &&
        Object.keys(filters?.competition?.options)
          .filter((item) => !isEmpty(item))
          .map((item) => ({
            label: item,
            value: [item],
            type: RecordingFilters.COMPETITION,
          }))
          .sort(sortTabsTypesByPriority)) ||
      ([] as TabType[]),
    [filters],
  );

  if (isPending) {
    return <RecordingListPageSkeleton />;
  }

  if (isSuccess) {
    return <RecordingsListPage competitionsTabs={competitionsTabs} defaultFilters={filters} />;
  }

  return null;
};

export default React.memo(RecordingsListPageContainer);
