import { SvgIcon, type SvgIconProps } from 'shared/components/icons/svg-icon/SvgIcon';

const IconPlus = (props: Omit<SvgIconProps, 'children'>): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path d='M16.333 12.333h-4v4H11v-4H7V11h4V7h1.333v4h4v1.333z' />
    </SvgIcon>
  );
};

export default IconPlus;
