import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { useState } from 'react';
import { FieldErrors, UseFormRegister } from 'react-hook-form';

import { ProcessGameFormSchema } from 'pages/backoffice/widgets/process-game/ProcessGameForm/types/processGameForm.types';
import { FilesSecondSpectrumFieldsNames } from 'pages/backoffice/widgets/process-game/ProcessGameForm/types/processGameFormSubforms.types';
import { FormInputField } from 'shared/components/form/form-input-field';

interface Props {
  register: UseFormRegister<ProcessGameFormSchema>;
  errors: FieldErrors<ProcessGameFormSchema>;
}

export function SecondSpectrumForm({ errors, register }: Props) {
  const [set, setSet] = useState(1);

  const textFieldsListSetOne = [
    {
      id: FilesSecondSpectrumFieldsNames.metadataXml,
      label: 'Metadata (.xml)',
    },
    {
      id: FilesSecondSpectrumFieldsNames.dataXml,
      label: 'Data (.xml)',
    },
    {
      id: FilesSecondSpectrumFieldsNames.physicalSummaryCsv,
      label: 'Physical Summary (.csv)',
    },
  ];

  const textFieldsListSetTwo = [
    {
      id: FilesSecondSpectrumFieldsNames.dataJsonl,
      label: 'Data (.jsonl)',
    },
    {
      id: FilesSecondSpectrumFieldsNames.metadataJson,
      label: 'Metadata (.json)',
    },
  ];

  const textFieldsSetOne = textFieldsListSetOne.map((textField) => {
    return (
      <FormInputField
        key={textField.id}
        id={textField.id}
        label={textField.label}
        error={!!errors[textField.id]}
        fullWidth
        helperText={errors[textField.id] && <>{errors[textField.id]?.message}</>}
        {...register(textField.id)}
      />
    );
  });

  const textFieldsSetTwo = textFieldsListSetTwo.map((textField) => {
    return (
      <FormInputField
        key={textField.id}
        id={textField.id}
        label={textField.label}
        error={!!errors[textField.id]}
        fullWidth
        helperText={errors[textField.id] && <>{errors[textField.id]?.message}</>}
        {...register(textField.id)}
      />
    );
  });

  return (
    <div>
      <FormControl>
        <FormLabel id='second-spectrum-set'>Files set</FormLabel>
        <RadioGroup
          aria-labelledby='second-spectrum-set'
          name='second-spectrum-set-radio-group'
          value={set}
          onChange={(event) => setSet(Number(event.target.value))}
        >
          <FormControlLabel value={1} control={<Radio />} label='Set one' />
          <FormControlLabel value={2} control={<Radio />} label='Set two' />
        </RadioGroup>
      </FormControl>

      {set === 1 && textFieldsSetOne}
      {set === 2 && textFieldsSetTwo}
    </div>
  );
}
