import { TypeOf } from 'zod';

import { stageFormSchema } from 'pages/backoffice/pages/stages/components/stage-form/form';
import { Season } from 'pages/backoffice/types/seasons';

export enum StageFormFieldsNames {
  id = 'id',
  name = 'name',
  orderIndex = 'orderIndex',
  season = 'season',
  parentStageId = 'parentStageId',
}

export interface StageForm {
  [StageFormFieldsNames.id]?: string;
  [StageFormFieldsNames.name]: string;
  [StageFormFieldsNames.orderIndex]: number;
  [StageFormFieldsNames.season]?: Season;
  [StageFormFieldsNames.parentStageId]?: string;
}

export type StageFormSchema = TypeOf<typeof stageFormSchema>;
