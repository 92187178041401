import { TypeOf } from 'zod';

import { competitionFormSchema } from 'pages/backoffice/pages/competitions/components/competition-form/form';

export enum CompetitionFormFieldsNames {
  id = 'id',
  name = 'name',
  country = 'country',
}

export interface CompetitionForm {
  [CompetitionFormFieldsNames.id]?: string;
  [CompetitionFormFieldsNames.name]: string;
  [CompetitionFormFieldsNames.country]: string;
}

export type CompetitionFormSchema = TypeOf<typeof competitionFormSchema>;
