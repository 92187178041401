import { Grid } from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { PlaylistSortOptions } from 'api/playlist/useFetchPlaylists/types';
import { PlaylistCard } from 'pages/playlists/components/playlist-card';
import { CreatePlaylist } from 'pages/playlists/components/playlists-list/components/create-playlist';
import { Filters } from 'pages/playlists/components/playlists-list/components/filters';
import { usePlaylistsWithQueryStringState } from 'pages/playlists/components/playlists-list/hooks/usePlaylistsWithQueryStringState';
import styles from 'pages/playlists/components/playlists-list/PlaylistList.module.scss';
import { PlaylistsListItemDetails } from 'pages/playlists/components/playlists-list-item-details';
import { PlaylistListSkeleton } from 'pages/playlists/components/playlists-skeleton/playlist-list-skeleton';
import EmptyPage from 'shared/components/empty-page';
import { List } from 'shared/components/list';
import { ListContainer } from 'shared/components/list/list-container';
import { ListHeader } from 'shared/components/list/list-header';
import { ListHeaderOption } from 'shared/components/list/list-header-option';
import { ListTitle } from 'shared/components/list/list-title';
import { ListTitleActions } from 'shared/components/list/list-title-actions';
import { ListTitleContainer } from 'shared/components/list/list-title-container';
import Pagination from 'shared/components/pagination';
import { SortDirection } from 'shared/types/filters/types';

export const PlaylistsList = () => {
  const { t } = useTranslation();
  const { data, isFetchingNextPage, isFetching, isPending, appliedFilters, filterActions } =
    usePlaylistsWithQueryStringState();

  const handleSortChange = useCallback(
    (sort: PlaylistSortOptions) => {
      if (appliedFilters.sort === sort) {
        return filterActions.setSort(
          sort,
          appliedFilters.sortDirection === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC,
        );
      }

      return filterActions.setSort(sort, SortDirection.DESC);
    },
    [filterActions, appliedFilters.sort, appliedFilters.sortDirection],
  );

  return (
    <ListContainer>
      <ListTitleActions>
        <CreatePlaylist />
      </ListTitleActions>
      <ListTitleContainer>
        <ListTitle dataTestId='page-title'>Playlists</ListTitle>
        <Grid container item xs={'auto'} spacing={1} alignItems={'center'}>
          <Filters filtersList={data.filters} filterActions={filterActions} appliedFilters={appliedFilters} />
        </Grid>
      </ListTitleContainer>

      {data.playlists.length || isPending ? (
        <>
          {isPending ? (
            <PlaylistListSkeleton />
          ) : (
            <>
              <ListHeader className={styles.playlistHeader}>
                <ListHeaderOption
                  title={t('playlists:headers.title')}
                  showOrder
                  order={appliedFilters.sort === 'name' ? appliedFilters.sortDirection : undefined}
                  onClick={() => handleSortChange(PlaylistSortOptions.NAME)}
                />

                <PlaylistsListItemDetails>
                  <div />
                  <ListHeaderOption title={t('playlists:headers.n-clips')} />
                  <ListHeaderOption title={t('playlists:headers.duration')} />
                  <ListHeaderOption
                    title={t('playlists:headers.last-modified')}
                    showOrder
                    order={
                      appliedFilters.sort === PlaylistSortOptions.UPDATED_AT ? appliedFilters.sortDirection : undefined
                    }
                    onClick={() => handleSortChange(PlaylistSortOptions.UPDATED_AT)}
                  />
                  <div />
                </PlaylistsListItemDetails>
              </ListHeader>
              <List>
                {data.playlists.map((playlist) => (
                  <PlaylistCard key={playlist.id} playlist={playlist} />
                ))}
              </List>
            </>
          )}
          <Pagination
            total={data.totalElements}
            displayed={data.playlists.length}
            getStatsText={(displayed, total) => t('playlists:pagination.total', { displayed, total, count: total })}
            onShowMore={data.fetchNextPage}
            loading={isFetchingNextPage || Boolean(data.playlists.length && isFetching)}
          />
        </>
      ) : (
        <EmptyPage header={t('playlists:not-found.header')} description={t('playlists:not-found.description')} />
      )}
    </ListContainer>
  );
};
