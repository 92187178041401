import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';

import styles from 'pages/tactical-analysis/components/tactical-analysis/add-to-playlist-menu/unsaved-changes-dialog/UnsavedChangesDialog.module.scss';
import { UnsavedChangesDialogIconContainer } from 'pages/tactical-analysis/components/tactical-analysis/add-to-playlist-menu/unsaved-changes-dialog/UnsavedChangesDialog.styled';
import IconClose from 'shared/components/icons/icon-close';

interface Props {
  isOpen: boolean;
  onCancel: () => void;
  onSave: () => void;
  onClose: () => void;
}

export const UnsavedChangesDialog = ({ isOpen, onCancel, onSave, onClose }: Props) => {
  const { t } = useTranslation();

  const handleCancel = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    onCancel();
  };

  const handleSave = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    onSave();
    onCancel();
  };

  return (
    <Dialog
      keepMounted={false}
      className={styles.dialog}
      open={isOpen}
      onClick={(event) => event.stopPropagation()}
      aria-labelledby='unsaved-changes-dialog-title'
      aria-describedby='unsaved-changes-description'
      onClose={onClose}
    >
      <div className={styles.dialog}>
        <IconButton size='small' onClick={onClose} className={styles.closeButton}>
          <IconClose isButton color='secondary' size='small' />
        </IconButton>
        <DialogTitle className={styles.title}>
          <UnsavedChangesDialogIconContainer>
            <IconClose color='error' size='small' />
          </UnsavedChangesDialogIconContainer>
          {t('timeline:unsaved-changes-dialog.title')}
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div className={styles.text}>{t('timeline:unsaved-changes-dialog.text-line-1')}</div>
          <div className={styles.text}>{t('timeline:unsaved-changes-dialog.text-line-2')}</div>
        </DialogContent>
        <DialogActions className={styles.actions}>
          <Button variant='outlined' color='secondary' onClick={handleCancel}>
            {t('timeline:unsaved-changes-dialog.actions.cancel')}
          </Button>
          <Button variant='contained' onClick={handleSave}>
            {t('timeline:unsaved-changes-dialog.actions.save')}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};
