import { Box, Chip, Button, styled } from '@mui/material';
import { fontSizes } from 'kognia-ui';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import FiltersBarSummary from 'pages/tactical-analysis/components/tactical-analysis/filters/components/filters-bar-summary';
import FiltersModal from 'pages/tactical-analysis/components/tactical-analysis/filters/components/filters-modal';
import { useSelectionPlaying } from 'pages/tactical-analysis/hooks/use-selection-playing';
import {
  useSetTacticalAnalysisAppliedFilters,
  useTacticalAnalysisAppliedFilters,
} from 'pages/tactical-analysis/hooks/use-tactical-analysis-applied-filters';
import {
  useSetTacticalAnalysisFiltersResults,
  useTacticalAnalysisClearFilters,
} from 'pages/tactical-analysis/hooks/use-tactical-analysis-filters-results';
import { useTacticalAnalysisMode } from 'pages/tactical-analysis/hooks/use-tactical-analysis-mode';
import { TacticalAnalysisPlayingMode } from 'pages/tactical-analysis/types/tactical-analysis-playing-mode';
import IconFilter from 'shared/components/icons/icon-filter';
import { useVideoPlayerActions } from 'shared/components/video-player/hooks';
import { ActionTypes } from 'shared/streams/actionTypes';
import { publishEvent } from 'shared/streams/eventEmitter';
import { Episode } from 'shared/types';
import { MetricsNames } from 'shared/types/metrics';
import { RecordingsFilters } from 'shared/types/recording/types';
import { MatchTeams } from 'shared/types/teams/types';

export const defaultFilters = {
  recordingIds: [],
};

interface Props {
  episodeCount: number;
  episodes: Episode[];
  recordingId: string;
  teams: MatchTeams;
  hasTeamFocus?: boolean;
}

const FiltersContainer = styled(Box)(() => ({
  fontSize: fontSizes.small,
  marginRight: '16px',
  paddingRight: '16px',
  position: 'relative',
  display: 'flex',
  flexWrap: 'nowrap',
  alignItems: 'center',
}));

const Filters = ({ episodes, teams, recordingId, episodeCount }: Props) => {
  const { t } = useTranslation();
  const actions = useVideoPlayerActions();
  const [summaryAnchor, setFiltersSummaryAnchor] = useState<HTMLElement | null>(null);
  const timeoutIdRef = useRef<number>();
  const [areFiltersOpen, setAreFiltersOpen] = useState(false);
  const { appliedFilters } = useTacticalAnalysisAppliedFilters(recordingId);
  const setAppliedFilters = useSetTacticalAnalysisAppliedFilters(recordingId);
  const clearFilters = useTacticalAnalysisClearFilters(recordingId);
  const setTacticalAnalysisFiltersResults = useSetTacticalAnalysisFiltersResults(recordingId);

  const tacticalAnalysisMode = useTacticalAnalysisMode(recordingId);
  const { playAll } = useSelectionPlaying(recordingId);

  const toggleOpenFilters = useCallback(() => {
    if (!areFiltersOpen) {
      publishEvent({
        type: ActionTypes.OPEN_TACTICAL_ANALYSIS_FILTER,
        payload: { name: MetricsNames.TACTICAL_ANALYSIS_OPEN_FILTER },
      });
      actions.handleStandBy();
    } else {
      actions.resumeStandBy();
    }
    setAreFiltersOpen(!areFiltersOpen);
  }, [areFiltersOpen, actions]);

  const handleCloseFilters = useCallback(() => {
    actions.resumeStandBy();
    setAreFiltersOpen(false);
  }, [actions, setAreFiltersOpen]);

  const handleClearFilters = useCallback(() => {
    if (tacticalAnalysisMode === TacticalAnalysisPlayingMode.selection) playAll();
    actions.resumeStandBy();
    actions.pause();
    clearFilters();
  }, [actions, clearFilters, playAll, tacticalAnalysisMode]);

  const handleApplyFilters = useCallback(
    (appliedFilters: RecordingsFilters, episodes: Episode[]) => {
      if (tacticalAnalysisMode === TacticalAnalysisPlayingMode.selection) playAll();

      // Order is important as applied filters need to be set before the results
      const filtersWithRecordingId = { ...appliedFilters, recordingIds: [recordingId] };

      setAppliedFilters(filtersWithRecordingId);
      setTacticalAnalysisFiltersResults(episodes, filtersWithRecordingId);
      handleCloseFilters();
    },
    [
      tacticalAnalysisMode,
      playAll,
      recordingId,
      setAppliedFilters,
      setTacticalAnalysisFiltersResults,
      handleCloseFilters,
    ],
  );

  const showFiltersApplied = Boolean(
    appliedFilters.eventsEnding || appliedFilters.eventsStarting || appliedFilters.scenariosOrTacticsInside,
  );

  const handleOpenSummary = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    clearTimeout(timeoutIdRef.current);
    setFiltersSummaryAnchor(event.currentTarget);
  }, []);

  const handlePopupMouseEnter = useCallback(() => {
    clearTimeout(timeoutIdRef.current);
  }, []);

  const handleCloseSummary = useCallback(() => {
    timeoutIdRef.current = setTimeout(() => {
      setFiltersSummaryAnchor(null);
    }, 50) as unknown as number;
  }, []);

  return (
    <FiltersContainer>
      <Button startIcon={<IconFilter size='small' />} onClick={toggleOpenFilters} color='inherit'>
        {t('timeline:filter-by')}
      </Button>

      {showFiltersApplied && (
        <>
          <Chip
            label={t('recordings:filters.applied')}
            color='primary'
            variant='outlined'
            onDelete={handleClearFilters}
            onMouseEnter={handleOpenSummary}
            onMouseLeave={handleCloseSummary}
            sx={{ marginLeft: ({ spacing }) => spacing(1) }}
          />
          <FiltersBarSummary
            hasTeamFocus={true}
            recordingId={recordingId}
            filters={appliedFilters}
            teams={teams}
            onMouseEnter={handlePopupMouseEnter}
            onMouseLeave={handleCloseSummary}
            summaryAnchor={summaryAnchor}
          />
        </>
      )}
      {areFiltersOpen ? (
        <FiltersModal
          appliedFilters={appliedFilters}
          episodeCount={episodeCount}
          episodes={episodes}
          isOpen={areFiltersOpen}
          onApply={handleApplyFilters}
          onClose={handleCloseFilters}
          clearFilters={handleClearFilters}
          recordingId={recordingId}
          teams={teams}
        />
      ) : null}
    </FiltersContainer>
  );
};

export default Filters;
