import { useTranslation } from 'react-i18next';

import { queryClient } from 'api/config';
import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { generateFetchPlaylistQueryRef } from 'api/playlist/usePlaylist';
import { deletePlaylistItemsUrl } from 'api/routes';
import { HTTPMethod } from 'api/types';

export const useDeletePlaylistItems = (playlistId: string) => {
  const { t } = useTranslation();
  const fetchQueryRef = generateFetchPlaylistQueryRef(playlistId);
  const { mutate, isPending, isError } = useMutationRequest({
    type: HTTPMethod.DELETE,
    errorMessage: t('api:use-delete-playlist-items.error'),
    successMessage: t('api:use-delete-playlist-items.success'),
    onSuccess: async () => {
      if (fetchQueryRef) await queryClient.invalidateQueries({ queryKey: [fetchQueryRef] });
    },
  });

  const deletePlaylistItems = (playlistItemsIds: string[], onSuccess?: (data: unknown) => void) => {
    mutate({ url: deletePlaylistItemsUrl(playlistId), data: { data: playlistItemsIds } }, { onSuccess });
  };

  return { deletePlaylistItems, isPending, isError };
};
