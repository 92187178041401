import { Button } from '@mui/material';
import classNames from 'classnames';
import React, { useRef } from 'react';

import IconBackward from 'shared/components/icons/icon-backward';
import {
  useCurrentPlaylistItemVideoSources,
  useCurrentVideoSourceIndex,
  useVideoPlayerIsInStandBy,
} from 'shared/components/video-player/hooks';
import { useVideoPlayerActions, useVideoPlayerState } from 'shared/components/video-player/index';
import { PLAYER_STATES } from 'shared/components/video-player/state/states';
import styles from 'shared/components/video-player/video-player-component/components/Controls.module.scss';

const PreviousVideoSourceButton = () => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { state, readyState } = useVideoPlayerState();
  const isInStandBy = useVideoPlayerIsInStandBy();
  const actions = useVideoPlayerActions();
  const videoSources = useCurrentPlaylistItemVideoSources();
  const videoSourceIndex = useCurrentVideoSourceIndex();

  const isDisabled = !readyState || state === PLAYER_STATES.ENDED || isInStandBy || videoSourceIndex === 0;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    buttonRef?.current?.blur();
    actions.previousVideoSource();
  };

  if (videoSources.length <= 1) return null;

  return (
    <span>
      <Button
        ref={buttonRef}
        className={classNames({ [styles.disabled]: isDisabled })}
        disabled={isDisabled}
        size={'small'}
        onClick={handleClick}
      >
        <IconBackward size='medium' sx={{ color: 'common.white' }} />
      </Button>
    </span>
  );
};

export default PreviousVideoSourceButton;
