import { Grid } from '@mui/material';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { TaggingEvent } from 'api/tagging-tool/types';
import { ImportUploader } from 'pages/tagging-tool-tag-recording/components/import-uploader';
import ActionIcon from 'shared/components/action-icon';
import IconExport from 'shared/components/icons/icon-export';
import IconFolder from 'shared/components/icons/icon-folder';
import IconSync from 'shared/components/icons/icon-sync';
import IconTag from 'shared/components/icons/icon-tag';
import { MenuList } from 'shared/components/menu-list';
import { fetchExportTaggingEvents } from 'tagging-tool/service/taggingEvent.service';
import { makeRecordingAlignmentRoute } from 'tagging-tool/utility/navigation';

interface Props {
  handleImportEvents: (events: Array<TaggingEvent>) => void;
  importing: boolean;
  isLive: boolean;
  isMultiselectModeActive: boolean;
  orderedTaggingEvents: TaggingEvent[];
  recordingId: string;
  setImporting: (value: boolean) => void;
  setIsMultiselectModeActive: (value: boolean) => void;
  setShowsKeypad: (value: boolean) => void;
  showsKeypad: boolean;
  videoSrc?: string;
}

export const TagRecordingActions = ({
  handleImportEvents,
  importing,
  isLive,
  isMultiselectModeActive,
  recordingId,
  setImporting,
  setIsMultiselectModeActive,
  setShowsKeypad,
  showsKeypad,
  videoSrc,
}: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showsIEMenu, setShowsIEMenu] = useState<boolean>(false);
  const actionIconRef = useRef<HTMLDivElement | null>(null);

  const handleShowIEMenu = useCallback(() => setShowsIEMenu(true), []);
  const handleHideIEMenu = useCallback(() => setShowsIEMenu(false), []);
  const handleCancelImport = useCallback(() => setImporting(false), [setImporting]);
  const handleShowImportForm = useCallback(() => setImporting(true), [setImporting]);
  const handleImportMenuOption = useCallback(
    (events: Array<TaggingEvent>) => {
      setImporting(false);
      handleImportEvents(events);
    },
    [setImporting, handleImportEvents],
  );

  const handleExportMenuOption = useCallback(() => {
    fetchExportTaggingEvents({
      recordingId,
      isLive,
    }).then((res) => {
      if (res.error) {
        return;
      }

      const blob = new Blob([res.data], {
        type: 'text/xml',
      });
      const blobURL = window.URL.createObjectURL(blob);

      const downloadLink = document.createElement('a');
      downloadLink.target = '_blank';
      downloadLink.href = blobURL;
      downloadLink.download = `session-${recordingId}-tags.xml`;
      downloadLink.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        }),
      );
      setTimeout(function () {
        window.URL.revokeObjectURL(blobURL);
      }, 200);
    });
  }, [isLive, recordingId]);

  const menuOptions = useMemo(() => {
    return [
      {
        displayText: t('tagging-tool:tagging-recording.import'),
        onClick: handleShowImportForm,
      },
      {
        displayText: t('tagging-tool:tagging-recording.export'),
        onClick: handleExportMenuOption,
      },
    ];
  }, [handleShowImportForm, handleExportMenuOption, t]);

  return (
    <Grid container spacing={1} justifyContent={'flex-end'} alignItems={'center'}>
      {!importing && (
        <>
          {videoSrc && (
            <>
              <Grid item>
                <ActionIcon
                  active={isMultiselectModeActive}
                  text={t('tagging-tool:tagging-recording.action-add-to-playlist')}
                  onClick={() => {
                    setIsMultiselectModeActive(!isMultiselectModeActive);
                  }}
                >
                  <IconFolder color={isMultiselectModeActive ? 'primary' : undefined} />
                </ActionIcon>
              </Grid>
              <Grid item>
                <ActionIcon
                  text={t('tagging-tool:tagging-recording.action-edit-alignment')}
                  onClick={() => {
                    navigate(
                      makeRecordingAlignmentRoute({
                        recordingId,
                      }),
                    );
                  }}
                >
                  <IconSync />
                </ActionIcon>
              </Grid>
            </>
          )}
          <Grid item ref={actionIconRef}>
            <ActionIcon onClick={handleShowIEMenu} text={t('tagging-tool:tagging-recording.action-export-import')}>
              <IconExport />
            </ActionIcon>
            <MenuList
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              anchorEl={actionIconRef.current}
              isOpen={showsIEMenu}
              onClose={handleHideIEMenu}
              options={menuOptions}
            />
          </Grid>
          <Grid item>
            <ActionIcon
              active={showsKeypad}
              text={t('tagging-tool:tagging-recording.action-add-tags')}
              onClick={() => {
                setShowsKeypad(!showsKeypad);
              }}
            >
              <IconTag sx={{ color: showsKeypad ? 'primary.main' : 'text.primary' }} />
            </ActionIcon>
          </Grid>
        </>
      )}
      {importing && (
        <Grid item>
          <ImportUploader recordingId={recordingId} onCancel={handleCancelImport} onImport={handleImportMenuOption} />
        </Grid>
      )}
    </Grid>
  );
};
