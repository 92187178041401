import { Box, ClickAwayListener, ListItem, Stack } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { RecordingCompetitionsFilters } from 'pages/backoffice/api/season-clients/use-recording-competitions/types';
import { useVenues } from 'pages/backoffice/api/venues/use-venues';
import { Venue } from 'pages/backoffice/api/venues/use-venues/types';
import { RenderSelectOption } from 'pages/backoffice/components/select-components/render-option';
import { Autocomplete } from 'shared/components/autocomplete';
import { AutocompleteDropdownButton } from 'shared/components/autocomplete-multi-select/components/autocomplete-dropdown-button';
import {
  AutocompletePopper,
  AutocompletePopperContent,
} from 'shared/components/autocomplete-multi-select/components/autocomplete-popper';
import { AutocompletePopperWrapper } from 'shared/components/autocomplete-multi-select/components/autocomplete-popper-wrapper';
import { AutocompleteResultsWrapper } from 'shared/components/autocomplete-multi-select/components/autocomplete-results-wrapper';

const INITIAL_RECORDING_VENUES_FILTERS: RecordingCompetitionsFilters = {
  name: '',
};

interface Props {
  setVenueOnChange: (venue: Venue | undefined) => void;
  venue?: Venue;
  disabled?: boolean;
}

const AUTOCOMPLETE_WIDTH = 400;

export const SelectVenue = ({ setVenueOnChange, venue }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [autocompleteValue, setAutocompleteValue] = useState<Venue | undefined>(undefined);
  const { items: results, isLoading, setFilters, fetchNextPage } = useVenues();
  const { t } = useTranslation();

  const isOpen = Boolean(anchorEl);

  const open = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAutocompleteValue(venue || undefined);
      setAnchorEl(event.currentTarget);
    },
    [venue],
  );

  const close = useCallback(() => {
    setFilters(INITIAL_RECORDING_VENUES_FILTERS);
    setAnchorEl(null);
  }, [setFilters]);

  const handleDropdownClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      isOpen ? close() : open(event);
    },
    [isOpen, close, open],
  );

  const handleSelectVenue = useCallback(() => {
    setAutocompleteValue((autocompleteValue) => {
      setVenueOnChange(autocompleteValue);
      return autocompleteValue;
    });
    close();
  }, [setAutocompleteValue, setVenueOnChange, close]);

  const handleSetName = useCallback(
    (name: string) => {
      setFilters({ name: name });
    },
    [setFilters],
  );

  const renderOption = useCallback((props: React.ComponentProps<typeof ListItem>, option: Venue) => {
    return <RenderSelectOption {...props} key={option.id} name={option.name} />;
  }, []);

  const handleApply = useCallback(() => {
    setAutocompleteValue((autocompleteValue) => {
      return autocompleteValue;
    });
    close();
  }, [setAutocompleteValue, close]);

  const handleReset = useCallback(() => {
    setAutocompleteValue(undefined);
  }, [setAutocompleteValue]);

  const paperComponent: React.JSXElementConstructor<React.HTMLAttributes<HTMLElement>> = useCallback(
    (props) => (
      <AutocompleteResultsWrapper {...props} onReset={handleReset} onApply={handleApply}>
        {props.children}
      </AutocompleteResultsWrapper>
    ),
    [handleReset, handleApply],
  );

  const handleUpdateValue = useCallback(
    (item: Venue | null) => {
      if (item === null) return;
      setAutocompleteValue(item.id ? item : undefined);
      setVenueOnChange(item.id ? item : undefined);
      close();
    },
    [setAutocompleteValue, setVenueOnChange, close],
  );

  return (
    <Stack direction={'column'} spacing={1}>
      <AutocompleteDropdownButton
        fullWidth
        isOpen={isOpen}
        onClick={handleDropdownClick}
        isSelected={Boolean(venue)}
        label={venue ? venue.name : 'Select venue'}
      />
      <Box>
        {anchorEl && (
          <ClickAwayListener onClickAway={handleSelectVenue}>
            <AutocompletePopper anchorEl={anchorEl} open={isOpen} placement='bottom-start'>
              <AutocompletePopperContent elevation={8}>
                <Autocomplete
                  autoFocus
                  PaperComponent={paperComponent}
                  PopperComponent={AutocompletePopperWrapper}
                  fetchNextPage={fetchNextPage}
                  getItemLabel={(item) => item.name}
                  inputWidth={AUTOCOMPLETE_WIDTH}
                  isLoading={isLoading}
                  isOptionEqualToValue={(option: Venue, value: Venue) => option.id === value?.id}
                  listWidth={AUTOCOMPLETE_WIDTH}
                  multiple={false}
                  onChange={handleSetName}
                  open
                  options={[
                    {
                      id: '',
                      name: 'No venue',
                      city: '',
                      countryCode: '',
                    },
                    ...results,
                  ]}
                  renderOption={renderOption}
                  placeholder={t('common:actions.search')}
                  resultsHeight={260}
                  updateValue={handleUpdateValue}
                  value={autocompleteValue}
                />
              </AutocompletePopperContent>
            </AutocompletePopper>
          </ClickAwayListener>
        )}
      </Box>
    </Stack>
  );
};
