import { useRef } from 'react';

import { KogniaUser } from 'api/backoffice/user/use-kognia-users/types';
import { RemoveUsersFromEnvironmentsFormWithConfirm } from 'pages/backoffice/features/users/remove-users-from-environments-form-with-confirm/RemoveUsersFromEnvironmentsFormWithConfirm.feature';
import { DialogNew } from 'shared/components/dialog-new';
import { IconUser } from 'shared/components/icons/icon-user';

interface Props {
  open: boolean;
  selectedUsers: KogniaUser[];
  onClose: () => void;
  onSuccess: () => void;
}

export const RemoveUsersFromEnvironmentsModal = ({ open, selectedUsers, onClose, onSuccess }: Props) => {
  const formRef = useRef<HTMLFormElement>(null);

  const handleSubmit = () => {
    formRef.current?.requestSubmit();
  };

  const handleSuccess = () => {
    onSuccess();
    onClose();
  };

  return (
    <DialogNew
      maxWidth='md'
      fullWidth
      title='Remove users from environments'
      icon={<IconUser size='small' />}
      onCancel={onClose}
      onClose={onClose}
      onSubmit={handleSubmit}
      buttonSubmitText={'Remove'}
      open={open}
    >
      <RemoveUsersFromEnvironmentsFormWithConfirm
        ref={formRef}
        onSuccess={handleSuccess}
        selectedUsers={selectedUsers}
      />
    </DialogNew>
  );
};
