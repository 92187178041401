import { Button, Link, Modal } from '@mui/material';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDownloadEffectiveTimeVideos } from 'api/video-sources/use-download-effective-time-videos/useDownloadEffectiveTimeVideos';
import styles from 'pages/tactical-analysis/components/tactical-analysis/filters-bar/export-button/export-modal/ExportModal.module.scss';
import {
  ExportModalIconContainer,
  ExportModalContent,
} from 'pages/tactical-analysis/components/tactical-analysis/filters-bar/export-button/export-modal/ExportModal.styled';
import { ExportModals } from 'pages/tactical-analysis/components/tactical-analysis/filters-bar/export-button/index';
import IconExport from 'shared/components/icons/icon-export';
import RadioButton from 'shared/components/radio-button';
import { ActionTypes } from 'shared/streams/actionTypes';
import { publishEvent } from 'shared/streams/eventEmitter';
import { MetricsNames } from 'shared/types/metrics';

type ExportModalProps = {
  tacticalAnalysisId: string | undefined;
  onClose: () => void;
  modalTitle: string;
  options: ExportModalOption[];
  type: ExportModals;
};

type ExportModalOption = {
  type: ExportVideoTypes;
  displayValue: string;
  downloadUrl?: string;
  enabled: boolean;
};

export enum ExportVideoTypes {
  FULL_MATCH = 'full-match',
  EFFECTIVE_TIME = 'effective-time',
}

const ExportModal = ({ tacticalAnalysisId, onClose, modalTitle, options, type }: ExportModalProps) => {
  const { t } = useTranslation();

  const findFirstEnabled = (options: ExportModalOption[]): ExportModalOption | undefined => {
    return options.find((option) => option.enabled);
  };

  const [selectedOption, setSelectedOption] = useState<ExportModalOption | undefined>(findFirstEnabled(options));
  const downloadEffectiveTimeVideos = useDownloadEffectiveTimeVideos();

  const handleSelectOption = (id: string) => {
    const newOption = options.find((o) => o.type === id);
    setSelectedOption(newOption);
  };

  const handleExportClick = useCallback(() => {
    if (type === ExportModals.VIDEO) {
      publishEvent({
        type: ActionTypes.EXPORT_TACTICAL_ANALYSIS_VIDEO,
        payload: { name: MetricsNames.TACTICAL_ANALYSIS_EXPORT_VIDEO },
      });

      if (selectedOption?.type === ExportVideoTypes.EFFECTIVE_TIME && tacticalAnalysisId !== undefined) {
        downloadEffectiveTimeVideos(tacticalAnalysisId);
      }
    }
    if (type === ExportModals.XML)
      publishEvent({
        type: ActionTypes.EXPORT_TACTICAL_ANALYSIS_XML,
        payload: { name: MetricsNames.TACTICAL_ANALYSIS_EXPORT_XML },
      });
  }, [type, selectedOption?.type, downloadEffectiveTimeVideos, tacticalAnalysisId]);

  const exportUrl = selectedOption?.downloadUrl;
  const fullMatchSelectedOption = selectedOption?.type === ExportVideoTypes.FULL_MATCH && exportUrl !== undefined;
  const exportButtonProps = fullMatchSelectedOption
    ? {
        href: exportUrl as string,
        component: Link,
        download: true,
      }
    : {};

  return (
    <Modal
      open
      onClose={onClose}
      aria-labelledby='export-modal-title'
      aria-describedby='export-modal-description'
      className={styles.modalStyle}
    >
      <ExportModalContent>
        <div className={styles.modalHeader}>
          <ExportModalIconContainer>
            <IconExport size='small' />
          </ExportModalIconContainer>
          {modalTitle.toUpperCase()}
        </div>
        <div className={styles.radioButtonsContainer}>
          {options.map((option) => (
            <RadioButton
              key={option.type}
              id={option.type}
              checked={selectedOption?.type === option.type}
              onSelect={handleSelectOption}
              displayValue={option.displayValue}
              disabled={!option.enabled}
            />
          ))}
        </div>
        <div className={styles.actionButtonsContainer}>
          <Button variant='outlined' color='secondary' onClick={onClose}>
            {t('common:actions.cancel')}
          </Button>
          <Button
            variant='contained'
            disabled={selectedOption === undefined}
            onClick={handleExportClick}
            {...exportButtonProps}
          >
            {t('timeline:actions.export.title')}
          </Button>
        </div>
      </ExportModalContent>
    </Modal>
  );
};

export default ExportModal;
