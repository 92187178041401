import { TacticId } from '@kognia/tactical-analysis-data';
import { Duration } from 'luxon';
import { Homographies, PlayersPositions, TimeSeries } from '../main';
import { OverlayElementGlyphs } from '../overlay-canvas-renderer/overlay-elements/interface';
import { Teams } from '../overlay-canvas-renderer/types';
import { OverlayElementNames } from '../types';
import { makeFetch } from './makeFetch';
import { transformTimeSeries } from './transformTimeSeries';

export interface RecordingOverlayData {
  overlayTactics: OverlayTactic[];
  trajectories: PlayersPositions;
  homographies: Homographies;
}

interface OverlayData {
  overlayTactics: OverlayTactic[];
  trajectories: PlayersPositions;
  timeSeries: TimeSeries;
}

export interface OverlayTactic {
  tacticTypeId: TacticId;
  startFrame: number;
  endFrame: number;
  overlayElements: OverlayElement[];
}

export interface OverlayTacticWithGlyphs {
  tacticId: TacticId;
  startFrame: number;
  endFrame: number;
  overlayElementsGlyphs: OverlayElementGlyphs[];
}

export interface OverlayElement {
  overlayElementTypeId: OverlayElementNames;
  startFrame: number;
  endFrame: number;
  references: Reference[];
}

export type Reference =
  | {
      referenceType: ReferenceType.Players;
      values: string[];
    }
  | {
      referenceType: ReferenceType.StaticCoordinates;
      values: [[number, number][]];
    };

export enum ReferenceType {
  Players = 'players',
  StaticCoordinates = 'static-coordinates',
}

export type Segment = {
  segmentType: {
    name: string;
    start: number;
    length: number;
  };
  startFrame: number;
  endFrame: number;
  pitchLeftSideTeamId: string;
  pitchRightSideTeamId: string;
};

interface OverlayElementsMetaDataApi {
  pitchSize: { length: number; width: number };
  segments: Segment[];
  video: { height: number; width: number; frameRate: number; duration: string };
  teams: Teams;
}

export interface OverlayElementsMetaData extends OverlayElementsMetaDataApi {
  video: { height: number; width: number; frameRate: number; duration: string; frameCount: number };
}

interface LoadMetaDataParams {
  recordingId: string;
  domainUrl?: string;
  headers?: HeadersInit;
  fetchInterface: any;
}

export const loadMetaData = async ({
  recordingId,
  domainUrl = '',
  headers,
  fetchInterface,
}: LoadMetaDataParams): Promise<OverlayElementsMetaData> => {
  const overlayMetaData = await makeFetch<OverlayElementsMetaDataApi>({
    url: `${domainUrl}/api/overlay-elements-metadata?recordingId=${recordingId}`,
    headers,
    fetchInterface,
  });

  return {
    ...overlayMetaData,
    video: {
      ...overlayMetaData.video,
      frameCount:
        (Duration.fromISO(overlayMetaData.video.duration).toMillis() / 1000) * overlayMetaData.video.frameRate,
    },
  };
};

interface LoadChunkParams {
  tacticalAnalysisId: string;
  startFrame: number;
  endFrame: number;
  domainUrl?: string;
  headers?: HeadersInit;
  fetchInterface: any;
}

export const loadChunk = async ({
  tacticalAnalysisId,
  startFrame,
  endFrame,
  domainUrl = '',
  headers,
  fetchInterface,
}: LoadChunkParams) => {
  const { overlayTactics, trajectories, timeSeries } = await makeFetch<OverlayData>({
    url: `${domainUrl}/api/overlay-data/${tacticalAnalysisId}?startFrame=${startFrame}&endFrame=${endFrame}&smoothingEnabled=true`,
    headers,
    fetchInterface,
  });

  const homographies = transformTimeSeries(timeSeries);

  return <RecordingOverlayData>{
    homographies,
    trajectories,
    overlayTactics,
  };
};
