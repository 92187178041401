import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { PlaylistsData } from 'api/playlist/useFetchPlaylists';
import { INITIAL_PLAYLISTS_FILTERS, useSetPlaylistsFilters } from 'api/playlist/useFetchPlaylists/atoms';
import { playlistPageQueryParams, routes } from 'kognia/router/routes';
import { CopyPlaylist } from 'pages/playlists/components/copy-playlist';
import { PlaylistsList } from 'pages/playlists/components/playlists-list';
import { usePlaylistsWithQueryStringState } from 'pages/playlists/components/playlists-list/hooks/usePlaylistsWithQueryStringState';
import PlaylistsSkeleton from 'pages/playlists/components/playlists-skeleton';
import Container from 'shared/components/container';
import EmptyList from 'shared/components/empty-page';
import { useBranding } from 'shared/hooks/use-branding/useBranding';
import usePageStateMachine from 'shared/hooks/use-page-state-machine';
import { useQueryParams } from 'shared/hooks/use-query-params';

export const PlaylistsContainer = () => {
  const { t } = useTranslation();
  const query = useQueryParams();
  const branding = useBranding();
  const setPlaylistsFilters = useSetPlaylistsFilters(routes.PLAYLISTS);
  const location = useLocation();

  const copyPlaylistQueryParam: string | null = query.get(playlistPageQueryParams.copyPlaylist);

  const { isPageLoading, isPageMissingData, isPageError, isPageReady } = usePageStateMachine<PlaylistsData>(() =>
    usePlaylistsWithQueryStringState(),
  );

  useEffect(() => {
    document.title = t('common:metas.title.playlists', { clientDisplayName: branding.displayName });
  }, [branding.displayName, t]);

  useEffect(() => {
    if (location.search === '') setPlaylistsFilters({ ...INITIAL_PLAYLISTS_FILTERS });
  }, [location.search, setPlaylistsFilters]);

  return (
    <Container>
      {isPageLoading && <PlaylistsSkeleton />}
      {(isPageMissingData || isPageError) && (
        <EmptyList header={t('playlists:not-found.header')} description={t('playlists:not-found.description')} />
      )}
      {isPageReady && (
        <>
          <PlaylistsList />
          {copyPlaylistQueryParam ? <CopyPlaylist playlistId={copyPlaylistQueryParam} /> : null}
        </>
      )}
    </Container>
  );
};
