import { useQueryClient } from '@tanstack/react-query';

import { useInfinityQuery } from 'api/hooks/useInfinityQuery';
import { InfinityQueryResult } from 'api/hooks/useInfinityQuery/types';
import { teamsWithFiltersUrl } from 'pages/backoffice/api/routes';
import { transformTeams } from 'pages/backoffice/api/teams/transformers';
import { MetadataTeamsApiResponse } from 'pages/backoffice/api/teams/use-teams/types';
import { useAllowedBackofficeOrigin } from 'pages/backoffice/api/use-allowed-backoffice-origin';
import { MetadataTeamsFilters, MetadataTeamWithFixtures } from 'pages/backoffice/types/teams';
import { SortDirection } from 'shared/types/filters/types';

const TEAMS_QUERY_KEY = 'teams';

export const useTeams = (): InfinityQueryResult<MetadataTeamWithFixtures, MetadataTeamsFilters> => {
  const { getIsAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();

  return useInfinityQuery<MetadataTeamWithFixtures, MetadataTeamsFilters, MetadataTeamsApiResponse>({
    generateUrl: teamsWithFiltersUrl,
    key: TEAMS_QUERY_KEY,
    options: {
      size: 10,
      sort: 'name',
      sortDirection: SortDirection.DESC,
    },
    transformer: transformTeams,
    queryOptions: {
      enabled: getIsAllowedBackofficeOrigin(),
    },
  });
};

export const useInvalidateTeams = () => {
  const queryClient = useQueryClient();

  return () =>
    queryClient.invalidateQueries({
      queryKey: [TEAMS_QUERY_KEY],
    });
};
