import { Box, MenuItem, Select, TextField, Typography } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { KeypadTag } from 'api/tagging-tool/types';
import { HotkeyInput } from 'pages/tagging-tool-keypad-edit/components/hot-key-input/HotkeyInput';
import {
  KeypadCustomButtonCutBox,
  KeypadCustomButtonForm,
  KeypadFormItem,
  KeypadFormRow,
  KeypadOptionalText,
  KeypadSelectContainer,
  TextFieldExtended,
  TimeframeEndAdornment,
} from 'pages/tagging-tool-keypad-edit/components/keypad-tag-form-modal/index.styled';
import {
  KeypadCustomButtonFieldsNames,
  KeypadCustomButtonFormInputs,
} from 'pages/tagging-tool-keypad-edit/components/keypad-tag-form-modal/types';
import { DialogNew } from 'shared/components/dialog-new';
import { FormInputLabel } from 'shared/components/form/form-input-label';
import IconTag from 'shared/components/icons/icon-tag';
import { TypeOfPlay, TypeOfPlaySource } from 'shared/types/type-of-play/types';
import { KeypadTagHotKeyResolver } from 'tagging-tool/utility/hotkeys';

export type KeypadItemFormModalProps = {
  data?: Partial<KeypadTag>;
  open: boolean;
  hotKeyResolver?: KeypadTagHotKeyResolver;
  onSave: (data: KeypadTag) => void;
  onClosed?: () => void;
  onCancel: () => void;
};

type SelectOption<V> = {
  value: V;
  title: string;
};

const initialValues: Partial<KeypadCustomButtonFormInputs> = {
  [KeypadCustomButtonFieldsNames.NAME]: '',
  [KeypadCustomButtonFieldsNames.KEYBOARD_SHORTCUT]: null,
  [KeypadCustomButtonFieldsNames.TIMEFRAME_BEFORE]: 5,
  [KeypadCustomButtonFieldsNames.TIMEFRAME_AFTER]: 5,
  [KeypadCustomButtonFieldsNames.DESCRIPTION]: null,
  [KeypadCustomButtonFieldsNames.TYPE_OF_PLAY_SOURCE]: null,
};

export const KeypadTagFormModal = ({ open, data, onSave, onCancel }: KeypadItemFormModalProps) => {
  const { t } = useTranslation();

  const defaultValues = {
    [KeypadCustomButtonFieldsNames.NAME]: data?.name ? data.name : initialValues[KeypadCustomButtonFieldsNames.NAME],
    ...(data?.typeOfPlay && { [KeypadCustomButtonFieldsNames.TYPE_OF_PLAY]: data.typeOfPlay }),
    ...(data?.typeOfPlaySource && { [KeypadCustomButtonFieldsNames.TYPE_OF_PLAY_SOURCE]: data.typeOfPlaySource }),
    [KeypadCustomButtonFieldsNames.TIMEFRAME_BEFORE]:
      data?.timeBefore ?? initialValues[KeypadCustomButtonFieldsNames.TIMEFRAME_BEFORE],
    [KeypadCustomButtonFieldsNames.TIMEFRAME_AFTER]: data?.timeAfter
      ? data?.timeAfter
      : initialValues[KeypadCustomButtonFieldsNames.TIMEFRAME_AFTER],
    [KeypadCustomButtonFieldsNames.KEYBOARD_SHORTCUT]: data?.hotKey
      ? data?.hotKey
      : initialValues[KeypadCustomButtonFieldsNames.KEYBOARD_SHORTCUT],
    [KeypadCustomButtonFieldsNames.DESCRIPTION]: data?.description
      ? data?.description
      : initialValues[KeypadCustomButtonFieldsNames.DESCRIPTION],
  };

  const {
    register,
    formState,
    watch,
    setValue,
    getValues,
    handleSubmit: handleFormSubmit,
  } = useForm<KeypadCustomButtonFormInputs>({ defaultValues });

  const top1Options = useMemo<Array<SelectOption<TypeOfPlay>>>(() => {
    return [
      {
        title: t('tagging-tool:keypad-tag.type-of-play-offense'),
        value: TypeOfPlay.Offense,
      },
      {
        title: t('tagging-tool:keypad-tag.type-of-play-defense'),
        value: TypeOfPlay.Defense,
      },
      {
        title: t('tagging-tool:keypad-tag.type-of-play-transition'),
        value: TypeOfPlay.Transition,
      },
    ];
  }, [t]);

  const top2Options = useMemo<Array<SelectOption<TypeOfPlaySource>>>(() => {
    return [
      {
        title: t('tagging-tool:keypad-tag.type-of-play-individual'),
        value: TypeOfPlaySource.Individual,
      },
      {
        title: t('tagging-tool:keypad-tag.type-of-play-collective'),
        value: TypeOfPlaySource.Collective,
      },
    ];
  }, [t]);

  const handleSubmit = useCallback(() => {
    const keypadData: KeypadTag = {
      isEnabled: data!.isEnabled ?? false,
      isCustom: data!.isCustom ?? false,
      keypadId: data!.keypadId ?? '',
      keypadTagId: data!.keypadTagId ?? '',
      name: getValues(KeypadCustomButtonFieldsNames.NAME),
      typeOfPlay: getValues(KeypadCustomButtonFieldsNames.TYPE_OF_PLAY),
      typeOfPlaySource: getValues(KeypadCustomButtonFieldsNames.TYPE_OF_PLAY_SOURCE)
        ? getValues(KeypadCustomButtonFieldsNames.TYPE_OF_PLAY_SOURCE)
        : null,
      timeBefore: getValues(KeypadCustomButtonFieldsNames.TIMEFRAME_BEFORE),
      timeAfter: getValues(KeypadCustomButtonFieldsNames.TIMEFRAME_AFTER),
      hotKey: getValues(KeypadCustomButtonFieldsNames.KEYBOARD_SHORTCUT) ?? undefined,
      description: getValues(KeypadCustomButtonFieldsNames.DESCRIPTION),
    };
    onSave?.(keypadData);
  }, [data, getValues, onSave]);

  const typeOfPlayOptions = top1Options.map((item) => {
    return (
      <MenuItem key={item.value} value={item.value}>
        {item.title}
      </MenuItem>
    );
  });

  const typeOfPlaySourceOptions = top2Options.map((item) => {
    return (
      <MenuItem key={item.value} value={item.value}>
        {item.title}
      </MenuItem>
    );
  });

  const formIsDisabled =
    !watch(KeypadCustomButtonFieldsNames.NAME) ||
    !watch(KeypadCustomButtonFieldsNames.TYPE_OF_PLAY) ||
    (data?.isCustom === true && !watch(KeypadCustomButtonFieldsNames.TYPE_OF_PLAY_SOURCE)) ||
    !formState.isDirty;
  return (
    <DialogNew
      title={
        data?.name
          ? t('tagging-tool:keypad-tag.editing-button-details', { name: data.name })
          : t('tagging-tool:keypad-tag.creating-new')
      }
      icon={<IconTag size='small' />}
      onCancel={onCancel}
      onSubmit={handleSubmit}
      buttonSubmitText={t('common:actions.save')}
      open={open}
      fullWidth
      maxWidth='sm'
      submitDisabled={formIsDisabled}
    >
      <KeypadCustomButtonForm onSubmit={handleFormSubmit(handleSubmit)}>
        <Box>
          <FormInputLabel>Name</FormInputLabel>
          <TextField required fullWidth size='small' {...register(KeypadCustomButtonFieldsNames.NAME)} />
        </Box>
        <KeypadFormRow>
          <KeypadSelectContainer>
            <FormInputLabel id={`${KeypadCustomButtonFieldsNames.TYPE_OF_PLAY}-label`}>
              {t('tagging-tool:keypad-tag.type-of-play')}
            </FormInputLabel>
            <Select
              labelId={`${KeypadCustomButtonFieldsNames.TYPE_OF_PLAY}-label`}
              id={KeypadCustomButtonFieldsNames.TYPE_OF_PLAY}
              size='small'
              fullWidth
              value={watch(KeypadCustomButtonFieldsNames.TYPE_OF_PLAY)}
              {...register(KeypadCustomButtonFieldsNames.TYPE_OF_PLAY)}
            >
              {typeOfPlayOptions}
            </Select>
          </KeypadSelectContainer>
          <KeypadSelectContainer>
            <Select
              labelId={`${KeypadCustomButtonFieldsNames.TYPE_OF_PLAY_SOURCE}-label`}
              id={KeypadCustomButtonFieldsNames.TYPE_OF_PLAY_SOURCE}
              size='small'
              fullWidth
              value={watch(KeypadCustomButtonFieldsNames.TYPE_OF_PLAY_SOURCE)}
              {...register(KeypadCustomButtonFieldsNames.TYPE_OF_PLAY_SOURCE)}
            >
              {typeOfPlaySourceOptions}
            </Select>
          </KeypadSelectContainer>
        </KeypadFormRow>
        <KeypadFormRow>
          <KeypadSelectContainer>
            <FormInputLabel id={`${KeypadCustomButtonFieldsNames.TIMEFRAME_BEFORE}-label`}>
              {t('tagging-tool:keypad-tag.cut-time-frame')}
            </FormInputLabel>
            <TextFieldExtended
              id={KeypadCustomButtonFieldsNames.TIMEFRAME_BEFORE}
              size='small'
              fullWidth
              type={'number'}
              InputProps={{
                endAdornment: (
                  <TimeframeEndAdornment>{t('tagging-tool:keypad-tag.seconds-after')}</TimeframeEndAdornment>
                ),
              }}
              {...register(KeypadCustomButtonFieldsNames.TIMEFRAME_BEFORE)}
            />
          </KeypadSelectContainer>
          <KeypadCustomButtonCutBox>
            <Typography>{t('tagging-tool:keypad-tag.cut')}</Typography>
          </KeypadCustomButtonCutBox>
          <KeypadSelectContainer>
            <TextFieldExtended
              id={KeypadCustomButtonFieldsNames.TIMEFRAME_AFTER}
              size='small'
              fullWidth
              type={'number'}
              InputProps={{
                endAdornment: (
                  <TimeframeEndAdornment>{t('tagging-tool:keypad-tag.seconds-before')}</TimeframeEndAdornment>
                ),
              }}
              {...register(KeypadCustomButtonFieldsNames.TIMEFRAME_AFTER)}
            />
          </KeypadSelectContainer>
        </KeypadFormRow>
        <KeypadFormRow>
          <KeypadFormItem>
            <KeypadOptionalText>{t('common:actions.optional')}</KeypadOptionalText>
            <FormInputLabel>{t('tagging-tool:keypad-tag.shortcut')}</FormInputLabel>
            <HotkeyInput
              placeholder={t('tagging-tool:keypad-tag.shortcut-placeholder')}
              value={data?.hotKey ? data.hotKey : watch(KeypadCustomButtonFieldsNames.KEYBOARD_SHORTCUT)}
              onChange={(value) => setValue(KeypadCustomButtonFieldsNames.KEYBOARD_SHORTCUT, value)}
            />
          </KeypadFormItem>
        </KeypadFormRow>
        <KeypadFormRow>
          <KeypadFormItem>
            <KeypadOptionalText>{t('common:actions.optional')}</KeypadOptionalText>
            <FormInputLabel>{t('tagging-tool:keypad-tag.shortcut')}</FormInputLabel>
            <TextField
              fullWidth
              size='small'
              id={KeypadCustomButtonFieldsNames.DESCRIPTION}
              multiline
              rows={3}
              placeholder={t('tagging-tool:keypad-tag.description-placeholder')}
              {...register(KeypadCustomButtonFieldsNames.DESCRIPTION)}
            />
          </KeypadFormItem>
        </KeypadFormRow>
      </KeypadCustomButtonForm>
    </DialogNew>
  );
};
