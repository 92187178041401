import { PlayerImageElement } from 'shared/components/player-photo/styled';

export type PlayerPhotoSizes = 'small' | 'medium' | 'large' | 'xlarge';

interface Props {
  src?: string | null;
  size?: PlayerPhotoSizes;
  name?: string;
  withBorder?: boolean;
}

export const PlayerPhoto = ({ src, size = 'small', name = '', withBorder = false }: Props) => {
  return <PlayerImageElement withBorder={withBorder} size={size} src={src ?? undefined} alt={name} />;
};
