import { Box, IconButton, Link, Popper, Stack, Typography } from '@mui/material';
import { fontSizes } from 'kognia-ui';
import { forwardRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { NotViewedRecordingsData } from 'features/download-manager/sidebar-menu-item-download-manager/types/notViewedRecordingsData';
import IconClose from 'shared/components/icons/icon-close';
import { IconWarning } from 'shared/components/icons/icon-warning';
import { DownloadRequest } from 'shared/types/download-manager/downloadRequests';

interface Props {
  open: boolean;
  anchorEl: HTMLDivElement | null;
  amountOfNotViewedRecordings: number;
  onCloseWithDownloadRequest: () => void;
  onCloseWithSavePreviousNotViewedData: () => void;
  data: DownloadRequest | null | undefined;
  notViewedRecordingsData: NotViewedRecordingsData;
}

export const DownloadManagerPopper = forwardRef(
  (
    {
      open,
      anchorEl,
      onCloseWithDownloadRequest,
      onCloseWithSavePreviousNotViewedData,
      amountOfNotViewedRecordings,
      notViewedRecordingsData,
      data,
    }: Props,
    ref: ((instance: HTMLDivElement | null) => void) | React.RefObject<HTMLDivElement> | null | undefined,
  ) => {
    const { t } = useTranslation('download-manager');

    return (
      <Popper
        ref={ref}
        id={open ? 'download-popover' : undefined}
        open={open}
        anchorEl={anchorEl}
        placement='right'
        sx={(theme) => ({
          color: 'common.white',
          zIndex: theme.zIndex.drawer,
        })}
      >
        <Box
          position='relative'
          maxWidth='300px'
          ml={1}
          padding={2}
          borderRadius={1}
          bgcolor='info.main'
          sx={{
            '&::before': {
              backgroundColor: 'info.main',
              content: '""',
              position: 'absolute',
              top: 'calc(50% - 6px)',
              left: '-6px',
              display: 'block',
              width: '12px',
              height: '12px',
              transform: 'rotate(45deg)',
            },
          }}
        >
          <IconButton
            color='inherit'
            sx={({ spacing }) => ({ position: 'absolute', top: spacing(1), right: spacing(1), padding: 0 })}
            onClick={onCloseWithSavePreviousNotViewedData}
          >
            <IconClose size='small' color='inherit' />
          </IconButton>

          {amountOfNotViewedRecordings === 1 && data?.status === 'processed' && (
            <Stack gap={1}>
              <Stack>
                <Typography fontWeight='fontWeightMedium' variant='body2'>
                  {t('download-manager:single-recording.title')}
                </Typography>
                <Typography variant='body2'>
                  {t('download-manager:single-recording.description', { playlistName: data.name })}
                </Typography>
              </Stack>
              <Stack direction='row' justifyContent='end'>
                <Link
                  download={data.name}
                  href={data.downloadUrl}
                  onClick={onCloseWithDownloadRequest}
                  color='inherit'
                  fontSize={fontSizes.small}
                  fontWeight='fontWeightMedium'
                  sx={{
                    padding: 0,
                    '&:hover': {
                      color: 'inherit',
                      textDecoration: 'underline',
                    },
                    '&:after': {
                      display: 'none',
                      width: 0,
                    },
                  }}
                >
                  {t('download-manager:single-recording.link')}
                </Link>
              </Stack>
            </Stack>
          )}

          {amountOfNotViewedRecordings === 1 && data?.status === 'error' && (
            <Stack gap={0.125}>
              <Stack direction='row' gap={0.5} alignItems='center'>
                <IconWarning size='small' color='inherit' />
                <Typography fontWeight='fontWeightMedium' variant='body2'>
                  {t('download-manager:error-recording.title')}
                </Typography>
              </Stack>

              <Typography variant='body2'>
                {t('download-manager:error-recording.description', { playlistName: data.name })}
              </Typography>
            </Stack>
          )}

          {amountOfNotViewedRecordings > 1 && (
            <>
              {amountOfNotViewedRecordings === notViewedRecordingsData.processed ? (
                <>
                  <Typography fontWeight='fontWeightMedium' variant='body2'>
                    {t('download-manager:multiple-recordings.title')}
                  </Typography>
                  <Typography variant='body2'>
                    {t('download-manager:multiple-recordings.description', { amount: amountOfNotViewedRecordings })}
                  </Typography>
                </>
              ) : (
                <>
                  {amountOfNotViewedRecordings === notViewedRecordingsData.failed ? (
                    <>
                      <Stack gap={0.125}>
                        <Stack direction='row' gap={0.5} alignItems='center'>
                          <IconWarning size='small' color='inherit' />
                          <Typography fontWeight='fontWeightMedium' variant='body2'>
                            {t('download-manager:single-status-error-recordings.title')}
                          </Typography>
                        </Stack>

                        <Typography variant='body2'>
                          {t('download-manager:single-status-error-recordings.description', {
                            amount: notViewedRecordingsData.failed,
                          })}
                        </Typography>
                      </Stack>
                    </>
                  ) : (
                    <Stack gap={2}>
                      <Stack gap={1}>
                        <Typography fontWeight='fontWeightMedium' variant='body2'>
                          {t('download-manager:multiple-recordings.title')}
                        </Typography>
                        <Typography variant='body2'>
                          {t('download-manager:multiple-recordings.description', {
                            amount: notViewedRecordingsData.processed,
                          })}
                        </Typography>
                      </Stack>

                      <Stack direction='row' gap={0.5}>
                        <IconWarning size='small' color='inherit' />

                        <Typography variant='body2'>
                          <Trans
                            i18nKey='download-manager:multiple-status-error-recordings.description'
                            values={{ amount: notViewedRecordingsData.failed }}
                            components={{
                              bold: <Typography variant='body2' component='span' fontWeight='fontWeightMedium' />,
                            }}
                          />
                        </Typography>
                      </Stack>
                    </Stack>
                  )}
                </>
              )}
            </>
          )}
        </Box>
      </Popper>
    );
  },
);

DownloadManagerPopper.displayName = 'DownloadManagerPopper';
