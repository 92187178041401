import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import styles from 'pages/home/HomeContainer.module.scss';
import { Solutions } from 'pages/home/ui/solutions/Solutions';
import Container from 'shared/components/container';
import { useUser } from 'shared/contexts/app-state/hooks/useUser';
import { useBranding } from 'shared/hooks/use-branding/useBranding';

export const HomeContainer = () => {
  const user = useUser();
  const { t } = useTranslation();
  const branding = useBranding();

  useEffect(() => {
    document.title = t('common:metas.title.home', { clientDisplayName: branding.displayName });
  }, [branding.displayName, t]);

  return (
    <Container>
      <div className={styles.homePage}>
        <h1 data-testid='page-title'>{t('home:welcome', { firstName: user.firstName })}</h1>
        <p>{t('home:what-would-you-like-to-do')}</p>
        <Solutions />
      </div>
    </Container>
  );
};

export default HomeContainer;
