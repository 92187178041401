import { SvgIcon, type SvgIconProps } from 'shared/components/icons/svg-icon/SvgIcon';

const IconStrawberry = (props: Omit<SvgIconProps, 'children'>): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path d='M4.5 7v1.667h15V7h-15zm5.833 10h3.333v-1.667h-3.333V17zM17 12.833H7v-1.667h10v1.667z' />
    </SvgIcon>
  );
};
export default IconStrawberry;
