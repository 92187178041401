import classNames from 'classnames';
import { motion, usePresence } from 'framer-motion';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import { useDeletePlaylist } from 'api/playlist/useDeletePlaylist';
import { invalidatePlaylistsQuery, removePlaylistItemFromList } from 'api/playlist/useFetchPlaylists';
import { routes } from 'kognia/router/routes';
import styles from 'pages/playlists/components/playlist-card/PlaylistCard.module.scss';
import PlaylistDuration from 'pages/playlists/components/playlist-duration';
import PlaylistNumberOfClips from 'pages/playlists/components/playlist-number-of-clips';
import { PlaylistsListItemDetails } from 'pages/playlists/components/playlists-list-item-details';
import DuplicatePlaylistDialog from 'pages/recording-playlists/components/duplicate-playlist-dialog';
import DateInfo from 'pages/recordings-list/components/live-recording-card/date-info';
import { getAnimationStatus, recordingVariants, Variants } from 'pages/recordings-list/components/recording-card/utils';
import IconCopy from 'shared/components/icons/icon-copy';
import IconDelete from 'shared/components/icons/icon-delete';
import IconShare from 'shared/components/icons/icon-share';
import KebabMenu, { MenuListOption } from 'shared/components/kebab-menu';
import { ListItem } from 'shared/components/list/list-item';
import { ListItemTitle } from 'shared/components/list/list-item-title';
import SharePlaylistModal from 'shared/components/share-playlist-modal';
import Spinner from 'shared/components/spinner';
import { useClientId } from 'shared/contexts/app-state/hooks/useClientId';
import useConfirmPopoverDialog from 'shared/hooks/use-confirm-popover-dialog';
import { Playlist } from 'shared/types';

interface Props {
  playlist: Playlist;
}

export const PlaylistCard = ({ playlist }: Props) => {
  const { clientId } = useClientId();
  const [isDeleted, setIsDeleted] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const { isPending, deletePlaylist, isSuccess: isDeleteSuccess } = useDeletePlaylist(playlist.id);
  const { isOpen, setIsOpen, ConfirmPopoverDialog } = useConfirmPopoverDialog();
  const kebabRef = useRef<HTMLDivElement | null>(null);
  const [isPresent, safeToRemove] = usePresence();
  const [duplicateModalOpen, setDuplicateModalOpen] = useState(false);

  useEffect(() => {
    if (isDeleteSuccess) {
      removePlaylistItemFromList(playlist.id);
    }
  }, [clientId, isDeleteSuccess, playlist.id]);

  const handleOnClickPlaylist = useCallback(
    () => navigate(generatePath(routes.RECORDING_PLAYLIST_DETAIL, { playlistId: playlist.id })),
    [navigate, playlist.id],
  );

  const openDuplicateModal = () => {
    setDuplicateModalOpen(true);
  };

  const closeModal = () => {
    setShareModalOpen(false);
  };

  const onClickShare = () => {
    setShareModalOpen(true);
  };

  const options = [
    {
      displayText: t('recording-playlists:share-modal.share-playlist'),
      icon: <IconShare size='small' color='secondary' />,
      onClick: onClickShare,
    },
    {
      displayText: t('common:actions.duplicate'),
      icon: <IconCopy size='small' color='secondary' />,
      onClick: openDuplicateModal,
    },
    {
      displayText: t('common:actions.delete'),
      icon: <IconDelete size='small' color='secondary' />,
      onClick: (event?: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        event && event.stopPropagation();
        setIsOpen(true);
      },
    },
  ] as MenuListOption[];

  const handleDelete = () => {
    deletePlaylist();
    setIsOpen(false);
    setIsDeleted(true);
  };

  const onDuplicateModal = () => {
    setDuplicateModalOpen(false);
  };

  return (
    <>
      <motion.li
        layout
        initial={Variants.IN}
        animate={getAnimationStatus(isPresent, isDeleted)}
        variants={recordingVariants}
        onAnimationComplete={() => !isPresent && safeToRemove && safeToRemove()}
        className={styles.container}
      >
        <ListItem
          className={classNames(styles.card, {
            [styles.deleting]: isDeleted,
          })}
          onClick={handleOnClickPlaylist}
          data-testid='playlist-card'
        >
          <ListItemTitle>
            <h2 data-testid={'playlist-title'}>{playlist.name}</h2>
          </ListItemTitle>
          <PlaylistsListItemDetails>
            <div />
            <PlaylistNumberOfClips number={playlist.playlistItems.length} />
            <PlaylistDuration
              duration={playlist.playlistItems.reduce(
                (duration, item) => duration + (item.endTime - item.startTime),
                0,
              )}
            />
            <DateInfo date={new Date(playlist.updatedAt)} />
            <div className={styles.recordingActions}>
              {isPending && <Spinner size='small' />}
              {!isDeleted && !isPending && <KebabMenu ref={kebabRef} options={options} />}
            </div>
          </PlaylistsListItemDetails>
        </ListItem>
      </motion.li>
      {shareModalOpen && <SharePlaylistModal playlist={playlist} onCloseModal={closeModal} />}
      {duplicateModalOpen && (
        <DuplicatePlaylistDialog
          onUpdate={invalidatePlaylistsQuery}
          isOpen={duplicateModalOpen}
          onClose={onDuplicateModal}
          playlist={playlist}
        />
      )}
      <ConfirmPopoverDialog
        anchorEl={kebabRef.current}
        cancelLabel={t('common:actions.cancel')}
        confirmLabel={t('common:actions.delete')}
        description={t('recordings-list:delete-confirm-modal.confirm-message')}
        isOpen={isOpen}
        onConfirm={handleDelete}
        setIsOpen={setIsOpen}
        buttonColor='warning'
      />
    </>
  );
};
