import { SvgIcon, type SvgIconProps } from 'shared/components/icons/svg-icon/SvgIcon';

const IconPlay = (props: Omit<SvgIconProps, 'children'>): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path d='M7.4448819 19l11.0000001-7L7.4448819 5z' />
    </SvgIcon>
  );
};

export default IconPlay;
