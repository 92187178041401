import { InfinityQueryDataResult } from 'api/hooks/useInfinityQuery/types';
import { CompetitionApi } from 'pages/backoffice/api/competitions/types';
import { CompetitionsApiResponse } from 'pages/backoffice/api/competitions/use-competitions/types';
import { Competition } from 'pages/backoffice/types/competitions';
import { chooseNextCursorValue } from 'shared/utils/choose-next-cursor-value';

export const transformCompetitions = (items: CompetitionApi[]): Competition[] => {
  return items.map((item) => ({
    id: item.uuid,
    name: item.name,
    country: item.country,
    seasons: item.seasons.map((season) => ({
      id: season.uuid,
      year: season.year,
      crossesYear: season.crosses_year,
      name: season.name,
    })),
  }));
};

export const transformPaginatedCompetitions = (
  response: CompetitionsApiResponse,
): InfinityQueryDataResult<Competition> => {
  return {
    data: {
      items: transformCompetitions(response.data),
      page: response.page,
    },
    nextCursor: chooseNextCursorValue(response.page.totalPages, response.page.number),
  };
};
