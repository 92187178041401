import { Button, Menu, Stack, Tooltip } from '@mui/material';
import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useSaveUserPreset } from 'api/user-presets/use-save-user-preset';
import IconPlaySpeed from 'shared/components/icons/icon-play-speed';
import { useSetVideoPlayerSpeed } from 'shared/components/video-player/hooks/use-video-player-speed';
import { SpeedItemButton } from 'shared/components/video-player/video-player-component/components/speed-button/styled';
import { PLAYBACK_RATES_VALUES } from 'shared/constants/playback-rates/paybackRates';
import { VideoPlayerSpeedPreset } from 'shared/constants/user-presets/userPresetsClasses';
import { PlaybackRate } from 'shared/types/payback-rates/payback-rates';

interface Props {
  playbackRate: PlaybackRate;
  container: HTMLDivElement | null;
}

export const SpeedButton = ({ playbackRate, container }: Props) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const setVideoPlayerSpeed = useSetVideoPlayerSpeed();
  const { savePreset } = useSaveUserPreset();

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  }, []);

  const handleChangeSpeed = useCallback(
    (speed: PlaybackRate, event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      setVideoPlayerSpeed(speed);
      const speedPreset = new VideoPlayerSpeedPreset(speed);
      savePreset({ data: speedPreset });
      handleClose(event);
    },
    [handleClose, savePreset, setVideoPlayerSpeed],
  );

  return (
    <>
      <Tooltip enterDelay={2000} title={`${t('video-player:controls.video-speed')}`}>
        <Button ref={buttonRef} size={'small'} onClick={handleClick}>
          <IconPlaySpeed sx={{ color: 'common.white' }} />
        </Button>
      </Tooltip>
      <Menu
        id='speed-button-menu'
        aria-labelledby='speed-button-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        container={container}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Stack direction='row' paddingX={1} spacing={0.5}>
          {PLAYBACK_RATES_VALUES.map((playbackRateValue) => (
            <SpeedItemButton
              key={playbackRateValue}
              variant={playbackRate === playbackRateValue ? 'contained' : 'text'}
              color={playbackRate === playbackRateValue ? 'primary' : 'secondary'}
              size={'small'}
              onClick={(event) => handleChangeSpeed(playbackRateValue, event)}
            >
              {playbackRateValue}
            </SpeedItemButton>
          ))}
        </Stack>
      </Menu>
    </>
  );
};
