import { UseSuspenseQueryResult } from '@tanstack/react-query';

import { useFetchSuspenseRequest } from 'api/hooks/useFetchSuspenseRequest';
import { playerCompetitions } from 'api/routes';
import { transformCompetitions } from 'pages/backoffice/api/competitions/use-competitions/transformer';
import { Competition } from 'pages/backoffice/types/competitions';

const PLAYER_COMPETITIONS_QUERY_KEY = 'player-competitions';

export const usePlayerCompetitions = (playerId: string): UseSuspenseQueryResult<Competition[]> => {
  return useFetchSuspenseRequest<Competition[]>({
    url: playerCompetitions(playerId),
    queryRef: [PLAYER_COMPETITIONS_QUERY_KEY, playerId],
    transformer: transformCompetitions,
  });
};
