import { IconButton, Stack, styled, TextField, TextFieldProps } from '@mui/material';
import { css } from '@mui/system';
import { Colors } from 'kognia-ui';
import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import IconMinus from 'shared/components/icons/icon-minus';
import IconPlus from 'shared/components/icons/icon-plus';

const TextFieldWithHiddenArrows = styled(TextField, { shouldForwardProp: (prop) => prop !== 'showControls' })(
  () => css`
    input {
      -moz-appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  `,
);

const ControlButton = styled(IconButton)(
  () => css`
    padding: 0;
    background-color: ${Colors.athens};

    &:hover {
      background-color: ${Colors.lavender};
    }
  `,
);

interface DefaultInputProps {
  min?: number;
  max?: number;
  step: number;
}

const defaultInputProps: DefaultInputProps = {
  min: undefined,
  max: undefined,
  step: 1,
};

type Props = Omit<TextFieldProps, 'variant'> & {
  showControls?: boolean;
};

function isNumber(value: unknown) {
  const number = Number(value);
  return typeof number === 'number' && !isNaN(number);
}

export const NumberField = (props: Props) => {
  const { value: valueProp, ...restProps } = props;
  const ref = useRef(null);
  const [value, setValue] = useState<number | undefined>(isNumber(valueProp) ? Number(valueProp) : undefined);

  const inputSettings: DefaultInputProps = useMemo(() => {
    return {
      min: props.inputProps?.min ?? defaultInputProps.min,
      max: props.inputProps?.max ?? defaultInputProps.max,
      step: props.inputProps?.step ?? defaultInputProps.step,
    };
  }, [props]);

  const simulateChangeEvent = useCallback(
    (customValue: number) => {
      const event: ChangeEvent<HTMLInputElement> = {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        target: { value: customValue },
      };
      props.onChange?.(event);
    },
    [props],
  );

  useEffect(() => {
    if (isNumber(valueProp)) setValue(Number(valueProp));
  }, [valueProp]);

  const handleIncrement = useCallback(() => {
    setValue((prev) => {
      if (!isNumber(prev) || prev === undefined) {
        simulateChangeEvent(+inputSettings.step);
        return +inputSettings.step;
      }
      simulateChangeEvent(prev + inputSettings.step);
      return prev + inputSettings.step;
    });
  }, [inputSettings.step, simulateChangeEvent]);

  const handleDecrement = useCallback(() => {
    setValue((prev) => {
      if (!isNumber(prev) || prev === undefined) {
        simulateChangeEvent(-inputSettings.step);
        return -inputSettings.step;
      }

      simulateChangeEvent(prev - inputSettings.step);
      return prev - inputSettings.step;
    });
  }, [inputSettings.step, simulateChangeEvent]);

  return (
    <Stack direction='row' spacing={1}>
      <TextFieldWithHiddenArrows ref={ref} variant='outlined' type='number' {...restProps} value={value} />
      {props.showControls && (
        <Stack direction='row' alignItems='center' spacing={1}>
          <ControlButton size={'small'} onClick={handleDecrement}>
            <IconMinus />
          </ControlButton>
          <ControlButton size={'small'} onClick={handleIncrement}>
            <IconPlus />
          </ControlButton>
        </Stack>
      )}
    </Stack>
  );
};
