import { ButtonBase } from '@mui/material';
import classNames from 'classnames';
import hotkeys, { KeyHandler } from 'hotkeys-js';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from 'pages/tagging-tool-keypad-edit/components/hot-key-input/HotKeyInput.module.scss';
import IconKeyboard from 'shared/components/icons/icon-keyboard';
import KebabMenu, { MenuListOption } from 'shared/components/kebab-menu';
import { keyboardEventToKey, hotKeyToString, HotKey, hotKeyToPrintableString } from 'tagging-tool/utility/hotkeys';

const SCOPE = 'ktt::keypad_tag_form';

type FormControlProps<V> = {
  id?: string;
  name?: string;
  error?: boolean;
  disabled?: boolean;
  placeholder?: string;
  value?: V | null;
  onChange?: (value: V) => void;
};

export type HotkeyInputProps = FormControlProps<string | null> & {
  initialValue?: string;
  className?: string;
  onRecordingChange?: (recording: boolean) => void;
};

export const HotkeyInput = (props: HotkeyInputProps) => {
  const kebabRef = useRef<HTMLDivElement | null>(null);
  const [recording, setRecording] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (!recording) {
      return;
    }

    let fingers = 0;
    const keys: HotKey = [];
    props.onChange?.(null);

    const handler: KeyHandler = (ev) => {
      ev.preventDefault();

      if (ev.type === 'keyup') {
        fingers -= 1;
      } else if (!ev.repeat) {
        if (!hotkeys.command || fingers === 0) {
          fingers += 1;
        }

        const key = keyboardEventToKey(ev);
        if (key !== undefined && keys.indexOf(key) < 0) {
          keys.push(key);
          props.onChange?.(hotKeyToString([...keys]));
        }
      }

      if (fingers === 0) {
        setRecording(false);
      }

      return false;
    };

    hotkeys('*', { scope: SCOPE, keydown: true, keyup: true }, handler);
    hotkeys.setScope(SCOPE);

    return () => {
      hotkeys.unbind('*', SCOPE, handler);
      hotkeys.deleteScope(SCOPE);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recording]);

  useEffect(() => {
    props.onRecordingChange?.(recording);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recording]);

  const handleRecord = useCallback(() => {
    if (recording) {
      return;
    }
    setRecording(true);
  }, [recording]);

  const handleRestore = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    props.onChange?.(props.initialValue!);
  }, [props]);

  const handleRemove = useCallback(() => {
    props.onChange?.(null);
  }, [props]);

  const showsRecordButton = recording || props.value === undefined || props.value === null;

  const menuOptions: MenuListOption[] = useMemo(
    () => [
      {
        displayText: t('tagging-tool:keypad-tag.record-new-shortcut'),
        onClick: handleRecord,
      },
      {
        isHidden: Boolean(!props.initialValue || props.initialValue === props.value),
        displayText: t('tagging-tool:keypad-tag.restore-previous-shortcut'),
        onClick: handleRestore,
      },
      {
        isHidden: Boolean(props.value === undefined || props.value === null),
        displayText: t('common:actions.remove'),
        onClick: handleRemove,
      },
    ],
    [handleRecord, handleRestore, handleRemove, props.initialValue, props.value, t],
  );

  return (
    <div
      className={classNames(
        styles.container,
        { [styles.errorText]: props.error, [styles.selected]: props.value },
        props.className,
      )}
    >
      <span className={styles.value}>
        {props.value ? hotKeyToPrintableString(props.value) : recording ? '' : props.placeholder ?? ''}
      </span>
      {showsRecordButton ? (
        <ButtonBase
          onClick={handleRecord}
          sx={{
            backgroundColor: (theme) => (recording ? theme.palette.error.main : theme.palette.grey[200]),
            p: 0.5,
            borderRadius: 1,
          }}
        >
          <IconKeyboard size='small' sx={{ color: recording ? 'common.white' : 'secondary.main' }} />
        </ButtonBase>
      ) : (
        <KebabMenu
          triggerComponent={<IconKeyboard size='small' color='secondary' />}
          ref={kebabRef}
          options={menuOptions}
          id={'hot-key-input'}
        />
      )}
    </div>
  );
};
