import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { HTTPMethod } from 'api/types';
import { transformMetadataGame } from 'pages/backoffice/api/games/transformers';
import { GameApi } from 'pages/backoffice/api/games/types';
import { CreateGameApiParams } from 'pages/backoffice/api/games/use-create-metadata-game/types';
import { useInvalidateMetadataGames } from 'pages/backoffice/api/games/use-metadata-games';
import { annotationApiUrls } from 'pages/backoffice/api/routes';
import { useAllowedBackofficeOrigin } from 'pages/backoffice/api/use-allowed-backoffice-origin';
import { metadataApiErrorParser, MetadataApiErrorResponse } from 'pages/backoffice/api/utils';
import { MetadataGame } from 'pages/backoffice/types/games';

interface RequestData {
  data: CreateGameApiParams;
  onSuccess?: (game: MetadataGame) => void;
}

export const useCreateMetadataGame = () => {
  const { getIsAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();
  const invalidateGames = useInvalidateMetadataGames();
  const mutationRequest = useMutationRequest<GameApi, MetadataGame, MetadataApiErrorResponse>({
    type: HTTPMethod.POST,
    errorMessage: (data) => metadataApiErrorParser(data, 'Game creation error'),
    onSuccess: invalidateGames,
    transformer: transformMetadataGame,
  });

  const create = ({ data, onSuccess }: RequestData) => {
    if (!getIsAllowedBackofficeOrigin()) return;

    mutationRequest.mutate(
      {
        url: annotationApiUrls.GAMES,
        data,
      },
      {
        onSuccess,
      },
    );
  };

  return { ...mutationRequest, create };
};
