import { Button } from '@mui/material';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MultimatchFiltersModal } from 'pages/playlist-detail/components/add-multiple-clips/components/multimatch-filters-modal/MultimatchFiltersModal';
import IconPlus from 'shared/components/icons/icon-plus';

interface Props {
  playlistId: string;
}

export const AddMultipleClips = ({ playlistId }: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const handleOnClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  return (
    <>
      <Button
        variant='contained'
        startIcon={<IconPlus size='small' sx={{ color: 'common.white' }} />}
        onClick={handleOpen}
        size='small'
      >
        {t('common:actions.add-clips')}
      </Button>

      {isOpen ? <MultimatchFiltersModal playlistId={playlistId} isOpen={isOpen} onClose={handleOnClose} /> : null}
    </>
  );
};
