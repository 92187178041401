import { z } from 'zod';

import { CompetitionFormFieldsNames } from 'pages/backoffice/pages/competitions/components/competition-form/types';
import { SeasonCompetitionFormFieldsNames } from 'pages/backoffice/pages/season-competitions/components/season-competition-form/types';

export const seasonCompetitionFormSchema = z.object({
  [SeasonCompetitionFormFieldsNames.id]: z.string().optional(),
  [SeasonCompetitionFormFieldsNames.year]: z.number().min(1970),
  [SeasonCompetitionFormFieldsNames.crossesYear]: z.boolean(),
  [SeasonCompetitionFormFieldsNames.competition]: z.object({
    [CompetitionFormFieldsNames.id]: z.string(),
    [CompetitionFormFieldsNames.name]: z.string().trim().min(2),
    [CompetitionFormFieldsNames.country]: z.string(),
  }),
});
