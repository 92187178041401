import { literal, TypeOf, z } from 'zod';

import {
  FilesBundesligaFieldsNames,
  FilesBundesligaForm,
  filesBundesligaSchema,
  FilesChyronHegoSetOneFieldsNames,
  FilesChyronhegoSetOneForm,
  filesChyronHegoSetOneSchema,
  FilesChyronHegoSetTwoFieldsNames,
  FilesChyronHegoSetTwoForm,
  filesChyronHegoSetTwoSchema,
  FilesHawkeyeFieldsNames,
  FilesHawkeyeForm,
  filesHawkeyeSchema,
  FilesOptaFieldsNames,
  FilesOptaForm,
  filesOptaSchema,
  FilesSecondSpectrumFieldsNames,
  FilesSecondSpectrumSetOneForm,
  filesSecondSpectrumSetOneSchema,
  FilesSecondSpectrumSetTwoForm,
  filesSecondSpectrumSetTwoSchema,
  FilesSignalityFieldsNames,
  FilesSignalityForm,
  filesSignalitySchema,
  SportlogiqTrackingType,
} from 'pages/backoffice/widgets/process-game/ProcessGameForm/types/processGameFormSubforms.types';

export enum ProcessGameEventsProvider {
  KOGNIA = 'kognia',
  OPTA = 'opta',
  BUNDESLIGA = 'bundesliga',
}

export enum ProcessGamePipelineType {
  THIRD_PARTY_TACTICAL = 'third-party-tactical',
  THIRD_PARTY_TACTICAL_TRACKING = 'third-party-tactical-tracking',
}

export enum ProcessGameFieldsNames {
  recordingId = 'recording_id',
  videoPath = 'video_path',
  bucket = 'bucket',
  pipelineType = 'pipeline_type',
  trackingProvider = 'tracking_provider',
  eventsProvider = 'events_provider',
  runProcessing = 'run_processing',
  restartProcessing = 'restart_processing',
  showOverlays = 'show_overlays',
  autoRunTacticalAnalysis = 'auto_run_tactical_analysis',
  sportlogiqTrackingType = 'sportlogiq_tracking_type',
}

export enum ProcessGameTrackingProvider {
  KOGNIA = 'kognia',
  HAWKEYE = 'hawkeye',
  CHYRONHEGO = 'chyronhego',
  SIGNALITY = 'signality',
  SECOND_SPECTRUM = 'second-spectrum',
  SPORTLOGIQ = 'sportlogiq',
}

export interface ProcessGameForm
  extends FilesHawkeyeForm,
    FilesChyronhegoSetOneForm,
    FilesChyronHegoSetTwoForm,
    FilesSecondSpectrumSetOneForm,
    FilesSecondSpectrumSetTwoForm,
    FilesSignalityForm,
    FilesOptaForm,
    FilesBundesligaForm {
  [ProcessGameFieldsNames.recordingId]: string;
  [ProcessGameFieldsNames.videoPath]: string;
  [ProcessGameFieldsNames.bucket]: string;
  [ProcessGameFieldsNames.pipelineType]: ProcessGamePipelineType;
  [ProcessGameFieldsNames.trackingProvider]?: ProcessGameTrackingProvider;
  [ProcessGameFieldsNames.eventsProvider]?: ProcessGameEventsProvider;
  [ProcessGameFieldsNames.runProcessing]: boolean;
  [ProcessGameFieldsNames.restartProcessing]: boolean;
  [ProcessGameFieldsNames.showOverlays]: boolean;
  [ProcessGameFieldsNames.autoRunTacticalAnalysis]: boolean;
  [ProcessGameFieldsNames.sportlogiqTrackingType]: SportlogiqTrackingType;
}

const filesSchema = z.object({
  ...filesHawkeyeSchema.shape,
  ...filesChyronHegoSetOneSchema.shape,
  ...filesChyronHegoSetTwoSchema.shape,
  ...filesSecondSpectrumSetOneSchema.shape,
  ...filesSecondSpectrumSetTwoSchema.shape,
  ...filesSignalitySchema.shape,
  ...filesOptaSchema.shape,
  ...filesBundesligaSchema.shape,
});

export const processGameFormSchema = z.object({
  [ProcessGameFieldsNames.recordingId]: z.string().min(2),
  [ProcessGameFieldsNames.videoPath]: z.string().min(2),
  [ProcessGameFieldsNames.bucket]: z.string(),
  [ProcessGameFieldsNames.pipelineType]: z.nativeEnum(ProcessGamePipelineType),
  [ProcessGameFieldsNames.trackingProvider]: z
    .nativeEnum(ProcessGameTrackingProvider)
    .optional()
    .or(literal('').transform(() => undefined)),
  [ProcessGameFieldsNames.eventsProvider]: z
    .nativeEnum(ProcessGameEventsProvider)
    .optional()
    .or(literal('').transform(() => undefined)),
  [ProcessGameFieldsNames.runProcessing]: z.boolean(),
  [ProcessGameFieldsNames.restartProcessing]: z.boolean(),
  [ProcessGameFieldsNames.showOverlays]: z.boolean(),
  [ProcessGameFieldsNames.autoRunTacticalAnalysis]: z.boolean(),
  [ProcessGameFieldsNames.sportlogiqTrackingType]: z.nativeEnum(SportlogiqTrackingType),
  ...filesSchema.shape,
});

export type ProcessGameFormSchema = TypeOf<typeof processGameFormSchema>;

export const eventsFilesFieldsNames = {
  ...FilesOptaFieldsNames,
  ...FilesBundesligaFieldsNames,
};

export const trackingFilesFieldsNames = {
  ...FilesChyronHegoSetTwoFieldsNames,
  ...FilesChyronHegoSetOneFieldsNames,
  ...FilesHawkeyeFieldsNames,
  ...FilesSecondSpectrumFieldsNames,
  ...FilesSignalityFieldsNames,
};
