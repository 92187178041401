import { zodResolver } from '@hookform/resolvers/zod';
import { Stack } from '@mui/material';
import { forwardRef, useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { useAddUsersToEnvironments } from 'api/backoffice/user/use-add-users-to-environments';
import { KogniaUser } from 'api/backoffice/user/use-kognia-users/types';
import { SelectClients } from 'pages/backoffice/components/select-clients';
import { SelectUsers } from 'pages/backoffice/components/select-users';
import {
  UsersToEnvironmentsForm,
  UsersToEnvironmentsFormFieldsNames,
  usersToEnvironmentsFormSchema,
  UsersToEnvironmentsFormSchema,
} from 'pages/backoffice/utils/users-to-environments-form-schema/usersToEnvironmentsFormSchema';

interface Props {
  onSuccess: () => void;
}

export const AddUsersToEnvironmentsFormFeature = forwardRef(
  ({ onSuccess }: Props, ref: React.LegacyRef<HTMLFormElement> | undefined) => {
    const { addUsersToEnvironments } = useAddUsersToEnvironments();
    const {
      setValue,
      handleSubmit: handleFormSubmit,
      watch,
    } = useForm<UsersToEnvironmentsFormSchema>({
      defaultValues: {
        [UsersToEnvironmentsFormFieldsNames.ENVIRONMENT_IDS]: [],
        [UsersToEnvironmentsFormFieldsNames.KOGNIA_USERS]: [],
      },
      resolver: zodResolver(usersToEnvironmentsFormSchema),
    });

    const { environmentIds: selectedEnvironments, kogniaUsers: selectedKogniaUsers } = watch();

    const setSelectedKogniaUsers = useCallback(
      (clients: KogniaUser[]) => {
        setValue(UsersToEnvironmentsFormFieldsNames.KOGNIA_USERS, clients);
      },
      [setValue],
    );

    const setSelectedEnvironments = useCallback(
      (clientIds: string[]) => {
        setValue(UsersToEnvironmentsFormFieldsNames.ENVIRONMENT_IDS, clientIds);
      },
      [setValue],
    );

    const handleAddUsersToEnvironments = useCallback(
      (data: UsersToEnvironmentsForm) => {
        addUsersToEnvironments({
          clientIds: data[UsersToEnvironmentsFormFieldsNames.ENVIRONMENT_IDS],
          kogniaUsers: data[UsersToEnvironmentsFormFieldsNames.KOGNIA_USERS],
          onSuccess,
        });
      },
      [addUsersToEnvironments, onSuccess],
    );

    return (
      <form ref={ref} onSubmit={handleFormSubmit(handleAddUsersToEnvironments)}>
        <Stack direction='column' spacing={2}>
          <SelectUsers kogniaUsers={selectedKogniaUsers} setKogniaUsersOnChange={setSelectedKogniaUsers} />
          <SelectClients clientIds={selectedEnvironments} onChange={setSelectedEnvironments} />
        </Stack>
      </form>
    );
  },
);

AddUsersToEnvironmentsFormFeature.displayName = 'AddUsersToEnvironmentsFormFeature';
