import { useQueryClient } from '@tanstack/react-query';

import { useInfinityQuery } from 'api/hooks/useInfinityQuery';
import { InfinityQueryResult } from 'api/hooks/useInfinityQuery/types';
import { transformMetadataGamesFromApiResponse } from 'pages/backoffice/api/games/transformers';
import { GamesFilters } from 'pages/backoffice/api/games/types';
import { MetadataGamesApiResponse } from 'pages/backoffice/api/games/use-metadata-games/types';
import { getGamesUrl } from 'pages/backoffice/api/routes';
import { useAllowedBackofficeOrigin } from 'pages/backoffice/api/use-allowed-backoffice-origin';
import { MetadataGame } from 'pages/backoffice/types/games';
import { SortDirection } from 'shared/types/filters/types';

const QUERY_KEY = 'metadata-games';

export const useMetadataGames = (): InfinityQueryResult<MetadataGame, GamesFilters> => {
  const { isAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();

  return useInfinityQuery<MetadataGame, GamesFilters, MetadataGamesApiResponse>({
    generateUrl: getGamesUrl,
    key: QUERY_KEY,
    options: {
      size: 10,
      sort: 'name',
      sortDirection: SortDirection.DESC,
    },
    transformer: transformMetadataGamesFromApiResponse,
    queryOptions: {
      enabled: isAllowedBackofficeOrigin,
    },
  });
};

export const useInvalidateMetadataGames = () => {
  const queryClient = useQueryClient();

  return () => queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
};
