import KogniaLogoSmall from 'shared/assets/kognia-sports-logo-small.svg';
import KogniaLogotype from 'shared/assets/kognia-sports-logotype.svg';
import { Client } from 'shared/types/user/types';

import KogniaFavicon from '../../../public/favicon.ico';

export const getBrandingFromClient = (client: Client) => {
  return client?.branding
    ? client.branding
    : {
        displayName: 'Kognia Sports',
        navbarCollapsedLogoUrl: KogniaLogoSmall,
        navbarLogoUrl: KogniaLogotype,
        emailLogoUrl: KogniaLogotype,
        faviconUrl: KogniaFavicon,
      };
};
