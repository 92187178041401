import { Button } from '@mui/material';
import { MenuItemProps } from '@mui/material/MenuItem/MenuItem';
import { PopoverOrigin } from '@mui/material/Popover/Popover';
import React, { forwardRef, useCallback, useState } from 'react';

import IconVerticalMenu from 'shared/components/icons/icon-vertical-menu';
import { MenuList } from 'shared/components/menu-list';

export type MenuListOption = {
  avoidCloseOnClick?: boolean;
  displayText?: string | JSX.Element;
  icon?: JSX.Element;
  isHidden?: boolean | undefined;
  onClick?: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
  selected?: boolean;
  menuItemProps?: MenuItemProps;
};

type Props = {
  id?: string;
  options?: MenuListOption[];
  triggerComponent?: JSX.Element;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  disableButtonPadding?: boolean;
};

export type Ref = HTMLDivElement;

const defaultTriggerComponent = <IconVerticalMenu size='small' color='secondary' />;

const KebabMenu = forwardRef<Ref, Props>(
  (
    {
      anchorOrigin,
      transformOrigin,
      options = [],
      triggerComponent = defaultTriggerComponent,
      id,
      disableButtonPadding,
    },
    ref,
  ) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const menuId = id ? `kebab-menu-${id}` : 'kebab-menu';

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    };

    const handleClose = useCallback(
      (event: React.SyntheticEvent | React.MouseEvent<Document, MouseEvent> | MouseEvent | TouchEvent) => {
        event && event.stopPropagation();
        if (!anchorEl) return;

        setAnchorEl(null);
      },
      [anchorEl],
    );

    const menuOptions = options.filter((option) => !option.isHidden);

    if (!menuOptions.length) {
      return null;
    }

    return (
      <div ref={ref}>
        <Button
          aria-controls={menuId}
          aria-haspopup='true'
          onClick={handleClick}
          data-testid='kebab-menu-button'
          sx={{ padding: (theme) => (disableButtonPadding ? 0 : theme.spacing(0.5)), minWidth: 'auto' }}
        >
          {triggerComponent}
        </Button>
        <MenuList
          anchorEl={anchorEl}
          anchorOrigin={anchorOrigin}
          id={menuId}
          isOpen={!!anchorEl}
          onClose={handleClose}
          options={menuOptions}
          transformOrigin={transformOrigin}
        />
      </div>
    );
  },
);

KebabMenu.displayName = 'KebabMenu';

export default KebabMenu;
