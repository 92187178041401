import { Grid, Typography } from '@mui/material';
import { generatePath } from 'react-router-dom';

import { routes } from 'kognia/router/routes';
import { EnvironmentItemColumn } from 'pages/backoffice/pages/environments/components/environment-item-column/EnvironmentItemColumn';
import IconEdit from 'shared/components/icons/icon-edit';
import { IconEye } from 'shared/components/icons/icon-eye';
import KebabMenu, { MenuListOption } from 'shared/components/kebab-menu';
import { ListItem } from 'shared/components/list/list-item';
import { useRedirectTo } from 'shared/components/sidebar-layout/sidebar-menu/hooks/use-redirect-to';
import { Client } from 'shared/types/user/types';

interface EnvironmentItemProps {
  environment: Client;
}

export const EnvironmentItem = ({ environment }: EnvironmentItemProps) => {
  const redirectTo = useRedirectTo();
  const route = generatePath(routes.BACKOFFICE_EDIT_ENVIRONMENT, { id: environment.id });
  const hasBranding = environment.branding !== null;

  const options: MenuListOption[] = [
    {
      displayText: 'Show details',
      icon: <IconEye size='small' color='secondary' />,
      onClick: () => redirectTo(generatePath(routes.BACKOFFICE_ENVIRONMENT_DETAIL, { id: environment.id })),
    },
    {
      displayText: 'Edit client',
      icon: <IconEdit size='small' color='secondary' />,
      onClick: () => redirectTo(route),
    },
    {
      displayText: hasBranding ? 'Edit branding' : 'Add branding',
      icon: <IconEdit size='small' color='secondary' />,
      onClick: () =>
        redirectTo(
          generatePath(
            hasBranding ? routes.BACKOFFICE_EDIT_ENVIRONMENT_BRANDING : routes.BACKOFFICE_CREATE_ENVIRONMENT_BRANDING,
            { id: environment.id },
          ),
        ),
    },
  ];

  return (
    <ListItem
      key={environment.id}
      onClick={() => redirectTo(generatePath(routes.BACKOFFICE_ENVIRONMENT_DETAIL, { id: environment.id }))}
    >
      <Grid container>
        <EnvironmentItemColumn item xs={3}>
          <Typography variant='body2'>{environment.name}</Typography>
        </EnvironmentItemColumn>
        <EnvironmentItemColumn item xs={3}>
          <Typography variant='body2'>{environment.id}</Typography>
        </EnvironmentItemColumn>
        <EnvironmentItemColumn item xs={3}>
          <Typography variant='body2'>{environment.city}</Typography>
        </EnvironmentItemColumn>
        <EnvironmentItemColumn item xs={2}>
          <Typography variant='body2'>{environment.country}</Typography>
        </EnvironmentItemColumn>
        <EnvironmentItemColumn item xs={1}>
          <KebabMenu options={options} />
        </EnvironmentItemColumn>
      </Grid>
    </ListItem>
  );
};
