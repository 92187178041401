import { SvgIcon, type SvgIconProps } from 'shared/components/icons/svg-icon/SvgIcon';

const IconBackward5 = (props: Omit<SvgIconProps, 'children'>): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path d='M12 6.49998V2.83331L7.41666 7.41665 12 12V8.33331c3.0342 0 5.5 2.46579 5.5 5.49999s-2.4658 5.5-5.5 5.5-5.50001-2.4658-5.50001-5.5H4.66666c0 4.0517 3.28166 7.3333 7.33334 7.3333s7.3333-3.2816 7.3333-7.3333S16.0517 6.49998 12 6.49998zm-1.2009 8.15832l.2292-1.9892h2.1908v.6509h-1.5583l-.1008.8433c.0275-.0183.0641-.0275.1008-.0458s.0825-.0367.1375-.0459.11-.0275.165-.0366.1192-.0184.1833-.0184c.1925 0 .3575.0275.5042.0917s.275.1467.3758.2567.1834.2475.2292.4125.0825.3483.0825.55c0 .1741-.0275.3391-.0825.495s-.1375.2933-.2475.4125-.2475.22-.4125.2841-.3575.11-.5867.11c-.165 0-.33-.0275-.4858-.0733s-.2933-.1283-.4217-.22-.22-.22-.2933-.3575-.1192-.3025-.1192-.4858h.77c.0184.165.0734.2933.1742.3758s.2292.1375.385.1375c.1008 0 .1833-.0183.2475-.055s.1283-.0917.165-.1558.0733-.1375.1008-.2292.0275-.1833.0275-.2842-.0091-.1925-.0366-.2841-.0642-.1559-.1192-.22-.1192-.11-.1925-.1375-.1742-.0459-.275-.0459c-.0733 0-.1375.0092-.1833.0184s-.1009.0275-.1375.0458-.0734.0458-.11.0642-.0642.055-.0917.0825l-.6142-.1467z' />
    </SvgIcon>
  );
};

export default IconBackward5;
