import { SvgIcon, type SvgIconProps } from 'shared/components/icons/svg-icon/SvgIcon';

const IconClose = (props: Omit<SvgIconProps, 'children'>): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path d='M18.4163 6.876l-1.2925-1.2925-5.1241 5.1242-5.12419-5.1242-1.2925 1.2925 5.12419 5.1242-5.12419 5.1241 1.2925 1.2925 5.12419-5.1241 5.1241 5.1241 1.2925-1.2925-5.1241-5.1241 5.1241-5.1242z' />
    </SvgIcon>
  );
};

export default IconClose;
