import { List } from '@mui/material';

import { TeamItem } from 'pages/backoffice/pages/teams/components/team-item';
import { MetadataTeamWithFixtures } from 'pages/backoffice/types/teams';

interface Props {
  teams: MetadataTeamWithFixtures[];
}

export const TeamsList = ({ teams }: Props) => {
  const teamsItems = teams.map((team) => {
    return <TeamItem key={team.id} team={team} />;
  });

  return <List>{teamsItems}</List>;
};
