import { Button, Grid, Stack, Typography } from '@mui/material';
import { fontSizes, fontWeight } from 'kognia-ui';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

import { useAddManyToPlaylist } from 'api/playlist/useAddManyToPlaylist';
import { invalidatePlaylistsQuery } from 'api/playlist/useFetchPlaylists';
import { TaggingEvent } from 'api/tagging-tool/types';
import { useFetchTaggingEvents } from 'api/tagging-tool/use-fetch-tagging-events';
// eslint-disable-next-line import/no-restricted-paths
import { Clip } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/types/clip';
// eslint-disable-next-line import/no-restricted-paths
import AddToPlaylistMenu from 'pages/tactical-analysis/components/tactical-analysis/add-to-playlist-menu';
import {
  useAddMultiplePlaylistItems,
  usePlaylistMenuItems,
  useRemoveMultiplePlaylistMenuItem,
  // eslint-disable-next-line import/no-restricted-paths
} from 'pages/tactical-analysis/components/tactical-analysis/add-to-playlist-menu/add-to-playlist-menu-state/hooks';
import { ClipPlayerDialog } from 'pages/tagging-tool-tag-recording/components/clip-player-dialog';
import { ClipTrimmer } from 'pages/tagging-tool-tag-recording/components/clip-trimmer';
import { KeypadsPanel } from 'pages/tagging-tool-tag-recording/components/keypads-panel';
import { TagFiltersModal } from 'pages/tagging-tool-tag-recording/components/tag-filters-dialog';
import { TagRecordingActions } from 'pages/tagging-tool-tag-recording/components/tag-recording-actions';
import { TagRecordingFilters } from 'pages/tagging-tool-tag-recording/components/tag-recording-filters';
import { useCreateTaggingEventFromCurrentVideo } from 'pages/tagging-tool-tag-recording/components/tag-recording-screen/hooks/use-create-tagging-event-from-current-video';
import { TagFilterBar, TagsList } from 'pages/tagging-tool-tag-recording/components/tag-recording-screen/styled';
import { TaggingEventsList } from 'pages/tagging-tool-tag-recording/components/tagging-events-list';
import { useKeypads } from 'pages/tagging-tool-tag-recording/hooks/use-keypads';
import { CheckboxWithCustomColor } from 'shared/components/CheckboxWithCustomColor/CheckboxWithCustomColor';
import { IconTimeline } from 'shared/components/icons';
import IconPlaylist from 'shared/components/icons/icon-playlist';
import { PlaylistMenuVerticalPosition } from 'shared/components/select-playlist-dialog/SelectPlaylistDialog';
import { useCurrentPlaylistItem, useVideoPlayerActions } from 'shared/components/video-player';
import VideoPlayerComponent from 'shared/components/video-player/video-player-component';
import { defaultVideoAspectRatio } from 'shared/components/video-player/video-player-component/hooks/use-video-aspect-ratio';
import { useMapClipsToPlaylistItems } from 'shared/hooks/use-map-clips-to-post-playlist-items/useMapClipsToPlaylistItems';
import { SortDirection } from 'shared/types/filters/types';
import { FundamentalsSelection, NewPlaylistItemWithId } from 'shared/types/playlist/types';
import { makePlaylistIndexRoute, makeTimelineRoute } from 'tagging-tool/utility/navigation';

type TagRecordingScreenProps = {
  recordingId: string;
  isLive: boolean;
};

export const TagRecordingScreen = ({ recordingId, isLive }: TagRecordingScreenProps) => {
  const [sortOrder, setSortOrder] = useState<SortDirection>(SortDirection.ASC);

  const {
    addTaggingEvent,
    appliedFilters,
    data,
    filterOptions,
    hasFiltersApplied,
    removeTaggingEvent,
    updateTaggingEvent,
    addMultipleTaggingEvents,
    resetFilters,
    setFilters,
    taggingEvents,
  } = useFetchTaggingEvents(recordingId, {
    isLive,
    sortOrder: sortOrder === SortDirection.ASC ? SortDirection.ASC : SortDirection.DESC,
  });
  const reset = useRemoveMultiplePlaylistMenuItem();
  const { addManyToPlaylist } = useAddManyToPlaylist();
  const mapClipsToPlaylistItems = useMapClipsToPlaylistItems({ recordingId });

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { search } = useLocation();
  const actions = useVideoPlayerActions();
  const [playingTaggingEvent, setPlayingTaggingEvent] = useState<TaggingEvent | null>(null);
  const selectedPlaylistItems = usePlaylistMenuItems();
  const removeMultiplePlaylistItems = useRemoveMultiplePlaylistMenuItem();
  const addMultiplePlaylistItems = useAddMultiplePlaylistItems();
  const playlistItem = useCurrentPlaylistItem();
  const { createTaggingEventFromCurrentVideo } = useCreateTaggingEventFromCurrentVideo({
    onSuccess: (event: TaggingEvent) => addTaggingEvent(event),
    isLive,
    recordingId,
  });

  const [{ keypadsData, loadingKeypad, keypadData }, { handleChangeKeypad }] = useKeypads({
    recordingId,
    isLive,
  });

  const handlePlayClip = useCallback(
    (taggingEvent: TaggingEvent) => {
      actions.handleStandBy();
      setPlayingTaggingEvent(taggingEvent);
    },
    [actions],
  );

  const handleCancelClipPlayer = useCallback(() => {
    actions.resumeStandBy();
    setPlayingTaggingEvent(null);
  }, [actions]);

  const [showsKeypad, setShowsKeypad] = useState<boolean>(() => {
    if (search) {
      const qs = new URLSearchParams(search);
      return qs.has('showsKeypad');
    }

    return true;
  });

  const [showsFiltersModal, setShowsFiltersModal] = useState<boolean>(false);
  const [trimEvent, setTrimEvent] = useState<TaggingEvent>();
  const [importing, setImporting] = useState<boolean>(false);
  const [isMultiselectModeActive, setIsMultiselectModeActive] = useState<boolean>(false);

  // handlers
  const handleCloseKeypads = useCallback(() => setShowsKeypad(false), []);
  const handleShowFiltersModal = useCallback(() => setShowsFiltersModal(true), []);
  const handleStartTrim = useCallback((event: TaggingEvent) => setTrimEvent(event), []);
  const handleEndTrim = useCallback(() => setTrimEvent(undefined), []);
  const handleHideFiltersModal = useCallback(
    (reset?: boolean) => {
      if (reset === true) {
        resetFilters();
      }
      setShowsFiltersModal(false);
    },
    [resetFilters],
  );

  const handleSaveTrim = useCallback(
    (event: TaggingEvent) => {
      updateTaggingEvent(event);
      setTrimEvent(undefined);
    },
    [updateTaggingEvent],
  );

  const handleSortChange = (newOrder: SortDirection) => {
    setSortOrder(newOrder);
  };

  const handlePlaylist = useCallback(() => {
    navigate(makePlaylistIndexRoute({ recordingId: recordingId }));
  }, [navigate, recordingId]);

  const handleTimeline = useCallback(() => {
    navigate(makeTimelineRoute({ recordingId: recordingId }));
  }, [navigate, recordingId]);

  const countSelectedClips = () =>
    taggingEvents.filter((clip) => selectedPlaylistItems.map((item) => item.id).includes(clip.id)).length;

  const handleOnMultiselectChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    checked
      ? addMultiplePlaylistItems(
          taggingEvents
            .filter((clip) => !selectedPlaylistItems.map((item) => item.id).includes(clip.id))
            .map((selectedClip) => {
              return {
                id: selectedClip.id,
                endTime: selectedClip.time + selectedClip.timeAfter,
                startTime:
                  selectedClip.time - selectedClip.timeBefore > 0 ? selectedClip.time - selectedClip.timeBefore : 0,
                playlistId: '',
                name: selectedClip.name,
              } as NewPlaylistItemWithId;
            }),
        )
      : removeMultiplePlaylistItems(
          taggingEvents
            .filter((clip) => selectedPlaylistItems.map((item) => item.id).includes(clip.id))
            .map((clip) => clip.id),
        );
  };

  const handleShowKeypad = useCallback(() => {
    setIsMultiselectModeActive(false);
    setShowsKeypad(!showsKeypad);
  }, [showsKeypad]);

  const handleShowMultiselectMode = useCallback(() => {
    setShowsKeypad(false);
    setIsMultiselectModeActive(!isMultiselectModeActive);
  }, [isMultiselectModeActive]);

  const handleSave = useCallback(
    ({
      items,
      playlistId,
      fundamentalsSelected,
    }: {
      items: Clip[];
      playlistId: string;
      fundamentalsSelected: FundamentalsSelection;
    }) => {
      addManyToPlaylist({
        items: mapClipsToPlaylistItems({ items, playlistId, fundamentalsSelected }),
        options: {
          onSuccess: async () => {
            await invalidatePlaylistsQuery();
            reset(selectedPlaylistItems.map((clip) => clip.id));
          },
        },
      });
    },
    [addManyToPlaylist, mapClipsToPlaylistItems, reset, selectedPlaylistItems],
  );
  const showSidePanel = showsKeypad || isMultiselectModeActive;

  if (!taggingEvents) return null;

  return (
    <>
      <Grid container spacing={2}>
        {/* left */}
        <Grid item xs={12} sm={12} md={showSidePanel ? 6 : 12} lg={showSidePanel ? 7 : 12} xl={showSidePanel ? 7 : 12}>
          {!importing && data?.matchVideoSource?.src && (
            <Grid container direction={'row'} justifyContent={'flex-start'}>
              <Grid item xs={12}>
                <Stack direction='row' spacing={1} justifyContent='flex-end' mb={2}>
                  <Button
                    variant='outlined'
                    color='secondary'
                    startIcon={<IconPlaylist size='small' color='secondary' />}
                    onClick={handlePlaylist}
                  >
                    {t('tagging-tool:tagging-recording.action-playlist')}
                  </Button>

                  <Button
                    variant='outlined'
                    color='secondary'
                    onClick={handleTimeline}
                    startIcon={<IconTimeline size='small' color='secondary' />}
                  >
                    {t('tagging-tool:tagging-recording.action-timeline')}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          )}
          <Grid container direction={'row'} justifyContent={'flex-start'}>
            {/* player */}
            {data?.matchVideoSource?.src && (
              <Grid item xs={12} p={0}>
                <div style={{ aspectRatio: defaultVideoAspectRatio }}>
                  <VideoPlayerComponent showVideoPlayerBar={true} showTacticDrawings={false} />
                </div>
              </Grid>
            )}
            {trimEvent !== undefined && (
              <Grid item xs={12}>
                <ClipTrimmer event={trimEvent} onSave={handleSaveTrim} onCancel={handleEndTrim} />
              </Grid>
            )}
          </Grid>
          <TagFilterBar container direction={'row'} justifyContent={'flex-start'} hidden={trimEvent !== undefined}>
            <Grid item container xs={12} justifyContent={'space-between'} alignItems={'center'}>
              <Grid item>
                <TagRecordingFilters
                  handleSortChange={handleSortChange}
                  filtering={hasFiltersApplied}
                  handleShowFiltersModal={handleShowFiltersModal}
                />
              </Grid>
              <Grid item>
                <TagRecordingActions
                  handleImportEvents={addMultipleTaggingEvents}
                  importing={importing}
                  isMultiselectModeActive={isMultiselectModeActive}
                  orderedTaggingEvents={taggingEvents}
                  recordingId={recordingId}
                  isLive={isLive}
                  setImporting={setImporting}
                  setIsMultiselectModeActive={handleShowMultiselectMode}
                  setShowsKeypad={handleShowKeypad}
                  showsKeypad={showsKeypad}
                  videoSrc={data?.matchVideoSource?.src}
                />
              </Grid>
              {isMultiselectModeActive && (
                <Grid item xs={12} container direction={'row'} justifyContent={'flex-start'} paddingLeft={2}>
                  <Grid item xs={12} display='flex' alignItems='center' gap={1}>
                    <CheckboxWithCustomColor
                      customColor='typography'
                      checked={countSelectedClips() === taggingEvents.length}
                      indeterminate={countSelectedClips() < (taggingEvents.length || 0) && countSelectedClips() > 0}
                      onChange={handleOnMultiselectChange}
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) => event.stopPropagation()}
                    />
                    <Typography fontWeight={fontWeight['500']} fontSize={fontSizes.small}>
                      {`${t('tagging-tool:tagging-recording.all-clips')} (${countSelectedClips()})`}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <TagsList container direction={'row'} justifyContent={'flex-start'} hidden={trimEvent !== undefined}>
              {/** events */}
              <Grid item xs={12}>
                <TaggingEventsList
                  hasVideo={Boolean(data?.matchVideoSource?.src)}
                  onPlayClip={handlePlayClip}
                  data={taggingEvents}
                  onDelete={removeTaggingEvent}
                  onRename={updateTaggingEvent}
                  onTrim={handleStartTrim}
                  isMultiselectModeActive={isMultiselectModeActive}
                />
              </Grid>
            </TagsList>
          </TagFilterBar>
        </Grid>
        {/* right: keypad */}
        <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
          {keypadData && showsKeypad && (
            <KeypadsPanel
              disabled={trimEvent !== undefined}
              keypad={keypadData}
              keypads={keypadsData ?? []}
              loadingKeypad={loadingKeypad}
              onChangeKeypad={handleChangeKeypad}
              onClose={handleCloseKeypads}
              onKeypadTag={createTaggingEventFromCurrentVideo}
              videoStartRecordingTime={data?.startRecordingTime}
            />
          )}
          {isMultiselectModeActive && (
            <AddToPlaylistMenu
              isOpen={isMultiselectModeActive}
              onClose={() => setIsMultiselectModeActive(false)}
              recordingId={recordingId}
              verticalPosition={PlaylistMenuVerticalPosition.Top}
              onSave={handleSave}
            />
          )}
        </Grid>
      </Grid>
      {playingTaggingEvent && data?.matchVideoSource?.src ? (
        <ClipPlayerDialog
          videoTypes={playlistItem.videoTypes}
          data={{ src: data?.matchVideoSource?.src, id: playingTaggingEvent.id, event: playingTaggingEvent }}
          onCancel={handleCancelClipPlayer}
        />
      ) : null}

      <TagFiltersModal
        appliedFilters={appliedFilters}
        applyFilters={setFilters}
        filterOptions={filterOptions}
        onClose={handleHideFiltersModal}
        resetFilters={resetFilters}
        show={showsFiltersModal}
      />
    </>
  );
};
