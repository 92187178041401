import { Box } from '@mui/material';
import { Suspense } from 'react';
import { Navigate, Outlet, RouteObject } from 'react-router-dom';

import { ErrorContainerFeature } from 'features/error-container/ErrorContainer.feature';
import { routes } from 'kognia/router/routes';
import { BackofficeProtectedRoute } from 'pages/backoffice/components/backoffice-protected-route';
import { BackofficeSidebarLayout } from 'pages/backoffice/components/backoffice-sidebar-layout';
import { Annotation } from 'pages/backoffice/pages/annotation/Annotation';
import { SeasonClients } from 'pages/backoffice/pages/associate-season-with-clients';
import { Coaches } from 'pages/backoffice/pages/coaches';
import { Competitions } from 'pages/backoffice/pages/competitions';
import { CreateEnvironmentPage } from 'pages/backoffice/pages/create-environment/CreateEnvironment.page';
import { CreateEnvironmentBranding } from 'pages/backoffice/pages/create-environment-branding/CreateEnvironmentBranding.page';
import { EditEnvironment } from 'pages/backoffice/pages/edit-environment/EditEnvironment.page';
import { EditEnvironmentBranding } from 'pages/backoffice/pages/edit-environment-branding/EditEnvironmentBranding.page';
import { EnvironmentDetail } from 'pages/backoffice/pages/environment-detail/EnvironmentDetail.page';
import { Environments } from 'pages/backoffice/pages/environments/Environments.page';
import { Games } from 'pages/backoffice/pages/games';
import { EditGame } from 'pages/backoffice/pages/games/edit-game';
import { GameForm } from 'pages/backoffice/pages/games/game-form';
import { GameFormInfo } from 'pages/backoffice/pages/games/game-form/steps/game-info-form';
import { GameSummary } from 'pages/backoffice/pages/games/game-form/steps/game-summary';
import { HomeTeamForm } from 'pages/backoffice/pages/games/game-form/steps/home-team-form';
import { OpponentTeamForm } from 'pages/backoffice/pages/games/game-form/steps/opponent-team-form';
import { Players } from 'pages/backoffice/pages/players';
import { ProcessGamePage } from 'pages/backoffice/pages/process-game/ProcessGame.page';
import { SeasonCompetitions } from 'pages/backoffice/pages/season-competitions';
import { Stages } from 'pages/backoffice/pages/stages';
import { Teams } from 'pages/backoffice/pages/teams';
import { UsersPage } from 'pages/backoffice/pages/users/Users.page';
import { Venues } from 'pages/backoffice/pages/venues';
import { AccessProtected } from 'shared/components/access-protected/AccessProtected';
import { ProtectedRoute } from 'shared/components/protected-route';

export const backofficeRoutes: RouteObject[] = [
  {
    path: routes.BACKOFFICE,
    element: (
      <ProtectedRoute>
        <AccessProtected onlyAdminAccess>
          <BackofficeSidebarLayout>
            <Box sx={{ flex: 1 }}>
              <Outlet />
            </Box>
          </BackofficeSidebarLayout>
        </AccessProtected>
      </ProtectedRoute>
    ),
    errorElement: (
      <BackofficeSidebarLayout>
        <Suspense>
          <ErrorContainerFeature />
        </Suspense>
      </BackofficeSidebarLayout>
    ),
    children: [
      {
        index: true,
        element: (
          <BackofficeProtectedRoute>
            <Navigate to={routes.BACKOFFICE_GAMES} />
          </BackofficeProtectedRoute>
        ),
      },
      {
        path: routes.BACKOFFICE_USERS,
        element: <UsersPage />,
      },
      {
        path: routes.BACKOFFICE_ENVIRONMENTS,
        element: <Environments />,
      },
      {
        path: routes.BACKOFFICE_ENVIRONMENT_DETAIL,
        element: <EnvironmentDetail />,
      },
      {
        path: routes.BACKOFFICE_CREATE_ENVIRONMENT,
        element: <CreateEnvironmentPage />,
      },
      {
        path: routes.BACKOFFICE_EDIT_ENVIRONMENT,
        element: <EditEnvironment />,
      },
      {
        path: routes.BACKOFFICE_CREATE_ENVIRONMENT_BRANDING,
        element: <CreateEnvironmentBranding />,
      },
      {
        path: routes.BACKOFFICE_EDIT_ENVIRONMENT_BRANDING,
        element: <EditEnvironmentBranding />,
      },
      {
        path: routes.BACKOFFICE_ASSOCIATE_COMPETITION_WITH_CLIENTS,
        element: (
          <BackofficeProtectedRoute>
            <SeasonClients />
          </BackofficeProtectedRoute>
        ),
      },
      {
        path: routes.BACKOFFICE_COMPETITIONS,
        element: (
          <BackofficeProtectedRoute>
            <Competitions />
          </BackofficeProtectedRoute>
        ),
      },
      {
        path: routes.BACKOFFICE_COACHES,
        element: (
          <BackofficeProtectedRoute>
            <Coaches />
          </BackofficeProtectedRoute>
        ),
      },
      {
        path: routes.BACKOFFICE_EDIT_GAME_FORM,
        element: (
          <BackofficeProtectedRoute>
            <EditGame />
          </BackofficeProtectedRoute>
        ),
      },
      {
        path: '/kognia-backoffice/game-form',
        element: (
          <BackofficeProtectedRoute>
            <GameForm>
              <Outlet />
            </GameForm>
          </BackofficeProtectedRoute>
        ),
        children: [
          {
            path: routes.BACKOFFICE_GAME_FORM_STEP_1,
            element: <HomeTeamForm />,
          },
          {
            path: routes.BACKOFFICE_GAME_FORM_STEP_2,
            element: <OpponentTeamForm />,
          },
          {
            path: routes.BACKOFFICE_GAME_FORM_STEP_3,
            element: <GameFormInfo />,
          },
          {
            path: routes.BACKOFFICE_NEW_GAME_SUMMARY,
            element: <GameSummary />,
          },
        ],
      },
      {
        path: routes.BACKOFFICE_GAME_SUMMARY,
        element: (
          <BackofficeProtectedRoute>
            <GameSummary />
          </BackofficeProtectedRoute>
        ),
      },
      {
        path: routes.BACKOFFICE_SEASON_COMPETITIONS,
        element: (
          <BackofficeProtectedRoute>
            <SeasonCompetitions />
          </BackofficeProtectedRoute>
        ),
      },
      {
        path: routes.BACKOFFICE_VENUES,
        element: (
          <BackofficeProtectedRoute>
            <Venues />
          </BackofficeProtectedRoute>
        ),
      },
      {
        path: routes.BACKOFFICE_STAGES,
        element: (
          <BackofficeProtectedRoute>
            <Stages />
          </BackofficeProtectedRoute>
        ),
      },
      {
        path: routes.BACKOFFICE_PLAYERS,
        element: (
          <BackofficeProtectedRoute>
            <Players />
          </BackofficeProtectedRoute>
        ),
      },
      {
        path: routes.BACKOFFICE_TEAMS,
        element: (
          <BackofficeProtectedRoute>
            <Teams />
          </BackofficeProtectedRoute>
        ),
      },
      {
        path: routes.PROCESS_GAME,
        element: (
          <BackofficeProtectedRoute>
            <ProcessGamePage />
          </BackofficeProtectedRoute>
        ),
      },
      {
        path: routes.BACKOFFICE_ANNOTATION,
        element: (
          <BackofficeProtectedRoute>
            <Annotation />
          </BackofficeProtectedRoute>
        ),
      },
      {
        path: routes.BACKOFFICE_GAMES,
        element: (
          <BackofficeProtectedRoute>
            <Games />
          </BackofficeProtectedRoute>
        ),
      },
    ],
  },
];
