import {
  VITE_SUPERSET_URL,
  VITE_SUPERSET_PLAYER_PROFILE_DASHBOARD_ID,
  VITE_SUPERSET_PLAYER_PROFILE_EXPLORE_DASHBOARD_ID,
} from 'shared/utils/env-variables';

const SENTRY_DSN_URL: string = window.SENTRY_DSN_URL || '';
const GRAFANA_FARO_COLECTOR_URL: string = window.GRAFANA_FARO_COLECTOR_URL || '';
const SUPERSET_URL: string = window.SUPERSET_URL || (VITE_SUPERSET_URL ?? 'http://localhost:8088');
const SUPERSET_PLAYER_PROFILE_DASHBOARD_ID: string =
  window.SUPERSET_PLAYER_PROFILE_DASHBOARD_ID || (VITE_SUPERSET_PLAYER_PROFILE_DASHBOARD_ID ?? '');
const SUPERSET_PLAYER_PROFILE_EXPLORE_DASHBOARD_ID: string =
  window.SUPERSET_PLAYER_PROFILE_EXPLORE_DASHBOARD_ID || (VITE_SUPERSET_PLAYER_PROFILE_EXPLORE_DASHBOARD_ID ?? '');

export {
  SENTRY_DSN_URL,
  GRAFANA_FARO_COLECTOR_URL,
  SUPERSET_URL,
  SUPERSET_PLAYER_PROFILE_DASHBOARD_ID,
  SUPERSET_PLAYER_PROFILE_EXPLORE_DASHBOARD_ID,
};
