import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { HTTPMethod } from 'api/types';
import { annotationApiUrls } from 'pages/backoffice/api/routes';
import { useAllowedBackofficeOrigin } from 'pages/backoffice/api/use-allowed-backoffice-origin';
import { metadataApiErrorParser, MetadataApiErrorResponse } from 'pages/backoffice/api/utils';
import {
  ProcessGameEventsProvider,
  ProcessGamePipelineType,
  ProcessGameTrackingProvider,
} from 'pages/backoffice/widgets/process-game/ProcessGameForm/types/processGameForm.types';

export type ProcessGameParamsDataSettings = {
  providers: {
    video: 'third-party-tactical';
    tracking?: ProcessGameTrackingProvider;
    events?: ProcessGameEventsProvider;
    options?: {
      sportlogiq_tracking_type?: string;
    };
  };
  run_processing: boolean;
  postprocess: {
    show_overlays: boolean;
    auto_run_tactical_analysis: boolean;
  };
};

export interface ProcessGameParamsData {
  recording_id: string;
  video_path: string;
  bucket: string;
  pipeline_type: ProcessGamePipelineType;
  settings: ProcessGameParamsDataSettings;
  restart_processing: boolean;
  files?: unknown;
}

interface ProcessGameParams {
  data: ProcessGameParamsData;
}

export const useProcessGame = () => {
  const { getIsAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();
  const mutationRequest = useMutationRequest<unknown, unknown, MetadataApiErrorResponse>({
    type: HTTPMethod.POST,
    successMessage: 'Process game success',
    errorMessage: (data) => metadataApiErrorParser(data, 'Process game error'),
  });

  const processGame = ({ data }: ProcessGameParams) => {
    if (!getIsAllowedBackofficeOrigin()) return;

    mutationRequest.mutate({ url: `${annotationApiUrls.PROCESS_GAME}`, data });
  };

  return { ...mutationRequest, processGame };
};
