import { Box, Popper, Stack, TextField, MenuList, Typography, IconButton } from '@mui/material';
import { ChangeEvent, useCallback, useRef, useState, UIEvent, FocusEvent, KeyboardEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { PlayerSearchOption } from 'features/player-profile/player-search/ui/player-search-input/ui/PlayerSearchOption';
import { PlayerSearchPaper } from 'features/player-profile/player-search/ui/player-search-input/ui/PlayerSearchPaper';
import IconClose from 'shared/components/icons/icon-close';
import IconSearch from 'shared/components/icons/icon-search';
import Spinner from 'shared/components/spinner';
import { useDebounce } from 'shared/hooks/use-debounce/useDebounce';

export const MINIMAL_SEARCH_LENGTH = 3;
const DEFAULT_INPUT_WIDTH = 400;
const MIN_INPUT_WIDTH = 304;
const DEFAULT_RESULTS_HEIGHT = 304;
const CONTAINER_SCROLL_PADDING = 8;
const MENU_ID = 'explore-menu';
const EXPLORE_SEARCH_ID = 'explore-search';
const DEFAULT_SELECTED_INDEX = -1;

export type PlayerSearchData = { value: string; label: string; photoUrl?: string | null | undefined };

interface Props<T extends PlayerSearchData> {
  pastRequestsOptions: readonly T[];
  onSearch: (searchValue: string) => void; // NOTE: use memo function
  onSelect: (data: PlayerSearchData) => void;
  fetchNextPage: () => void;
  width?: number | `${number}${'px' | 'rem' | 'em' | '%' | 'vw'}` | 'auto';
  size?: 'medium' | 'small';
  placeholder?: string;
  options: readonly T[];
  isLoading: boolean;
}

export const PlayerSearchInput = <T extends PlayerSearchData>({
  options,
  pastRequestsOptions,
  width,
  placeholder,
  onSearch,
  isLoading,
  onSelect,
  size,
  fetchNextPage,
}: Props<T>) => {
  const { t } = useTranslation('explore');
  const [searchValue, setSearchValue] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLInputElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(DEFAULT_SELECTED_INDEX);
  const inputRef = useRef<HTMLInputElement>(null);
  const menuListRef = useRef<HTMLUListElement>(null);

  const open = Boolean(anchorEl?.parentElement);
  const isShowPastRequestsOptions = searchValue.length < MINIMAL_SEARCH_LENGTH;
  const currentOptions = isShowPastRequestsOptions ? pastRequestsOptions : options;
  const hasPastRequestOptions = pastRequestsOptions.length > 0;

  useEffect(() => {
    const menuItems =
      menuListRef.current?.children &&
      Array.from(menuListRef.current.children).filter((child) => child.tagName === 'LI');
    if (menuItems && menuItems[selectedIndex]) {
      menuItems[selectedIndex].scrollIntoView({ block: 'end', inline: 'nearest' });
    }
  }, [selectedIndex]);

  const debouncedSearchCallback = useDebounce((value: string) => {
    onSearch(value);
    setSelectedIndex(DEFAULT_SELECTED_INDEX);
  });

  const handleCloseResults = useCallback(() => {
    setAnchorEl(null);
    setSelectedIndex(DEFAULT_SELECTED_INDEX);
  }, []);

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setSearchValue(value);
      if (value.length >= MINIMAL_SEARCH_LENGTH) {
        debouncedSearchCallback(value);
      } else {
        debouncedSearchCallback('');
      }

      setAnchorEl(
        value.length >= MINIMAL_SEARCH_LENGTH
          ? event.currentTarget
          : hasPastRequestOptions
            ? event.currentTarget
            : null,
      );
    },
    [debouncedSearchCallback, hasPastRequestOptions],
  );

  const handleReset = useCallback(() => {
    setSearchValue('');
    setSelectedIndex(DEFAULT_SELECTED_INDEX);
    inputRef.current?.focus();
  }, []);

  const handleBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      if (!event.relatedTarget) {
        handleCloseResults();
      }
    },
    [handleCloseResults],
  );

  const handleFocus = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      if (hasPastRequestOptions) {
        setAnchorEl(event.currentTarget);
      }
    },
    [hasPastRequestOptions],
  );

  const handleOnScroll = useCallback(
    (event: UIEvent<HTMLDivElement>) => {
      const target = event.currentTarget;
      const isScrollBottom = target.offsetHeight + target.scrollTop + CONTAINER_SCROLL_PADDING >= target.scrollHeight;

      if (isScrollBottom && !isShowPastRequestsOptions && !isLoading) {
        fetchNextPage();
      }
    },
    [fetchNextPage, isLoading, isShowPastRequestsOptions],
  );

  const handleSelect = useCallback(
    (option: T) => {
      onSelect(option);
      setSearchValue('');
      handleCloseResults();
    },
    [handleCloseResults, onSelect],
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (!open && !(event.key === 'ArrowDown' || event.key === 'ArrowUp')) return;

      switch (event.key) {
        case 'ArrowDown':
          event.preventDefault();
          if (!currentOptions.length) return;
          if (!open && hasPastRequestOptions) {
            setAnchorEl(inputRef.current);
          }
          setSelectedIndex((prevIndex) => (prevIndex + 1) % currentOptions.length);
          break;
        case 'ArrowUp':
          event.preventDefault();
          if (!currentOptions.length) return;
          if (!open && hasPastRequestOptions) {
            setAnchorEl(inputRef.current);
          }
          setSelectedIndex((prevIndex) => (prevIndex <= 0 ? currentOptions.length - 1 : prevIndex - 1));
          break;
        case 'Escape':
          if (!open) return;
          handleCloseResults();
          break;
        case 'Enter':
          if (!currentOptions.length) return;
          if (selectedIndex !== DEFAULT_SELECTED_INDEX) {
            handleSelect(currentOptions[selectedIndex]);
          }
          break;
        default:
          break;
      }
    },
    [currentOptions, handleCloseResults, handleSelect, hasPastRequestOptions, open, selectedIndex],
  );

  const renderOptions = currentOptions.map((option) => (
    <PlayerSearchOption
      key={option.value}
      photoUrl={option.photoUrl}
      label={option.label}
      onClick={() => handleSelect(option)}
      selected={selectedIndex === currentOptions.indexOf(option)}
    />
  ));

  return (
    <Box sx={{ maxWidth: width ?? DEFAULT_INPUT_WIDTH, minWidth: MIN_INPUT_WIDTH, width: '100%' }}>
      <TextField
        size={'small'}
        placeholder={placeholder}
        fullWidth
        onChange={handleInputChange}
        value={searchValue}
        inputRef={inputRef}
        onKeyDown={handleKeyDown}
        sx={{
          paddingRight: 0,
          height: size === 'small' ? 34 : 40,
          '& .MuiInputBase-root': {
            height: '100%',
          },
        }}
        inputProps={{
          'aria-controls': open ? MENU_ID : undefined,
          'aria-haspopup': 'true',
          'aria-expanded': open ? 'true' : undefined,
          id: EXPLORE_SEARCH_ID,
        }}
        autoComplete={'off'}
        InputProps={{
          endAdornment: searchValue ? (
            <IconButton size={'small'} onClick={handleReset}>
              <IconClose size={'small'} />
            </IconButton>
          ) : (
            <Box padding={'5px'}>
              <IconSearch size='small' color='secondary' />
            </Box>
          ),
          sx: { paddingRight: 0.5 },
        }}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      <Popper open={open} anchorEl={anchorEl} placement='bottom-start'>
        <PlayerSearchPaper
          sx={{
            maxHeight: DEFAULT_RESULTS_HEIGHT,
            overflowY: 'auto',
            width: anchorEl?.parentElement?.offsetWidth ?? 0,
          }}
          onScroll={handleOnScroll}
        >
          {isLoading && !isShowPastRequestsOptions ? (
            <Stack flex={1} alignItems={'center'}>
              <Spinner />
            </Stack>
          ) : currentOptions.length ? (
            <MenuList
              id={MENU_ID}
              aria-labelledby={EXPLORE_SEARCH_ID}
              sx={{ pb: 0 }}
              ref={menuListRef}
              subheader={
                <Typography variant='caption' pl={2} fontWeight='fontWeightMedium'>
                  {isShowPastRequestsOptions ? t('explore:input.recent-searches') : t('explore:input.search-results')}
                </Typography>
              }
            >
              {renderOptions}
            </MenuList>
          ) : null}

          {!isLoading && !currentOptions.length && !isShowPastRequestsOptions && (
            <Typography variant='caption' pl={2} fontWeight='fontWeightMedium'>
              {t('explore:input.no-results-found')}
            </Typography>
          )}
        </PlayerSearchPaper>
      </Popper>
    </Box>
  );
};
