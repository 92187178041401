import { SvgIcon, type SvgIconProps } from 'shared/components/icons/svg-icon/SvgIcon';

const IconDone = (props: Omit<SvgIconProps, 'children'>): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path d='M8.98798 15.6705l-3.95302-3.953-1.31767 1.3177 5.27069 5.2707L20.2823 7.01152l-1.3176-1.31767-9.97672 9.97665z' />
    </SvgIcon>
  );
};

export default IconDone;
