import { Avatar, styled } from '@mui/material';

import { PlayerPhotoSizes } from 'shared/components/player-photo/PlayerPhoto';

interface PlayerImageProps {
  size: PlayerPhotoSizes;
  withBorder?: boolean;
}

const getSize = (size: PlayerPhotoSizes) => {
  if (size === 'small') return '36px';
  if (size === 'medium') return '48px';
  if (size === 'large') return '64px';
  if (size === 'xlarge') return '88px';
};

const getBorderSize = (size: PlayerPhotoSizes) => {
  if (size === 'small') return 2;
  if (size === 'medium') return 3;
  if (size === 'large') return 4;
  if (size === 'xlarge') return 4;

  return 1;
};

export const PlayerImageElement = styled(Avatar, {
  shouldForwardProp: (prop) => prop !== 'size' && prop !== 'withBorder',
})<PlayerImageProps>(({ theme, size, withBorder = false }) => ({
  width: getSize(size),
  height: getSize(size),
  border: withBorder ? `${getBorderSize(size)}px solid ${theme.palette.info.main}` : 'none',
}));
