import { ClickAwayListener, IconButton, Stack } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import InputTextField from 'shared/components/editable-text/input-text-field';
import IconEdit from 'shared/components/icons/icon-edit';
import { PlaylistTitle } from 'shared/components/PlaylistTitle';
import Spinner from 'shared/components/spinner';

type UpdateTextProps = {
  canEdit?: boolean;
  isSubmitting?: boolean;
  onSubmit: (newText: string) => void;
  originalText: string;
};

export const EditableText = ({ canEdit = true, isSubmitting = false, onSubmit, originalText }: UpdateTextProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [newText, setNewText] = useState<string>(originalText);

  useEffect(() => {
    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.code === 'Escape') {
        setNewText(originalText);
        setIsEditing(false);
      }

      return false;
    };
    // TODO add tests to cover the space play/pause functionality
    document.body.addEventListener('keyup', handleKeyUp);

    return () => {
      document.body.removeEventListener('keyup', handleKeyUp);
    };
  }, [originalText]);

  const handleOnSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    onSubmit(newText);
    setIsEditing(false);
  };

  const onClickOutside = () => {
    if (!isEditing) return;

    setIsEditing(false);

    if (newText === originalText) return;

    if (isEditing) {
      onSubmit(newText);
    }
  };

  const handleIconClick = () => {
    if (!isEditing) {
      setIsEditing(true);
    } else {
      onClickOutside();
    }
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewText(event.target.value);
  };

  if (!canEdit) return <PlaylistTitle title={originalText} />;

  return (
    <ClickAwayListener onClickAway={onClickOutside}>
      <form onSubmit={handleOnSubmit}>
        <Stack direction={'row'} alignItems={'center'} gap={2} justifyContent={'center'}>
          <InputTextField
            ref={inputRef}
            isEditing={isEditing}
            originalText={originalText}
            handleNameChange={handleNameChange}
          />
          {isSubmitting && <Spinner />}
          {!isSubmitting && (
            <IconButton type={'button'} onClick={handleIconClick}>
              <IconEdit size='small' color='secondary' />
            </IconButton>
          )}
        </Stack>
      </form>
    </ClickAwayListener>
  );
};
