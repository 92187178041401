import { Box, Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import { routes } from 'kognia/router/routes';
import Filters from 'pages/tactical-analysis/components/tactical-analysis/filters';
import ExportButton from 'pages/tactical-analysis/components/tactical-analysis/filters-bar/export-button';
import { FiltersBarContainer } from 'pages/tactical-analysis/components/tactical-analysis/filters-bar/FiltersBar.styled';
import ZoomRange from 'pages/tactical-analysis/components/tactical-analysis/zoom-range';
import { useTimelineIsMultiselectModeActive } from 'pages/tactical-analysis/hooks/use-timeline-is-multiselect-mode-active';
import { useTimelineTableData } from 'pages/tactical-analysis/hooks/use-timeline-table-data';
import IconFolder from 'shared/components/icons/icon-folder';
import IconPlaylist from 'shared/components/icons/icon-playlist';
import IconTag from 'shared/components/icons/icon-tag';
import { Episode, MatchTeams } from 'shared/types';
import { VideoSource } from 'shared/types/recording/types';

interface Props {
  episodes: Episode[];
  isLoading?: boolean;
  recordingId: string;
  teams: MatchTeams;
  videoSources: VideoSource[];
}

export const FiltersBar = ({ episodes, isLoading = false, recordingId, teams, videoSources }: Props): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const timelineTableData = useTimelineTableData(recordingId);
  const { isMultiselectModeActive, setIsMultiselectModeActive } = useTimelineIsMultiselectModeActive(recordingId);

  if (timelineTableData.rowGroups.length === 0) return <></>;

  return (
    <>
      <FiltersBarContainer>
        <Box display='flex' alignItems='center' bgcolor='common.white'>
          {episodes.length > 0 && !isLoading && (
            <Filters episodeCount={episodes.length} episodes={episodes} recordingId={recordingId} teams={teams} />
          )}
        </Box>
        <Box display='flex' alignItems='center' gap={1} height='100%'>
          <Button
            color={isMultiselectModeActive ? 'primary' : 'inherit'}
            sx={{ whiteSpace: 'nowrap', fontWeight: 'fontWeightRegular' }}
            id='add-to-playlist-button'
            startIcon={<IconFolder size='small' color={isMultiselectModeActive ? 'primary' : undefined} />}
            onClick={() => setIsMultiselectModeActive(!isMultiselectModeActive)}
          >
            {t('timeline:actions.add-to-playlist')}
          </Button>
          <ExportButton
            recordingId={recordingId}
            tacticalAnalysisId={episodes.length > 0 ? episodes[0].tacticalAnalysisId : undefined}
            videoSources={videoSources}
          />

          <Button
            color='secondary'
            variant='outlined'
            startIcon={<IconPlaylist size='small' color='secondary' />}
            onClick={() => navigate(generatePath(routes.RECORDING_PLAYLISTS, { id: recordingId }))}
            sx={{ fontWeight: 'fontWeightRegular', borderRadius: 5, whiteSpace: 'nowrap' }}
            data-testid='timeline-playlists-button'
          >
            {t('timeline:actions.playlists')}
          </Button>
          <Button
            color='secondary'
            variant='outlined'
            startIcon={<IconTag size='small' color='secondary' />}
            onClick={() => navigate(generatePath(routes.TAGGING_TOOL_TAG, { recordingId }))}
            sx={{ fontWeight: 'fontWeightRegular', borderRadius: 5, whiteSpace: 'nowrap' }}
          >
            {t('timeline:actions.add-tags')}
          </Button>
          <ZoomRange />
        </Box>
      </FiltersBarContainer>
    </>
  );
};
