import { Button } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import IconSort from 'shared/components/icons/icon-sort';
import styles from 'shared/components/sort-by/SortBy.module.scss';
import { SortDirection } from 'shared/types/filters/types';

type SortByProps = {
  onSort: (order: SortDirection) => void;
};

const SortBy = ({ onSort }: SortByProps) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleSort = (order: SortDirection) => {
    handleCloseMenu();
    onSort(order);
  };

  return (
    <>
      <Button
        id='sort-by-button'
        aria-controls='sort-by-menu'
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        color='inherit'
        size='small'
        startIcon={<IconSort size='small' />}
      >
        {t('common:actions.sort-by.sort-by').toUpperCase()}
      </Button>
      <Menu
        id='sort-by-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        MenuListProps={{ 'aria-labelledby': 'sort-by-button' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MenuItem onClick={() => handleSort(SortDirection.ASC)}>
          <div className={styles.menuItem}>{t('common:actions.sort-by.asc')}</div>
        </MenuItem>
        <MenuItem onClick={() => handleSort(SortDirection.DESC)}>
          <div className={styles.menuItem}>{t('common:actions.sort-by.desc')}</div>
        </MenuItem>
      </Menu>
    </>
  );
};

export default SortBy;
